import request from '@/utils/request'


export default {


  // 查询记上课使用信息 POST /api/CurriculumAttend/Query
  getArrangeCurriculumList(data) {
    return request({
      url: '/CurriculumAttend/Query',
      method: 'post',
      data,
    })
  },
  // 按班级查询 POST /api/CurriculumAttend/QueryClassesUsedForGroup
  QueryClassesUsedForGroup(data) {
    return request({
      url: '/CurriculumAttend/QueryClassesUsedForGroup',
      method: 'post',
      data,
    })
  },
  // 记上课-有排课 
  attendClass(data) {
    return request({
      url: '/CurriculumAttend/AttendClass',
      method: 'post',
      data,
    })
  },
  // 记上课-编辑补课管理  POST /api/CurriculumAttend/MakeUpAttendClass
  MakeUpAttendClass(data) {
    return request({
      url: '/CurriculumAttend/MakeUpAttendClass',
      method: 'post',
      data,
    })
  },
  // 记上课-没有排课 
  attendClassForNotArrange(data) {
    return request({
      url: '/CurriculumAttend/AttendClassForNotArrange',
      method: 'post',
      data,
    })
  },
    // 记上课-有排课 
    AttendClass(data) {
      return request({
        url: '/CurriculumAttend/AttendClass',
        method: 'post',
        data,
      })
    },
  // 编辑记上课  POST /api/CurriculumAttend/EditAttendClassInfo 
  CurriculumAttendEditAttendClassInfo(data) {
    return request({
      url: '/CurriculumAttend/EditAttendClassInfo',
      method: 'post',
      data,
    })
  },
  // 记上课-没有排课  POST /api/CurriculumAttend/Changestatus

  CurriculumAttendChangestatus(ids) {
    return request({
      url: '/CurriculumAttend/Changestatus?status=-99',
      method: 'post',
      params: {
        ids
      }
    })
  },
  //获取单个记上课信息 POST /api/CurriculumAttend/Detail/{id 
  CurriculumAttendDetail(id) {
    return request({
      url: '/CurriculumAttend/Detail',
      method: 'post',
      params: {
        id
      }
    })
  },
}
