import Layout from '@/views/layout'

const dictionaryRouter = {
  path: '/dictionary',
  component: Layout,
  redirect: '/dictionary/index',
  name: '数据字典',
  meta: {
    title: '数据字典',
    icon: 'table'
  },
  children: [{
    path: 'index',
    component: () => import('@/views/dictionary/index'),
    name: 'dictionary',
    meta: {
      title: '数据字典'
    }
  }, ]
}
export default dictionaryRouter
