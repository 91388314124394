<template>
	<el-dialog title="签到打卡" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="480px">
		<el-calendar v-model="todayTime" v-loading="loading">
			<template slot="dateCell" slot-scope="{date, data}">
				<span>{{ data.day.split('-').slice(2).join('-') }}</span>
				<div style="padding-left: 26px;" v-for="(item,index) of message">

					<span slot="reference" v-if="item.sign_time == data.day">
						<svg class="icon BgIcon" aria-hidden="true" style="width: 15px; height: 15px;">
							<use xlink:href="#icon-V"></use>
						</svg>
					</span>
					
					<!-- <span slot="reference"
						v-if="todayTime > data.day && item.sign_time != data.day && item.sign_time != todayTime">
						<svg class="icon BgIcon" aria-hidden="true" style="width: 15px; height: 15px;">
							<use xlink:href="#icon-cuo"></use>
						</svg>
					</span>
					<span slot="reference" v-if="todayTime == data.day && item.sign_time!=todayTime &&  item.sign_time!=data.day">
						
						<svg class="icon BgIcon" aria-hidden="true" style="width: 15px; height: 15px;">
							<use xlink:href="#icon-quan"></use>
						</svg>
					</span> -->
				</div>
				<div style="padding-left: 26px;">
					<span slot="reference" v-if="message.length==0 && data.day<todayTime">
						<svg class="icon BgIcon" aria-hidden="true" style="width: 15px; height: 15px;">
							<use xlink:href="#icon-cuo"></use>
						</svg>
					</span>
					<span slot="reference" v-if="message.length==0 && data.day==todayTime">
						<svg class="icon BgIcon" aria-hidden="true" style="width: 15px; height: 15px;">
							<use xlink:href="#icon-quan"></use>
						</svg>
					</span>
				</div>
			</template>
		</el-calendar>
		<div class="btn">
			<el-button :type="disabled?'':'primary'" round size="mini" @click="CheckIn(0)" :disabled="disabled">{{test}}
			</el-button>
			<el-button :type="disableds?'':'primary'" round size="mini" @click="CheckIn(1)" :disabled="disableds">
				{{test1}}
			</el-button>
		</div>

	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				disabled: false,
				disableds: false,
				text: '成功啦',
				test: '上班打卡',
				test1: '下班打卡',
				loading: true,
				todayTime: formatTime(new Date(), 'yyyy-MM-dd'),
				message: [],
				listQuery: {
					user_id: [],
					PageIndex: 1,
					PageSize: 100,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				}
			}
		},
		watch: {
			dialogVisible() {
				this.$bus.$emit("dialogClose", {
					type: "CheckIn",
					data: this.text,
				});
			},
		},
		created() {
			this.listQuery.user_id.push(this.$store.getters.getUserInfo.id)
			this.CheckIn()
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			CheckIn(val) {
				this.getQuerySign().then(res1 => {
					this.message = this.unique(res1.rows)

					let list = res1.rows.filter(t => t.sign_time == this.todayTime)
					list.map(item => {
						if (item.remark == "上班打卡") {
							this.disabled = true
							this.test = "上班已打卡"

						} else if (item.remark == "下班打卡") {
							this.disableds = true
							this.test1 = "下班已打卡"
						}
					})

				})

				if (val == 0) {
					this.API.accountSign({
						remark: '上班打卡'
					}).then(res => {
						if (res.success) {
							this.$message.success(res.data)
							this.getQuerySign().then(res1 => {
								this.message = this.unique(res1.rows)
								let list = res1.rows.filter(t => t.sign_time == this.todayTime)
								if (list.length) {
									this.disabled = true
									this.test = "上班已打卡"
								}
							})
						}
					})
				} else if (val == 1) {
					this.API.accountSign({
						remark: '下班打卡'
					}).then(res => {
						if (res.success) {
							this.$message.success(res.data)
							this.getQuerySign().then(res1 => {
								this.message = this.unique(res1.rows)
								let list = res1.rows.filter(t => t.sign_time == this.todayTime)
								if (list.length) {
									this.disableds = true
									this.test1 = "下班已打卡"
								}
							})
						}
					})
				}
				this.$forceUpdate()
			},
			getQuerySign() {
				return new Promise((resolve, reject) => {
					this.API.accountQuerySign(this.listQuery).then(res => {
						res.data.rows.map(item => {
							item.sign_time = formatTime(item.sign_time, 'yyyy-MM-dd')
						})
						this.loading = false
						resolve(res.data)
					})
				})

			},
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr.sign_time) && res.set(arr.sign_time, 1));
			},
			handleSelected(day) {
				let flag = 0; //默认显示为0
				this.message.forEach(item => { //this.	attendanceDetailsData是后台返回的数据
					// console.log(item.sign_time, day)
					if (item.sign_time == day) { //是否有签到数据
						return flag = 1;
					} else if (item.sign_time != day && this.todayTime == day) {

						return flag = 2;
					} else if (this.todayTime > day && item.sign_time != day && item.sign_time != this.todayTime) {
						return flag = 3;
					}
				})
				return flag;
			}
		}
	}
</script>

<style scoped>
	>>>.el-button-group {
		display: none;
	}

	>>>.el-calendar-table .el-calendar-day {
		height: 40px;
	}

	>>>.el-calendar-table thead th {
		padding: 6px 0;
	}

	>>>.el-calendar-table {
		width: 100%;
		height: 100%;

		&:not(.is-range) {

			/* //使不是本月的日期不可点击，不会跳转到其他月份 */
			td.next {
				pointer-events: none;
			}

			td.prev {
				pointer-events: none;
			}

			/* td{
                              pointer-events: none;
                            } */
		}
	}
</style>
<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.btn {
		text-align: center;
	}
</style>
