import request from '@/utils/request'

export default {
  // /api/UserLog/Query
  UserLog(query) {
    return request({
      url: '/UserLog/Query',
      method: 'post',
      data: query
    })
  },
  //查询用户操作日志POST /api/UserLog/Query
  QueryUserLog(data) {
    return request({
      url: '/UserLog/Query',
      method: 'post',
      data
    })
  },
}
