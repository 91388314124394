//服务中心
const serviceCentreList=[
    {
      name: "腕豪",
      phone: "15812345678",
      type: "申请的类型",
      shool: "九里堤校区",
      course: "一对一",
      className: "福星一班",
      handleName: "结束",
      date: "2021-03-10",
      reason:"运气不好！",
      feedback:"福星课堂真好！",
      feedbackdate: "2021-03-10",
    },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },{
        name: "腕豪",
        phone: "15812345678",
        type: "申请的类型",
        shool: "九里堤校区",
        course: "一对一",
        className: "福星一班",
        handleName: "结束",
        date: "2021-03-10",
        reason:"运气不好！",
        feedback:"福星课堂真好！",
        feedbackdate: "2021-03-10",
      },
   
  ]
  export default serviceCentreList;