<template>
	<el-dialog title="选择员工" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false">
		<el-table ref="singleTable" :data="EmployeesList" highlight-current-row @current-change="handleCurrentChange"
			style="width: 100%">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column property="full_name" label="员工名称" width="120"></el-table-column>
			<el-table-column property="code" label="工号" width="120">
			</el-table-column>
			<el-table-column property="phone" label="电话号码">
			</el-table-column>
		</el-table>
		
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="selectEmployee.PageSize" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
		<!-- <div class="margin-top padding-lr-lg"> -->
		<div class="flex justify-content-flexEnd margin-top-xl">
			<el-button @click="close">取消</el-button>
			<el-button type="primary" @click="submit">确定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				Customerids: '',
				dialogVisible: true,
				EmployeesList: [],
				total:0,
				message: {},
				selectEmployee: {
					// post_type: [4019],
					PageIndex: 1,
					PageSize: 10,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				},
			}
		},
		created() {
			this.getEmployeesMore()
			
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			pageSizeChange(val) {
				this.selectEmployee.PageIndex = 1;
				this.selectEmployee.pageSize = val;
				this.getEmployeesMore();
			},
			pageIndexChange(val) {
				this.selectEmployee.PageIndex = val;
				this.getEmployeesMore();
			},
			//获取所有的咨询专员
			getEmployeesMessage(data) {
				return new Promise((resolve, reject) => {
					this.API.accountQuery(data).then(res => {
						resolve(res)
					})
				})
			},
			getEmployeesMore() {
				this.getEmployeesMessage(this.selectEmployee).then(res => {
					this.total=res.data.total
					this.EmployeesList=res.data.rows
					// let num = Math.ceil(res.data.total / this.selectEmployee.PageSize)
					// if (num >= this.selectEmployee.PageIndex) {
					// 	this.selectEmployee.PageIndex++
					// 	this.getEmployeesMessage(this.selectEmployee).then(t => {
					// 		this.EmployeesList = this.EmployeesList.concat(res.data.rows, t.data.rows)
					// 	})
					// }
				})
			},
			handleCurrentChange(val) {
				this.message = val
			},
			submit() {
				if (this.message) {
					// console.log(this.)
					let listQuery = {
						customerid:String( this.Customerids),
						FollowUserId: String(this.message.id)
					}
					this.API.CustomerConsultingdistribution(listQuery).then(res => {
						if (res.success) {
							this.$message.success("分配成功")
							this.$bus.$emit('dialogClose', {
								type: 'ChooseStaff',
								data: this.message
							});
							this.close()
						}
					})
				}
			}
		}
	}
</script>

<style>
</style>
