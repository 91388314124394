import request from '@/utils/request'

export default {
	callAddCallList(ids) {
		return request({
			url: '/Call/AddCallList?CustomerUserId=' + ids,
			method: 'get',
		})
	},
	getSelectCallList(query) {
		return request({
			url: '/Call/SelectCallList',
			method: 'post',
			data: query
		})
	},
	postUpdateCallStatus(data) {
		return request({
			url: `/Call/UpdateCallStatus/${data.DialId}`,
			method: 'post',
			data
		})
	},
}
