const fourSeasons=[{
    label: '春季',
    value: 0
  },
  {
    label: '夏季',
    value: 1
  },
  {
    label: '秋季',
    value: 2
  },
  {
    label: '冬季',
    value:3
  },
];
export default fourSeasons;