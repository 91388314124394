
import Layout from '@/views/layout'

const contactRecordRouter = {
  path: '/contactRecord',
  component: Layout,
  redirect: '/contactRecord/index',
  name: '联系记录',
  meta: {
    title: '联系记录',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/contactRecord/index'),
      name: 'contactRecord',
      meta: { title: '联系记录' }
    },
  ]
}
export default contactRecordRouter
