const genders=[{
    label: '男',
    value: 1
  },
  {
    label: '女',
    value: 2
  },
  {
    label: '未知',
    value: 3
  },
];
export default genders;