<template>
  <el-dialog
    width="55%"
    title="选择班级"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="flex aligin-items-center justify-content-between padding-sm">
      <div>
        <el-select v-model="queryList.school_id" placeholder="请选择校区" size="medium">
          <el-option
            v-for="item in schoolOptions"
            :key="item.value"
            :label="item.schoolName"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-input
          size="medium"
          placeholder="请输入班级名称或关键字搜索"
          suffix-icon="el-icon-search"
          v-model="queryList.KeyWord"
          style="width: 230px"
        />
      </div>
    </div>
    <!-- 筛选 -->
    <div class="padding-sm flex aligin-items-center">
      <el-popover width="60" trigger="hover">
        <el-radio-group v-model="queryList.charge_type">
          <el-radio :label="0" class="padding-xs">未知</el-radio>
          <el-radio :label="1" class="padding-xs">按课时收费</el-radio>
          <el-radio :label="2" class="padding-xs">按时间收费</el-radio>
          <el-radio :label="3" class="padding-xs">按期收费</el-radio>
        </el-radio-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>收费模式</span>
          <i class="el-icon-caret-top" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="200" trigger="hover">
        <div style="max-height: 200px" class="scrollbar">
          <el-radio-group v-model="queryList.subject">
            <el-radio
              v-for="item in subjectTypeList"
              :key="item.id"
              :label="item.id"
              class="padding-xs"
              >{{ item.title }}</el-radio
            >
          </el-radio-group>
        </div>
        <div slot="reference" class="aligin-items-center flex">
          <span>科目</span>
          <i class="el-icon-caret-bottom" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
    </div>
    <!-- 表格 -->
    <as-list
      ref="reload"
      :ajaxMethods="{ list: 'getClassList' }"
      :listQueryProp="queryList"
      @SelectionChange="handleSelectionChange"
    >
      <!-- <el-table :data="datalist" @@SelectionChange="handleSelectionChange" height="400" > -->
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        property="class_name"
        label="班级名称"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        property="curriculum.curriculum_name"
        label="课程名称"
      ></el-table-column>
      <el-table-column
        align="center"
        property="class_num"
        label="人数"
      ></el-table-column>
      <el-table-column align="center" label="收费模式">
        <template slot-scope="scope">
          <span v-if="scope.row.curriculum && scope.row.curriculum.charge_type_name">{{ scope.row.curriculum.charge_type_name }}</span>
          <span v-else>暂无</span>
          <!-- <div v-if="scope.row.curriculum">
            <div v-if="scope.row.curriculum.charge_type == 0">未知</div>
            <div v-if="scope.row.curriculum.charge_type == 1">按课时收费</div>
            <div v-if="scope.row.curriculum.charge_type == 2">按时间收费</div>
            <div v-if="scope.row.curriculum.charge_type == 3">按期收费</div>
          </div>
          <div v-else>未知</div> -->
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        align="center"
        property="class_num"
        label="学费标准"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.curriculum">
            <div v-for="item in scope.row.curriculum.listm" :key="item.id">
              <span>{{ item.num }}课时 = {{ item.money }}元</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        property="Tlistname"
        label="教师"
      ></el-table-column>
      <el-table-column align="center" label="开班日期">
        <template slot-scope="scope">
          <span>{{ scope.row.starttime | dateTimeFilter("yyyy-MM-dd") }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column property="to_class_time" label="上课时间"></el-table-column> -->
    </as-list>
    <!-- 分页 -->
    <!-- <el-pagination style="margin-top:20px;" background layout="prev, pager, next,total" :total="queryResult.total" @current-change="pageChange"></el-pagination> -->
    <!-- 已选班级 -->
    <div class="margin-top flex justify-content-center">
      <el-popover
        placement="top"
        width="300"
        trigger="hover"
        :disabled="selectedClasses.length < 1"
      >
        <template v-if="selectedClasses.length > 0">
          <div
            class="flex justify-content-center aligin-items-center"
            v-for="(course, courseIndex) in selectedClasses"
            :key="courseIndex"
          >
            <span>{{ course.className }}(校区)</span>
            <i
              class="el-icon-error el-icon--right cursor"
              @click="delSelectedCourse(courseIndex)"
            ></i>
          </div>
        </template>
        <el-button slot="reference">
          已选择班级{{ selectedClasses.length || 0 }}个
          <i class="el-icon-error el-icon--right text-grey"></i>
        </el-button>
      </el-popover>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(true)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      // 校区数组
      schoolOptions: [],
      //课程科目数据
      subjectTypeList: [],
      courseInput: "", // 科目
      coursesList: [], // 科目数据
      courseName: "",
      courseId: [],
      dialogVisible: true,
      datalist: [],
      queryResult: {
        total: 0,
      },
      queryList: {
        KeyWord: "",
        school_id: [],
        
        student_id: [],
        curriculum_type: [],
        curriculum_week: [],
        curr_mode: 1,
        charge_type: "",
        subject: "",
      },
      keywordTimer: "",
      multipleSelection: [],
      name: "",
      selectedClasses: [],
    };
  },
  created() { 
    console.log(this.bb)
      console.log(this.queryList) 
    if (this.courseId || this.courseId.length) {
      this.queryList.curriculum_id = this.courseId;
    }
    this.$Dictionary
      .getDictionaryAllTreee({
        module: this.$Dictionary.moduleConfig.SubjectType,
        PageIndex: 1,
        PageSize: 1000000,
        HasRole: false,
        parentId: 10,
      })
      .then((res) => {
        this.subjectTypeList = res.data;
      });
    this.getshoolList();
    this.search();
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "ChooseClass",
        data: this.multipleSelection,
      });
    },
    "queryList.KeyWord": {
      handler() {
        clearTimeout(this.keywordTimer);
        this.keywordTimer = setTimeout(() => {
          this.search();
        }, 500);
      },
    },
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    search() {
      this.queryList.PageIndex = 1;
      // this.getList();
    },
    // 校区
    getshoolList() {
      let obj = {
        PageIndex: 1,
        PageSize: 1000000,
        type: 1,
      };
      this.API.OrganizationList(obj).then((res) => {
        this.schoolOptions = res.data.rows.map((t) => {
          return {
            value: t.id,
            schoolName: t.name,
          };
        });
      });
    },
    getList() { 
      this.API.getClassList(this.listQuery).then((res) => {
        this.datalist = res.data.rows || [];
        this.queryResult = Object.assign({}, this.queryResult, res.data);
        this.datalist.map((t) => {
          if (t.starttime) t.starttime = t.starttime.slice(0, 10);
          if (t.curriculum === null) {
            t.charge_type = "未知";
            return;
          }
          if (t.curriculum && t.curriculum.charge_type == 0)
            t.charge_type = "未知";
          if (t.curriculum && t.curriculum.charge_type == 1)
            t.charge_type = "按课时收费";
          if (t.curriculum && t.curriculum.charge_type == 2)
            t.charge_type = "按时间收费";
          if (t.curriculum && t.curriculum.charge_type == 4)
            t.charge_type = "按期收费 ";
          return t;
        });
      });
    },
    pageChange(pageindex) {
      this.listQuery.PageIndex = pageindex;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delSelectedCourse(index) {
      this.selectedClasses.splice(index, 1);
    },
    // 删除课程类别btn
    delCoursesType(index) {
      this.selectedClasses.splice(index, 1);
    },
    // 删除科目btn
    delCourses(index) {
      this.coursesList.splice(index, 1);
    },
    // 清空筛选
    clearBtn() {
      this.selectedClasses = [];
      this.coursesList = [];
    },
  },
};
</script>
