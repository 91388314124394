const course=[{
    label: '高三1V1',
    value: 1
  },
  {
    label: '小三1V1',
    value: 2
  },
  {
    label: '丽都高三文科全日制',
    value: 3
  },
  {
    label: '交大初三语文班课',
    value:4
  },
  {
    label: '麓山高二一对二',
    value: 5
  },
];
export default course;