
import Layout from '@/views/layout'

const mailListRouter = {
  path: '/mailList',
  component: Layout,
  redirect: '/mailList/index',
  name: '通讯录',
  meta: {
    title: '通讯录',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/mailList/index'),
      name: 'mailList',
      meta: { title: '通讯录' }
    },
  ]
}
export default mailListRouter
