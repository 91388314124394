import Layout from '@/views/layout'

const commonToolRouter = {
    path: '/commonTool',
    component: Layout,
    redirect: '/commonTool/cloudDisk',
    name: '通用功能',
    meta: {
        title: '通用功能',
        icon: 'table'
    },
    children: [{
        path: 'cloudDisk',
        component: () => import('@/views/cloudDisk/index'),
        name: 'cloudDiskIndex',
        meta: { title: '云盘' }
    },{
        path: 'tasklist',
        component: () => import('@/views/oa/taskList'),
        name: 'tasklist',
        meta: { title: '待办任务' }
    },{
        path: 'tasknew',
        component: () => import('@/views/oa/taskNew'),
        name: 'tasknew',
        meta: { title: '业务申请' }
    }
,{
        path: 'templist',
        component: () => import('@/views/oa/tasktemp'),
        name: 'templist',
        meta: { title: '模板管理' }
    }

]
}
export default commonToolRouter
