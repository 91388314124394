<template>
	<el-dialog title="销售模板" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="47%">
		<div style="height: 450px;">
			<div class="Clientcenter-left margin-right right-border" style="width: 35%;">

				<el-tabs v-model="activeName" @tab-click="handleClick">

					<el-tab-pane label="企业模板" name="enterprise">

						<el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
							@close="handleClose">
							<el-submenu :index="item.value" v-for="(item,index) of useEnterpriseTemplateList"
								:key='item.value'>
								<template slot="title">
									<span>{{item.label}}</span>
								</template>
								<el-menu-item-group v-for="(serve,res) of item.list" :ket='res.value'>
									<el-menu-item>{{serve.label}}</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
						</el-menu>

					</el-tab-pane>

					<el-tab-pane label="个人模板" name="personal">
						<el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
							@close="handleClose">
							<el-submenu index="1">
								<template slot="title">
									<span>我的分组</span>
								</template>
								<el-menu-item-group>
									<!-- <el-menu-item></el-menu-item> -->
								</el-menu-item-group>
							</el-submenu>
						</el-menu>
					</el-tab-pane>

				</el-tabs>
			</div>
			<div class="Clientcenter-right padding-right-sm" style="width: 65%;overflow-y:auto ;height: 400px;" >
				<el-form ref="form" :model="form" label-width="80px" v-if="activeName=='enterprise'">
					<el-form-item label="标题">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="说明">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="类型">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="时间">
						<el-col :span="11">
							<el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;">
							</el-date-picker>
						</el-col>
						<el-col class="line" :span="2">-</el-col>
						<el-col :span="11">
							<el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;">
							</el-time-picker>
						</el-col>
					</el-form-item>

					<el-form-item label="内容">
						<el-input type="textarea" v-model="form.desc"></el-input>
					</el-form-item>

				</el-form>
				<div v-else>
					暂无可用的销售模板
				</div>
			</div>
			

			<div class="myClient-position-absolute">
				<div class="flex justify-content-flexEnd">
					<el-button type="primary">引用模板</el-button>
					<el-button  @click="close">取消</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {

		data() {
			return {
				dialogVisible: true,
				activeName: 'enterprise',
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				useEnterpriseTemplateList: [{
				     label: '初步意向，邀约见面',
				     value: '1',
				     list: [{
				      label: '第一天 短信提醒',
				      value: 1
				     }, {
				      label: '第二天 短信问候',
				      value: 2
				     }],
				    },
				    {
				     label: '初次见面，加深印象',
				     value: '2',
				     list: [{
				      label: '第一天 短信提醒',
				      value: 1
				     }, {
				      label: '第二天 短信问候',
				      value: 2
				     }],
				    },
				    {
				     label: '成交客户，稳定关系',
				     value: '3',
				     list: [{
				      label: '第一天 短信提醒',
				      value: 1
				     }, {
				      label: '第二天 短信问候',
				      value: 2
				     }],
				    },
				   ]
			}
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			handleClick(tab) {
				this.activeName=tab.name
			},
			handleOpen(key, keyPath) { 
			},
			handleClose(key, keyPath) { 
			}
		}
	}
</script>

<style>
</style>
