import Layout from '@/views/layout'

const checkInListRouter = {
  path: '/checkInList',
  component: Layout,
  redirect: '/checkInList/index',
  name: '签到',
  meta: {
    title: '签到',
    icon: 'table'
  },
  children: [{
    path: 'index',
    component: () => import('@/views/checkInList/index'),
    name: 'checkInList',
    meta: {
      title: '签到'
    }
  }, ]
}
export default checkInListRouter
