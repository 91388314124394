<template>
	<section class="app-header">
		<div class="app-logo">
			<img src="../../../assets/images/logo/logo.png" alt="戴氏精品堂" />
			<span>成都戴氏精品堂中高考培训学校</span>

		</div>
		<!-- <el-menu mode="horizontal" @select="handleSelect">
			<el-submenu v-for="item in topMenus" :key="item.title" :index="item.title">
				<template slot="title">{{ item.title }}</template>
				<el-menu-item v-for="sub in item.subs" :key="sub.title"  @click="JumpUrl(sub.path)" :index="sub.title">{{ sub.title }}
				</el-menu-item>
			</el-submenu>
		</el-menu> -->
		<!-- <div class="app-search">
			<el-input placeholder="Search"></el-input>
		</div> -->
		<!-- <el-badge is-dot class="dot-icon" title="最新消息">
      <i class="el-icon-message-solid"></i>
    </el-badge> -->
		
		<div @click="CheckIn" class="cursor-pointer">
			<svg class="icon BgIcon" aria-hidden="true">
				<use xlink:href="#icon-dakajilu"></use>
			</svg>
			<span style="color:#fff;position: relative;top:-5px">
				签到
			</span>
		</div>
		<!-- <el-popover placement="top" width="450" v-model="visible" popper-class="popper">
			<el-calendar>
			  <template
			    slot="dateCell"
			    slot-scope="{date, data}">
			    <div :class="data.isSelected ? 'is-selected' : ''">
			      {{ data.day.split('-').slice(2).join('-') }} 
				  <span>
					  <svg class="icon BgIcon" aria-hidden="true"  style="width: 15px; height: 15px;">
					  	<use xlink:href="#icon-V" ></use>
					  </svg>
					  <svg class="icon BgIcon" aria-hidden="true"  style="width: 15px; height: 15px;">
					  	<use xlink:href="#icon-cuo" ></use>
					  </svg>
					  <svg class="icon BgIcon" aria-hidden="true"  style="width: 15px; height: 15px;">
					  	<use xlink:href="#icon-quan" ></use>
					  </svg>
				  </span>
			    </div>
			  </template>
			</el-calendar>
			<div class="btn">
				<el-button type="primary" round size="mini">签到打卡</el-button>
			</div>
			
			<div slot="reference">
				<svg class="icon BgIcon" aria-hidden="true">
					<use xlink:href="#icon-dakajilu"></use>
				</svg>
			</div>
		</el-popover>
		 -->
		<el-dropdown class="app-person" @command="handleCommand">
			<div class="head-person">
				<img src="../../../assets/images/xs/avatar5.jpg" alt="" />
				<p class="flex flex-column">
					<span>{{ userInfo.full_name }}</span>
					<span v-if="userInfo.user_school">({{ userInfo.user_school }})</span>
				</p>
				
			</div>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item>设置</el-dropdown-item>
				<el-dropdown-item command="exit">退出</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</section>
</template>
<script>
	export default {
		name: "AppHeader",
		data() {
			return {
				 visible: false,
				topMenus: [{
						title: "首页",
						subs: [{
							title: "首页",
							path: "/index.html"
						}]
					},
					{
						title: "工作台",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "报表中心",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "销售管理",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "学管中心",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "工资绩效",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "公告文件",
						subs: [{
								title: "发起流程",
								path: "",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
					{
						title: "系统设置",
						subs: [{
								title: "数据字典",
								path: "/dictionary/index",
							},
							{
								title: "打卡签到",
								path: "",
							},
							{
								title: "待办任务",
								path: "",
							},
							{
								title: "已办任务",
								path: "",
							},
							{
								title: "待发事项",
								path: "",
							},
							{
								title: "已发事项",
								path: "",
							},
							{
								title: "草稿箱",
								path: "",
							},
						],
					},
				],
			};
		},
		computed: {
			userInfo() {
				return this.$store.getters.getUserInfo;
			},
		},
		methods: {
			handleCommand(command) {
				if (command == "exit") {
					this.$confirm("是否确认退出登录？").then(() => {
						this.$store.dispatch("exitLogin");
					});
				}
			},
			JumpUrl(url) {
				this.$router.push(url);
			},
			handleSelect() {},
			CheckIn(){
				this.$Dialog.CheckIn()
			}
		},
	};
</script>
<style scoped>
	.el-menu {
		background-color: transparent;
	}

	>>>.el-menu--horizontal>.el-submenu .el-submenu__title {
		color: #fff;
	}

	>>>.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		color: #fff;
	}

	>>>.el-input__inner {
		background-color: #ffffff;
	}

	>>>.el-menu--horizontal>.el-submenu:focus .el-submenu__title,
	>>>.el-menu--horizontal>.el-submenu:hover .el-submenu__icon-arrow,
	>>>.el-menu--horizontal>.el-submenu:focus .el-submenu__icon-arrow,
	>>>.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
		color: #000;
		background-color: initial;
	}
	
</style>
<style lang="scss" scoped>
	.is-selected {
	    color: #1989FA;
	  }
	  .no-selected {
	      color: #ff5500;
	    }
	.icon {
		width: 30px;
		height: 30px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.app-header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 10px 20px 10px 310px;
		z-index: 9;
		height: 80px;
		background-image: url(../../../assets/images/system/Head_Bg.png);
		border-bottom: 1px solid #cadeea;

		.dot-icon {
			cursor: pointer;
			margin: 0 20px;

			i {
				font-size: 20px;
			}
		}

		.app-logo {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			padding-left: 30px;
			display: flex;
			align-items: center;
			color: #fff;

			span {
				font-size: 20px;
			}

			img {
				height: 50px;
			}
		}

		.app-person {
			margin-left: 10px;
			// box-shadow: 0px 0px 5px #808080;

			.head-person {
				// background-color: #fbfbfb;
				border-radius: 5px;
				height: 40px;
				line-height: 30px;
				padding-right: 20px;
				padding-left: 10px;
				display: flex;
				align-items: center;
				color: #fff;
				img {
					height: 30px;
					width: 30px;
					border-radius: 15px;
					margin-right: 10px;
				}
			}
		}
	}
	
</style>
