
import Layout from '@/views/layout'

const marketRouter = {
  path: '/market',
  component: Layout,
  redirect: '/market/index',
  name: '市场',
  meta: {
    title: '市场部门',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/myMarket/index'),
      name: 'clientIndex',
      meta: { title: '我的客户'}
    }, {
      path: 'createClient',
      component: () => import('@/views/myMarket/components/createClient'),
      name: 'createClient',
      meta: { title: '创建客户'}
    },{
      path: 'DepartmentalCustomers',
      component: () => import('@/views/myMarket/index'),
      name: 'clientIndex',
      meta: { title: '部门客户'}
    }
  ]
}
export default marketRouter
