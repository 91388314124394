import baseURL from '@/utils/config.js'
import {
  formatTime
} from '@/utils/util'
const schoolType = {
  '1': '直营',
  '2': '合作',
  '3': '加盟',
  '4': '代理',
}
//收费模式
const chargeType = {
  '0': '按课时收费',
  '1': '按时间收费',
  '2': '按期收费',
}

const ProgressType = {
  '1': '新入库',
  '2': '沟通/邀约',
  '3': '上门咨询',
  '4': '异议排除',
  '5': '报名入学',
}
//字节转换
const ByteChange = ""

//计划类型
const planType = {
  '1': '电话计划',
  '2': '短信计划',
  '3': 'QQ计划',
  '4': '微信计划',
  '5': '定时计划',
}
//任务类型
const ContactType = {
  '1': '电话任务',
  '2': '短信任务',
  '3': '微信任务',
  '4': 'QQ任务',
  '5': '邮件任务',
  '6': '拜访任务',
  '7': '提醒任务'
}
const personnelStatusType = {
  '1': '正式员工',
  '2': '兼职员工',
  '3': '面试期',
  '4': '培训期',
  '5': '试用期',
  '6': '转正失败',
  '7': '停薪留职',
  '8': '主动离职',
  '9': '被解雇',
}

export default {
  data() {
    return {
      editVisible: false,
      uploadActionUrl: "/antis-service/api/Upload/upload", //antis-service/api/Upload/upload
      sourceList: [{
          label: '请选择',
          value: 1
        },
        {
          label: '网络',
          value: 2
        },
        {
          label: '网络S1',
          value: 3
        },
      ],
      genderList: [{
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 0
        },
        {
          label: '未知',
          value: 2
        },
      ],
      useList: [{
          label: '使用',
          value: 1
        },
        {
          label: '不使用',
          value: 2
        }
      ],
      clientList: [{
          label: '我的客户库',
          value: 1
        },
        {
          label: '共享给我的客户',
          value: 2
        },
        {
          label: '业务组公海',
          value: 3
        }
      ],
      TimeList: [{
          label: '最后未联系时间',
          list: [{
            label: '最近3天',
            value: 1
          }, {
            label: '最近7天',
            value: 2
          }, {
            label: '最近30天',
            value: 3
          }, {
            label: '未曾联系',
            value: 4
          }, {
            label: '自定义',
            value: 5
          }],
          value: 1
        },
        {
          label: '未更新动态时间',
          value: 2,
          list: [{
            label: '最近3天',
            value: 1
          }, {
            label: '最近7天',
            value: 2
          }, {
            label: '最近30天',
            value: 3
          }, {
            label: '未曾联系',
            value: 4
          }, {
            label: '自定义',
            value: 5
          }],
        },
        {
          label: '最后联系时间',
          value: 3,
          list: [{
            label: '最近3天',
            value: 1
          }, {
            label: '最近7天',
            value: 2
          }, {
            label: '最近30天',
            value: 3
          }, {
            label: '未曾联系',
            value: 4
          }, {
            label: '自定义',
            value: 5
          }],
        },
        {
          label: '更新动态时间',
          value: 4,
          list: [{
            label: '最近3天',
            value: 1
          }, {
            label: '最近7天',
            value: 2
          }, {
            label: '最近30天',
            value: 3
          }, {
            label: '未曾联系',
            value: 4
          }, {
            label: '自定义',
            value: 5
          }],
        }
      ],
      //布置老师
      ArrangeTeachersList: [{
          label: '周老师',
          value: 2
        },
        {
          label: '张老师',
          value: 3
        },
        {
          label: '朱老师',
          value: 4
        },
        {
          label: '任老师',
          value: 5
        },
        {
          label: '陈老师',
          value: 6
        },
        {
          label: '杨老师',
          value: 7
        },
      ],
      //助教
      assistantList: [{
          label: '周助教',
          value: 2
        },
        {
          label: '张助教',
          value: 3
        },
        {
          label: '朱助教',
          value: 4
        },
        {
          label: '任助教',
          value: 5
        },
        {
          label: '陈助教',
          value: 6
        },
        {
          label: '杨助教',
          value: 7
        },
      ],
      //班级
      classList: [{
          label: '终极一班',
          value: 2
        },
        {
          label: '火箭一班',
          value: 3
        },
        {
          label: '火箭二班',
          value: 4
        },
        {
          label: '火箭三班',
          value: 5
        },
        {
          label: '速成一班',
          value: 6
        },
        {
          label: '一对一高效班',
          value: 7
        },
      ],
      //校区
      shoolList: [{
          label: '金牛校区',
          value: 2
        },
        {
          label: '新都校区',
          value: 3
        },
        {
          label: '成华校区',
          value: 4
        },
        {
          label: '锦江校区',
          value: 5
        },
        {
          label: '郫都校区',
          value: 6
        },
        {
          label: '双流校区',
          value: 7
        },
      ],
      //是否
      whether: [{
          label: '是',
          value: 2
        },
        {
          label: '否',
          value: 3
        },
      ],
      //星级
      starList: [{
          label: '一星级',
          value: 0
        },
        {
          label: '二星级',
          value: 1
        },
        {
          label: '三星级',
          value: 2
        },
        {
          label: '四星级',
          value: 3
        },
        {
          label: '五星级',
          value: 4
        },
        {
          label: '一2星级',
          value: 0
        },
        {
          label: '二321星级',
          value: 1
        },
        {
          label: '三231星级',
          value: 2
        },
        {
          label: '四4123星级',
          value: 3
        },
        {
          label: '五412312星级',
          value: 4
        },
        {
          label: '一221e星级',
          value: 0
        },
        {
          label: '二3wad21星级',
          value: 1
        },
        {
          label: '三23sad1星级',
          value: 2
        },
        {
          label: '四41sasd23星级',
          value: 3
        },
        {
          label: '五41fzdcz2312星级',
          value: 4
        },
      ],
      //24小时
      timeList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24
      ],

    }
  },
  filters: {
    dateTimeFilter(val, format, defaultVal) {
      if (!val) {
        return defaultVal || '';
      }
      let date = new Date(val);
      try {
        return formatTime(date, format) || defaultVal || '';
      } catch (err) {
        return defaultVal || '';
      }

    },
    moneyFilter(val) {
      if (isNaN(val)) {
        return val;
      }
      return val.toFixed(2);
    },
    sexFilter(val) {
      let sexs = ['女', '男'];
      return sexs[val] || '未知';
    },
    schoolTypeFilter(val) {
      if (val === null || val === undefined || val === 0) {
        return ' '
      }
      return schoolType[val.toString()] || '';
    },
    chargeTypeFilter(val) {
      if (val === null || val === undefined) {
        return ' '
      }
      return chargeType[val.toString()] || '';
    },
    ProgressTypeFilter(val) {
      if (val === null || val === undefined || val === 0) {
        return '未知'
      }
      return ProgressType[val.toString()] || '';
    },
    planTypeFilter(val) {
      if (val === null || val === undefined || val === 0) {
        return '未知'
      }
      return planType[val.toString()] || '';
    },
    ContactTypeFilter(val) {
      if (val === null || val === undefined || val === 0) {
        return '未知'
      }
      return ContactType[val.toString()] || '';
    },
	isFilter(val){
		if(val){
			return "是"
		}else{
			return "否"
		}
	},
	personnelStatusTypeFilter(val){
		if (val === null || val === undefined || val === 0) {
		  return '未知'
		}
		return personnelStatusType[val.toString()] || '';
	},

    /**
     * 转换为服务器地址
     * @param {图片地址} path 
     */
    previewImage(path) {
		console.log('baseURL',baseURL);
      if (path) return baseURL + path
	  
      else return ''
    },
    ByteChangeFilter(val) {
      if (val === 0) return "0 B";
      var k = 1024;
      var sizes = ["B", "KB", "MB", "GB", "TB"],
        i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  methods: {
    closeEditPop() {
      this.editVisible = false
    },
    editHandle() {
      this.editVisible = true
    }
  },
}
