<template>
	<el-dialog title="选择员工所属部门" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="50%">
		<div class="myClient-top-10" style="height: 400px;overflow-y: auto;">
			<el-tree ref="tree" :load="loadNode" lazy :props="defaultProps" node-key="id" :check-on-click-node='true'
				:check-strictly="true" :highlight-current="true" default-expand-all :expand-on-click-node="false"
				show-checkbox  @check-change="checkChange" @node-click="nodeClick">
			</el-tree>

			<div style="position: absolute;bottom: 0;right: 0;">
				<div class="flex">
					<el-button type="primary" @click="sub">确定</el-button>
					<el-button @click="close">取消</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		data() {
			return {
				dialogVisible: true,
				groupNmae: "",
				SuperiorData: {},
				editCheckId:0,
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				listQuery: {
					ParentId: -1,
					type: -1,
					isM:true
				},
			};
		},

		methods: {
			close() {
				this.dialogVisible = false
			},
			loadNode(node, resolve) {
				if (node.level > 0 && !node.data.issubordinate) {
					return resolve([]);
				}
				if (node.level === 0) {
					this.listQuery.ParentId = -1;
					this.listQuery.type = -1
				}
				if (node.level > 0 && node.data.issubordinate) {
					this.listQuery.ParentId = node.data.id;
					if(node.data.type==-1){
						this.listQuery.type = 4;
					}
					if(node.data.type==4){
						this.listQuery.type = -1;
					}
					if(node.data.type==1){
						this.listQuery.type = 2;
					}
					
				}
				this.OrganizationList(this.listQuery).then(res => {
					resolve(res || [])
				})
			},
			OrganizationList(data) {
				return new Promise((resolve, reject) => {
					this.API.OrganizationGetTree(data).then(res => {
						resolve(res || []);
					})
				});
			},
			sub() {
				this.$bus.$emit("dialogClose", {
					type: "selectDepartment",
					data: this.SuperiorData,
				});
				this.close()
			},
			
			checkChange(item, node, self) {
				if (node) {
					this.SuperiorData=item
					this.editCheckId = item.id
					this.$refs.tree.setCheckedKeys([item.id])
				} else {
					if(this.editCheckId==item.id){
						this.SuperiorData=item
						this.editCheckId = item.id
						this.$refs.tree.setCheckedKeys([item.id])
					}
				}
			},
			nodeClick(item, node, self) {
				this.editCheckId = item.id
				this.SuperiorData=item
				this.$refs.tree.setCheckedKeys([item.id])
			}
		},
	};
</script>

<style>
</style>
