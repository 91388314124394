import request from '@/utils/request'

export default {
	//报名续费订单详情
	getOrderDetailEdit(id){
		return request({
			url: `/Order/DetailEdit/${id}`,
			method: 'get',
		})
	},
	postAddOrder(data) {
		return request({
			url: '/Order/addorder',
			method: 'post',
			data
		})
	},
	//销售中心下单
	postAddSaleOrder(data) {
		return request({
			url: '/Order/saleaddorder',
			method: 'post',
			data
		})
	},
	//更改订单状态
	postOrderChangeStatus(data){
		return request({
			url: '/Order/ChangeStatus',
			method: 'post',
			params:data,
			data,
		})
	},
	//获取学员订单 GET /api/Order/GetStudentOrderCurriculums
	GetStudentOrderCurriculums(id) {
		return request({
			url: '/Order/GetStudentOrderCurriculums?student_id=' + id,
			method: 'get',
		})
	},
	getOrderList(data) {
		return request({
			url: '/Order/Query',
			method: 'post',
			data
		})
	},
	postDeleteOrder(query) {
		return request({
			url: '/Order/list',
			method: 'get',
			params: query
		})
	},
	getOrder(query) {
		return request({
			url: '/Order/list',
			method: 'get',
			params: query
		})
	},
	// 余额充值 /api/Order/BalanceRecharge
	BalanceRecharge(data) {
		return request({
			url: '/Order/BalanceRecharge',
			method: 'post',
			data
		})
	},
	// 需要补费的课程订单信息 ///api/Order/GetSupplementaryCurriculumOrderList
	GetSupplementaryCurriculumOrderList(id) {
		return request({
			url: '/Order/GetSupplementaryCurriculumOrderList?student_id=' + id,
			method: 'get',
		})
	},
	// 补费 /api/Order/SupplementaryFee
	SupplementaryFee(data) {
		return request({
			url: '/Order/SupplementaryFee',
			method: 'post',
			data
		})
	},
	// 余额退费  /api/Order/RefundYuE
	RefundYuE(data) {
		return request({
			url: '/Order/RefundYuE',
			method: 'post',
			data
		})
	},
	// 学费退费  /api/Order/RefundXueFei
	RefundXueFei(data) {
		return request({
			url: '/Order/RefundXueFei',
			method: 'post',
			data
		})
	},
	// 查询订单课程列表信息  POST /api/Order/QueryOrderCurriculumns
	QueryOrderCurriculumns(data) {
		return request({
			url: '/Order/QueryOrderCurriculumns',
			method: 'post',
			data
		})
	}, // 修改学员某个订单课程状态  POST /api/Order/ChangeOrderCurriculumStatus
	ChangeOrderCurriculumStatus(query) {
		return request({
			url: '/Order/ChangeOrderCurriculumStatus',
			method: 'post',
			params: query
		})
	},
	// 获取学生下课程订单记录信息 
	GetOrderCurriculums(student_id, curriculum_id) {
		return request({
			url: '/Order/GetOrderCurriculums?student_id=' + student_id + '&curriculum_id=' + curriculum_id,
			method: 'get',
		})
	}, // 获取分班记录 POST /api/Classes/QueryDivisionHistory
	QueryDivisionHistory(data) {
		return request({
			url: '/Classes/QueryDivisionHistory',
			method: 'post',
			data
		})
	} 
	, // 获取学员分班 POST /api/Order/QueryDivisionStudent
	QueryDivisionStudent(data) {
		return request({
			url: '/Order/QueryDivisionStudent',
			method: 'post',
			data
		})
	},
	// 转课 /api/Order/ConvertClassType
	ConvertClassType(data) {
		return request({
			url: '/Order/ConvertClassType',
			method: 'post',
			data
		})
	}, // 转课POST /api/Order/QueryNeedMakeUpList
	QueryNeedMakeUpList(data) {
		return request({
			url: '/Order/QueryNeedMakeUpList',
			method: 'post',
			data
		})
	},
	//根据客户查询客户下的订单 GET /api/Order/GetCustomerOrderCurriculums
	GetCustomerOrderCurriculums(ids) {
		return request({
			url: '/Order/GetCustomerOrderCurriculums?customer_id='+ids,
			method: 'get',
		})
	},
}
