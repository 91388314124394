import request from '@/utils/request'

export default {
    //通知公告列表 POST /api/Notice/Getlist
    GetNoticelist(data) {
        return request({
            url: '/Notice/Getlist',
            method: 'post',
            data
        })
    },
    //修改、添加公告 POST /api/Notice/Createorupdate
    NoticeCreateorupdate(data) {
        return request({
            url: '/Notice/Createorupdate',
            method: 'post',
            data
        })
    },	//修改组织状态 POST /api/Notice/ChangeStatus
    NoticeChangeStatus(data) {
        return request({
            url: '/Notice/ChangeStatus',
            method: 'post',
            params: data
        })
    },
    //GET /api/Notice/Get/{id获取单个
    Get_Notice(data) {
        return request({
            url: '/Notice/Get',
            method: 'get',
            params: data
        })
    },
}