import request from '@/utils/request'

export default {
  // 获取班级列表 /api/Classes/Getlist
  getClassList(query) {
    return request({
      url: '/Classes/Getlist',
      method: 'post',
      data: query
    })
  },
  // 修改/新建班级 /api/Classes/Createorupdate
  updateClass(query) {
    return request({
      url: '/Classes/Createorupdate',
      method: 'post',
      data: query
    })
  }, 
  // 修改/新建班级  POST /api/Classes/OneToOneSetTeacher
  OneToOneSetTeacher(query) {
    return request({
      url: '/Classes/OneToOneSetTeacher',
      method: 'post',
      data: query
    })
  },
  // 修改班级状态 /api/Classes/ChangeStatus
  updateClassStatus(id, status, is_end_studentcurr) {
    return request({
      url: '/Classes/ChangeStatus?ids=' + id + '&status=' + status + '&is_end_studentcurr=' + is_end_studentcurr,
      method: 'post'
    })
  },
  // 查询字典根据id
  getDictionaryId(query) {
    return request({
      url: '/Dictionary/Get',
      method: 'get',
      params: query
    })
  },
  // 导入班级
  importClasses(query) {
    return request({
      url: '/Classes/Import',
      method: 'post',
      data: query
    })
  },
  // 导出班级
  exportClasses(query) {
    return request({
      url: '/Classes/export',
      method: 'post',
      data: query
    })
  },
  //POST /api/Classes/Detail/{id}
  ClassesDetail(id) {
    return request({
      url: '/Classes/Detail/' + id,
      method: 'post'
    })
  },
}

//POST /api/Classes/Getlist
