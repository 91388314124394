<template>
	<el-dialog title="计划详情" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<div class="content" v-loading="loading">
				<div class="flex justify-content-between">
					<div class="flex flex-wrap-wrap">
						<svg class="icon content-logo " aria-hidden="true" v-if="planMessage.PlanType==1">
							<use xlink:href="#icon-S_dianhuawenzhen-copy"></use>
						</svg>
						<svg class="icon" aria-hidden="true" v-if="planMessage.PlanType==2">
							<use xlink:href="#icon-duanxin1"></use>
						</svg>
						<svg class="icon" aria-hidden="true" v-if="planMessage.PlanType==4">
							<use xlink:href="#icon-weixin1"></use>
						</svg>
						<svg class="icon" aria-hidden="true" v-if="planMessage.PlanType==3">
							<use xlink:href="#icon-qq1"></use>
						</svg>
						<!-- <svg class="icon" aria-hidden="true">
							<use xlink:href="#icon-tixing1"></use>
						</svg> -->
						<div class="margin-left-sm">
							<div>
								<span>{{Customermessage.UserName}}</span>
								<span class="padding-left-sm">{{Customermessage.PhoneNumber1}}</span>
							</div>
							<div class="margin-top-xs" v-if="planMessage.PlanStatus==1">
								<i class="iconfont icon-yuandianxiao text-orange"></i>
								<span >未执行</span>
							</div>
							<div class="margin-top-xs" v-if="planMessage.PlanStatus==2">
								<i class="iconfont icon-yuandianxiao text-green"></i>
								<span >执行成功</span>
							</div>
							<div class="margin-top-xs" v-if="planMessage.PlanStatus==3">
								<i class="iconfont icon-yuandianxiao text-red"></i>
								<span >执行失败</span>
							</div>
						</div>
					</div>
					<div v-if="planMessage.PlanType==1" class="margin-left-sm" @click="AddCall(planMessage.CustomerId)">
						<i class="el-icon-phone-outline cursor" style="font-size: 20px;"></i>
					</div>
				</div>

				<div class="margin-top" v-if="planMessage.PlanStatus==1">
					提醒时间：<span class="padding-left-sm">{{planMessage.ExecutionTime | dateTimeFilter}}</span>
				</div>
			</div>

			<div class="flex justify-content-flexEnd margin-top-lg">
				<el-button type="primary" @click="submit(planMessage.id)" v-if="planMessage.PlanStatus==1">确定完成</el-button>
				<el-button class="myClient-left-30" @click="close" v-if="planMessage.PlanStatus==1">取消</el-button>
				<el-button class="myClient-left-30" @click="close" v-if="planMessage.PlanStatus==2">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				planids: 0,
				planMessage: {},
				loading: true,
				Customermessage: {},
			}
		},
		created() {
			this.getplandetail()
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			getplandetail() {
				this.API.CustomerPlanGetPlanInfo(this.planids).then(res => {
					this.planMessage = res
					this.planMessage.ExecutionTime=formatTime(res.ExecutionTime,'yyyy-MM-dd HH:mm:ss')
					this.API.customerView_Customer(res.CustomerId).then(res1 => {
						this.Customermessage = res1
						this.loading = false
					})
				})
			},
			AddCall(id) {
				this.$confirm('确定将该用户加入到待拨打电话界面?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.API.callAddCallList(id).then(res => {
						if (res.code == -1) {
							this.$message.eval(res.message)
						} else if (res.code == 200) {
							this.$message.success("成功加入待拨打列表")
							alert('sdfv')
							this.close()
						}
					})
				}).catch(() => {
					
				});
			},
			submit(id) { 
				this.$confirm('确定已完成该销售计划?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.API.CustomerPlanCompletePlan(id).then(res => { 
						if (res.code == -1) {
							this.$message.eval(res.message)
						} else if (res.code == 200) {
							this.$message.success("成功完成该计划")
							this.$bus.$emit("dialogClose", {
								type: "planDetails",
								data:'成功'
							});
							this.close()
						}
					})
				}).catch(() => {
							
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.content {
		margin: 15px;


	}
</style>
