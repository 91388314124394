<template>
  <el-dialog title="选择课程" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" height="800">
    <div class="flex aligin-items-center justify-content-between padding-sm">
      <div>
        <el-select v-model="listQuery.open_school" placeholder="请选择" size="medium" @change="schoolChange">
          <el-option v-for="item in schoolOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div><el-input size="medium" placeholder="请输入课程名称关键字搜索" suffix-icon="el-icon-search" v-model="listQuery.KeyWord" style="width: 230px"></el-input></div>
    </div>

    <div class="padding-sm flex aligin-items-center">
      <el-popover width="60" trigger="click" @show="isShow = !isShow" @hide="isShow = !isShow">
        <el-checkbox-group v-model="listQuery.charge_type" :value="charge_type" @change="chargeTypeChange">
          <el-checkbox :label="0" class="padding-xs">按课时</el-checkbox>
          <el-checkbox :label="1" class="padding-xs">按期</el-checkbox>
          <el-checkbox :label="2" class="padding-xs">按时间</el-checkbox>
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>收费模式</span>
          <i :class="isShow == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="200" trigger="click">
        <el-input v-model="courseInput" placeholder="" size="mini"></el-input>
        <el-checkbox-group v-model="listQuery.curriculum_type">
         <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of courseClassList" :key="index">{{item.title}}</el-checkbox>
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>课程类别</span>
          <i class="el-icon-caret-bottom" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="250" trigger="click">
        <el-input v-model="courseTypeInput" placeholder="" size="mini"></el-input>
        <el-checkbox-group v-model="listQuery.curriculum_subject" @change="change">
          <el-checkbox class="padding-xs" v-for="item in SubjectTypeList" :key="item.id" :label="item.id">{{ item.title }}</el-checkbox>
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>科目</span>
          <i class="el-icon-caret-bottom" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="150" trigger="click">
        <el-checkbox-group v-model="listQuery.curriculumseason">
          <el-checkbox :label="0" class="padding-xs">春季</el-checkbox>
          <el-checkbox :label="1" class="padding-xs">夏季</el-checkbox>
          <el-checkbox :label="2" class="padding-xs">秋季</el-checkbox>
          <el-checkbox :label="3" class="padding-xs">冬季</el-checkbox>
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>学季</span>
          <i class="el-icon-caret-bottom" />
        </div>
      </el-popover>
    </div>

    <!-- 课程类别、科目选项 -->
    <div class="padding-lr flex aligin-items-center justify-content-between width-100" v-if="coursesTypeList.length > 0 || coursesList.length > 0">
      <div class="flex flex-direction" style="width: 90%">
        <div class="text-gray flex aligin-items-center flex-wrap-wrap" v-if="coursesTypeList.length > 0">
          <div>课程类别：</div>
          <div v-for="(btn, btnIndex) in coursesTypeList" :key="btnIndex">
            <div :class="{ 'margin-left': btnIndex > 0 }">
              <div class="padding-top-xs">
                <el-button size="mini">
                  {{ btn }}
                  <i class="el-icon-close" @click="delCoursesType(btnIndex)" />
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-gray flex aligin-items-center padding-top-sm flex-wrap-wrap" v-if="coursesList.length > 0">
          <div>科目：</div>
          <div v-for="(btn, btnIndex) in coursesList" :key="btnIndex">
            <div :class="{ 'margin-left': btnIndex > 0 }">
              <div class="padding-top-xs">
                <el-button size="mini">
                  {{ btn }}
                  <i class="el-icon-close" @click="delCourses(btnIndex)" />
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-blue cursor" style="width: 8%" @click="clearBtn">清空筛选</div>
    </div>

    <div class="margin-top">
      <as-list ref="singleTable" :ajaxMethods="{ list: 'getCurriculumList' }" :listQueryProp="listQuery" :isSingle="true" @singleSelect="singleSelect">
        <el-table-column>
          <template  slot="header">
            <span class="margin-left-50">
              课程名称
            </span>
          </template>
          <template scope="scope">
            <el-radio :label="scope.row.id" text-color="#ffffff" fill="#ffffff" v-model="templateRadio" @change.native="getTemplateRow(scope.$index, scope.row)">
            <span class="text-cut">{{scope.row.curriculum_name}}</span>
            </el-radio>
          </template>
        </el-table-column>
        <!-- <el-table-column property="curriculum_name" label="课程名称" align="center" width="180"></el-table-column> -->
        <el-table-column property="curriculum_type_namme" label="课程类别" width="100"></el-table-column>
        <el-table-column property="charge_type" label="收费模式" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.charge_type == 0">按课时</span>
            <span v-if="scope.row.charge_type == 1">按时间</span>
            <span v-if="scope.row.charge_type == 2">按期</span>
          </template>
        </el-table-column>
        <el-table-column property="listm" label="学费标准" width="150">
          <template slot-scope="scope">
            <div v-for="item in scope.row.listm">
              <span class="">{{ item.num }}课时 = {{ item.money }}元</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授课模式" prop="tmode" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.tmode == 0" class="class-tag">班</span>
            <span v-if="scope.row.tmode == 1">
              <svg class="icon" aria-hidden="true"><use xlink:href="#icon-dui"></use></svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column property="openclasscount" label="开班数" align="center" width="70"></el-table-column>
      </as-list>
    </div>

    <div class="margin-top flex justify-content-center">
      <el-popover placement="top" width="300" trigger="hover" :disabled="selectedCourses.length < 1">
        <template v-if="selectedCourses.length > 0">
          <div class="flex justify-content-center aligin-items-center" v-for="(course, courseIndex) in selectedCourses" :key="courseIndex">
            <span>{{ course.className }}(校区)</span>
            <i class="el-icon-error el-icon--right cursor" @click="delSelectedCourse(courseIndex)"></i>
          </div>
        </template>
      </el-popover>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(true)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      templateRadio: '',
      // 校区数组
      schoolOptions: [],
      queryResult: {
        total: 0
      },
      charge_type: [],
      listQuery: {
        open_school: [],
        curriculum_type: [],
        curriculum_subject: [],
        charge_type: [],
        curriculumseason: [],
        PageSize: 15,
        KeyWord: ''
      },
      keywordTimer: '',
      schoolValue: '', // 校区值
      courseClassList: [], // 课程类别
      SubjectTypeList: [], // 科目
      isShow: false,
      isArrearage: [],
      courseTypeInput: '', // 科目类别
      coursesTypeList: [], // 科目类别数据
      courseInput: '', // 科目
      coursesList: [], // 科目数据
      courseName: '',
      dialogVisible: true,
      datalist: [],
      multipleSelection: {},
      name: '',
      selectedCourses: {},
      listQuery1: {
        HasRole: false,
        module: '',
        KeyWord: '',
        PageIndex: 1,
        PageSize: 15
      },
      querySchooldata: {
        type: 1,
        PageIndex: 1,
        PageSize: 15
      }
    };
  },
  created() {
    this.search();
    this.getSchoolList();
    this.getCourseClass();
    this.getSubjectTypeList();
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit('dialogClose', {
        type: 'ChooseCoursesSingle',
        data: this.selectedCourses
      });
    },
    'listQuery.KeyWord': {
      handler() {
        clearTimeout(this.keywordTimer);
        this.keywordTimer = setTimeout(() => {
          this.search();
        }, 500);
      }
    }
  },
  methods: {
    // 收费模式选择
    chargeTypeChange(e) {
      console.log(this.charge_type);
    },
    getTemplateRow(index, row) {
      //获取选中数据
      this.selectedCourses = row
    },
    change(e) {
    },
    // 选中行
    singleSelect(e) {
      this.templateRadio = e.id
      this.selectedCourses = e
    },
    selectAllChange() {},
    getCourseClass() {
      this.listQuery1.module = 'course_class';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.courseClassList = res.data.rows;
        this.$forceUpdate();
      });
    },
    getSubjectTypeList() {
      this.listQuery1.module = 'SubjectType';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.SubjectTypeList = res.data.rows;
        this.$forceUpdate();
      });
    },
    close() {
      this.dialogVisible = false;
    },
    search() {
      this.listQuery.PageIndex = 1;
      this.getList();
    },
    getList() {
      this.API.getCurriculumList(this.listQuery).then(res => {
        this.datalist = res.data.rows || [];
        this.queryResult = Object.assign({}, this.queryResult, res.data);
      });
    },
    pageChange(pageindex) {
      this.listQuery.PageIndex = pageindex;
      this.getList();
    },
    handleSelectionChange(val) {
      this.selectedCourses = val;
    },
    delSelectedCourse(index) {
      this.selectedCourses.splice(index, 1);
    },
    // 删除课程类别btn
    delCoursesType(index) {
      this.coursesTypeList.splice(index, 1);
    },
    // 删除科目btn
    delCourses(index) {
      this.coursesList.splice(index, 1);
    },
    // 清空筛选
    clearBtn() {
      this.coursesTypeList = [];
      this.coursesList = [];
    },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 15
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolOptions = res.data.rows;
        }
      });
    },
    // 校区选择
    schoolChange(e) {
      this.listQuery.open_school = e;
      this.search();
    }
  }
};
</script>
<style scoped>
.icon {
  width: 25px;
  height: 25px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
>>> .el-table__body tr.current-row>td{
  background-color: #2fa4e7 !important;
  color: #fff;
}
>>> .el-radio__input.is-checked+.el-radio__label {
  color: #FFFFFF !important; 
}
>>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #FFFFFF !important;
}
</style>
