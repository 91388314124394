import Vue from "vue";
import Bus from './bus'
import store from '../store'
import router from '../router'
import ChooseClass from "../components/choose-class";
import introducer from "../components/introducer";
import ChooseTeacher from '../components/choose-teacher';
import CreatStudents from '../components/create-students';
import ChooseCourses from '../components/choose-courses';
import AddTextbook from '../components/add-textbook';
import AddOrderTag from '../components/add-order-tag';
import TaskSubmission from '../components/new-task'
import ChooseContact from '../components/choose-contact'
import AssignPhonePlan from '../components/assign-phone-plan'
import PlanTemplate from '../components/plan-template'
import CustomerScreening from '../components/customer-screening'
import ChooseCoursesSingle from '../components/choose-courses-single';
import ClassOneToOne from '../components/class-one-to-one'
import SpeechRecognition from '../components/speech-recognition'
import ImageRecognition from '../components/image-recognition'
import OutboundCall from '../components/outbound-call'
import PhonePlanMessage from '../components/add-plans/phonePlan.vue'
import QQPlanMessage from '../components/add-plans/QQPlan.vue'
import SMSPlanMessage from '../components/add-plans/SMSPlan.vue'
import WeixinPlanMessage from '../components/add-plans/weixinPlan.vue'
import TixinPlanMessage from '../components/add-plans/tixinPlan.vue'
import OutboundCalling from '../components/outbound-calling'
import planDetails from '../components/add-plans/planDetails.vue'
import taskDetails from '../components/add-plans/taskDetails.vue'
import selectDepartment from '../components/select-department'
import ChooseCustomerlabel from '../components/choose-Customerlabel'
import sharedClient from '../components/shared-client'
import selectEmployees from '../components/select-employees'
import CheckIn from '../components/check-in'
import DepartmentalTasks from '../components/departmental-tasks'
import ViewNotice from '../components/view-notice'
import ChooseSchool from '../components/choose-school'
import ChooseStaff from '../components/choose-staff'
import DepartmentTaskDetails from '../components/add-plans/departmentTaskDetails'
import staffTaskDetails from '../components/add-plans/staffTaskDetails'

const init = (dialogComponent, name, data) => {
	const PopupBox = Vue.extend({
		...dialogComponent,
		router,
		store
	});
	return new Promise((resovle) => {
		let instance = new PopupBox({
			data() {
				return {
					...data,
				}
			}
		}).$mount();
		instance.$store = Vue.$store;
		document.body.appendChild(instance.$el);
		Dialog.installs[name] = {
			instance,
			resovle,
		};
	});

};

const Dialog = {
	installs: {},
	ChooseClass(data) {
		return init(ChooseClass, 'ChooseClass', data || {});
	},
	introducer(data) {
		return init(introducer, 'introducer', data || {});
	},
	ChooseTeacher(data) {
		return init(ChooseTeacher, 'ChooseTeacher', data || {});
	},
	ChooseCourses(data) {
		return init(ChooseCourses, 'ChooseCourses', data || {});
	},
	CreatStudents(data) {
		return init(CreatStudents, 'CreatStudents', data || {});
	},
	AddTextbook(data) {
		return init(AddTextbook, 'AddTextbook', data || {});
	},
	AddOrderTag(data) {
		return init(AddOrderTag, 'AddOrderTag', data || {});
	},
	TaskSubmission(data) {
		return init(TaskSubmission, 'TaskSubmission', data || {});
	},
	ChooseContact(data) {
		return init(ChooseContact, 'ChooseContact', data || {});
	},
	AssignPhonePlan(data) {
		return init(AssignPhonePlan, 'AssignPhonePlan', data || {});
	},
	PlanTemplate(data) {
		return init(PlanTemplate, 'PlanTemplate', data || {});
	},
	CustomerScreening(data) {
		return init(CustomerScreening, 'CustomerScreening', data || {});
	},
	ChooseCoursesSingle(data) {
		return init(ChooseCoursesSingle, 'ChooseCoursesSingle', data || {});
	},
	ClassOneToOne(data) {
		return init(ClassOneToOne, 'ClassOneToOne', data || {});
	},
	//语音识别
	SpeechRecognition(data) {
		return init(SpeechRecognition, 'SpeechRecognition', data || {});
	},
	//图片识别
	ImageRecognition(data) {
		return init(ImageRecognition, 'ImageRecognition', data || {});
	},
	//电话助手
	OutboundCall(data) {
		return init(OutboundCall, 'OutboundCall', data || {});
	},
	//电话计划
	PhonePlanMessage(data) {
		return init(PhonePlanMessage, 'PhonePlanMessage', data || {});
	},
	//QQ计划
	QQPlanMessage(data) {
		return init(QQPlanMessage, 'QQPlanMessage', data || {});
	},
	//短信计划
	SMSPlanMessage(data) {
		return init(SMSPlanMessage, 'SMSPlanMessage', data || {});
	},
	//微信计划
	WeixinPlanMessage(data) {
		return init(WeixinPlanMessage, 'WeixinPlanMessage', data || {});
	},
	//提醒计划
	TixinPlanMessage(data) {
		return init(TixinPlanMessage, 'TixinPlanMessage', data || {});
	},
	//拨打电话
	OutboundCalling(data) {
		return init(OutboundCalling, 'OutboundCalling', data || {});
	},
	//计划详情
	planDetails(data) {
		return init(planDetails, 'planDetails', data || {});
	},

	//任务详情
	taskDetails(data) {
		return init(taskDetails, 'taskDetails', data || {});
	},
	//选择部门
	selectDepartment(data) {
		return init(selectDepartment, 'selectDepartment', data || {});
	},
	//选择客户标签
	ChooseCustomerlabel(data) {
		return init(ChooseCustomerlabel, 'ChooseCustomerlabel', data || {});
	},
	//选择可共享的销售团队
	sharedClient(data) {
		return init(sharedClient, 'sharedClient', data || {});
	},
	//选择员工
	selectEmployees(data) {
		return init(selectEmployees, 'selectEmployees', data || {});
	},
	//签到
	CheckIn(data) {
		return init(CheckIn, 'CheckIn', data || {});
	},
	//部门任务
	DepartmentalTasks(data) {
		return init(DepartmentalTasks, 'DepartmentalTasks', data || {});
		//查看通知
	},
	ViewNotice(data) {
		return init(ViewNotice, 'ViewNotice', data || {});
	},
	//选择学校
	ChooseSchool(data){
		return init(ChooseSchool, 'ChooseSchool', data || {});
	},
	//选择咨询专员
	ChooseStaff(data){
		return init(ChooseStaff, 'ChooseStaff', data || {});
	},
	//部门任务详情
	DepartmentTaskDetails(data){
		return init(DepartmentTaskDetails, 'DepartmentTaskDetails', data || {});
	},
	//专员任务详情
	staffTaskDetails(data){
		return init(staffTaskDetails, 'staffTaskDetails', data || {});
	}

};
Bus.$on('dialogClose', (res) => {
	let currentInstall = Dialog.installs[res.type];
	let instance = currentInstall && currentInstall.instance;
	let resovle = currentInstall && currentInstall.resovle;
	if (instance) {
		document.body.removeChild(instance.$el);
		instance.$destroy();
		instance = null;
		delete Dialog.installs[res.type];
	}
	if (resovle && typeof resovle === 'function') {
		resovle(res.data);
	}
});

export default Dialog;
