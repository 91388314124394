
import Layout from '@/views/layout'

const majorFunctionRouter = {
  path: '/majorFunction',
  component: Layout,
  redirect: '/reportCenter/index',
  name: '报表中心',
  meta: {
    title: '报表中心',
    icon: 'table'
  },
  children: [
    {
      path: 'reportCenter',
      component: () => import('@/views/majorFunction/reportCenter/index'),
      name: 'reportCenter',
      meta: { title: '报表中心' }
    },
  ]
}
export default majorFunctionRouter
