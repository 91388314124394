<template>
  <el-dialog
    title="通知详情"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="scrollbar" style="height:500px">
      <div class="flex justify-content-center">
        <span style="font-size: 24px">{{ datalist.notice_title }}</span>
      </div>
      <div class="flex justify-content-around margin-top">
        <span>{{ datalist.Username }}</span
        ><span v-if="datalist.addtime">{{
          datalist.addtime | dateTimeFilter
        }}</span>
      </div>
      <div
        class="margin-top"
        v-html="datalist.notice_content"
      ></div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      datalist: [],
      dialogVisible: true,
      listQuery: {
        FolderName: "",
        ParentId: 0,
        FolderType: "",
      },
    };
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "ViewNotice",
        data: this.multipleSelection,
      });
    },
  },
  created() {
    this.getNotice();
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    //获取通知
    getNotice() {
      this.API.Get_Notice({ id: this.id }).then((res) => {
        this.datalist = res;
      });
    },

    close() {
      this.$emit("closepop");
    },
  },
};
</script>
