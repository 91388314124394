import request from '@/utils/request'

export default {
	imageRecognition(data) {
        return request({
            url: '/Other/Picture_recognition',
            method: 'post',
            data
        })
    },
}