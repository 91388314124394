<template>
  <el-dialog
    title="语音识别"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="speech-recognition">
      <el-input
        type="textarea"
        :rows="10"
        placeholder="点击下方按钮开始识别"
        v-model="content"
      >
      </el-input>
      <div class="time-box">
        <i
          class="el-icon-microphone"
          style="color: #409eff"
          @click="changeStatus(false)"
          v-show="recognitioning"
          title="点击停止识别"
        ></i>
        <i
          class="el-icon-turn-off-microphone"
          @click="changeStatus(true)"
          v-show="!recognitioning"
          title="点击开始说话"
        ></i>
        <span class="start-taste-line" :class="{ active: recognitioning }">
          <hr class="hr hr1" />
          <hr class="hr hr2" />
          <hr class="hr hr3" />
          <hr class="hr hr4" />
          <hr class="hr hr5" />
          <hr class="hr hr6" />
          <hr class="hr hr7" />
          <hr class="hr hr8" />
          <hr class="hr hr9" />
          <hr class="hr hr10" />
        </span>
        <span class="total-time"
          ><span class="used-time">{{ timesFormat }}</span></span
        >
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(false)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
      contentHistory:'',
      content: "",
      recognitioning: false,
      times: 0,
      startTime: "",
      endTime: "",
      timer: "",
    };
  },
  created() {
    this.timer = setInterval(() => {
      if (this.recognitioning) {
        this.endTime = new Date();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    timesFormat() {
      let times = this.times + (this.endTime - this.startTime);
      times = times / 1000;
      return `${Math.floor(times / 60)
        .toString()
        .padStart(2, "0")}:${Math.floor(times % 60)
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    dialogVisible() {
      this.$SpeedRecognition.stop();
      this.$bus.$emit("dialogClose", {
        type: "SpeechRecognition",
        data: this.content,
      });
    },
  },
  methods: {
    close(flag) {
      this.dialogVisible = false;
      this.$SpeedRecognition.stop();
    },
    changeStatus(flag) {
      this.recognitioning = flag;
      if (flag) {
        this.times += this.endTime - this.startTime;
        this.startTime = new Date();
        this.endTime = new Date();
        //开始
        this.$SpeedRecognition.start((message) => {
          this.content = this.contentHistory + message;
        });
      }else{
        this.contentHistory = this.content;
        this.$SpeedRecognition.stop();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[class^="el-icon-"] {
  font-size: 30px;
  cursor: pointer;
  margin-right: 20px;
}
</style>
<style lang="css" scoped>
.time-box {
  margin-top: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.time-box .total-time {
  margin-left: 20px;
}

.time-box .start-taste-line {
  display: inline-block;
  margin-right: 20px;
}
.time-box .start-taste-line hr {
  background-color: #187cff;
  width: 3px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;
  border: none;
}
.time-box .start-taste-line.active hr {
  animation: note 0.2s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.time-box .start-taste-line.active .hr1 {
  animation-delay: -1s;
}

.time-box .start-taste-line.active .hr2 {
  animation-delay: -0.9s;
}

.time-box .start-taste-line.active .hr3 {
  animation-delay: -0.8s;
}

.time-box .start-taste-line.active .hr4 {
  animation-delay: -0.7s;
}

.time-box .start-taste-line.active .hr5 {
  animation-delay: -0.6s;
}

.time-box .start-taste-line.active .hr6 {
  animation-delay: -0.5s;
}

.time-box .start-taste-line.active .hr7 {
  animation-delay: -0.4s;
}

.time-box .start-taste-line.active .hr8 {
  animation-delay: -0.3s;
}

.time-box .start-taste-line.active .hr9 {
  animation-delay: -0.2s;
}

.time-box .start-taste-line.active .hr10 {
  animation-delay: -0.1s;
}

@keyframes note {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(4);
  }
}
</style>