const fileType=[
   {
    label: '档案类型一',
    value: 1
   },{
    label: '档案类型二',
    value: 2
   },{
    label: '档案类型三',
    value: 3
   },{
    label: '档案类型四',
    value: 4
   },{
    label: '档案类型五',
    value: 5
   }
];
export default fileType;