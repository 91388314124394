const TokenKey = 'token'

export function getToken() {
  var token = localStorage.getItem(TokenKey);
  return token;
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.setItem(TokenKey, '');
}
