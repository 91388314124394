<template>
  <div class="as-list" v-loading="listLoading">  
    <el-table
      v-if="isChoice"
      border
      v-loading="loading"
      stripe
      ref="multipleTable"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :data="datalist"
    >
      <slot></slot>
    </el-table>
    <el-table
      v-else
      :data="datalist"
      border
      v-loading="loading"
      stripe
      :highlight-current-row="isSingle"
      @current-change="handleCurrentChange"
      :cell-class-name="cellStyle"
      @cell-click="cellClick"
      @selection-change="handleSelectionChange"
    >
      <slot></slot>
    </el-table>
    <div class="as-list-pager" v-if="isPagination">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes,jumper"
        highlight-current-row
        :total="datacount"
        :page-size="listQuery.pageSize"
        :page-sizes="[15, 20, 50, 100, 200, 500]"
        @current-change="pageIndexChange"
        @size-change="pageSizeChange"
        style="text-align: center"
      />
    </div>
  </div>
</template>
<script>
import listMixins from "@/mixins/list";
export default {
  name: "AsList",
  mixins: [listMixins],
  props: {
    ajaxMethods: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listQueryProp: {
      type: Object,
      default: () => {
        return {
          PageIndex: 1,
          PageSize: 15,
        };
      },
    },
    initdataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isChoice: {
      type: Boolean,
      default: false,
    },
    Chooseone: {
      type: Boolean,
      default: false,
    },
    // 是否有单选
    isSingle: {
      type: Boolean,
      default: false,
    },
    // 是否显示分页
    isPagination: {
      type: Boolean,
      default: true,
    },
    // 单元格样式
    cellStyle: {
      type: Function,
      default: () => {
        return new Function();
      },
    },
  },
  data() {
    return {
      loading: false,
      datalist: [],
      multipleSelection: [],
    };
  },
  watch: {
    listQueryProp: {
      deep: true,
      handler: function (val) { 
        this.listQuery = val;
        // debugger;
        this.getList();
      },
    },
    initdataList: {
      deep: true,
      handler() {
        this.datalist = this.initdataList;
        this.loading = false;
      },
    },
    ajaxResult: {
      deep: true,
      handler: function (val) {
        this.$emit("requestChange", val);
        this.loading = false;
      },
    },
  },
  created() {
    this.ajaxMethod = this.ajaxMethods;
    this.listQuery = Object.assign({}, this.listQuery, this.listQueryProp);

    this.datalist = this.initdataList;
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("SelectionChange", val);
      this.loading = false;
    },
    refsh(data) {
      this.datalist = data;
    },
    handleCurrentChange(e) {
      this.$emit("singleSelect", e);
      this.loading = false;
    },
    cellClick(row, column, cell, event) {
      this.$emit("cellClick", row, column, cell, event);
    }
  },
};
</script>
<style lang="scss" scoped>
.as-list {
  // min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.as-list-pager {
  height: 50px;
  background-color: #fff;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #fff;
}
</style>
<style scoped>
>>> .el-table {
  height: 100%;
}
>>> .el-table__body-wrapper {
  height: 100%;
  overflow-y: auto;
}
</style>