
import Layout from '@/views/layout'
const parentCenterRouter = {
  path: '/parentCenter',
  component: Layout,
  redirect: '/classroomComments/index',
  name: '家校中心',
  meta: {
    title: '家校中心',
    icon: 'table'
  },
  children: [
    {
      path: 'classroomComments',
      component: () => import('@/views/parentCenter/classroomComments/index'),
      name: 'classroomComments',
      meta: { title: '课堂点评' }
    },{
      path: 'setUp',
      component: () => import('@/views/parentCenter/setUp/index'),
      name: 'setUp',
      meta: { title: '设置' }
    },{
      path: 'task',
      component: () => import('@/views/parentCenter/task/index'),
      name: 'task',
      meta: { title: '作业' }
    },{
      path: 'Assignment',
      component: () => import('@/views/parentCenter/task/Assignment/index'),
      name: 'Assignment',
      meta: { title: '布置作业' }
    },{
      path: 'growthRecord',
      component: () => import('@/views/parentCenter/growthRecord/index'),
      name: 'growthRecord',
      meta: { title: '成长记录' }
    },{
      path: 'noticeNotice',
      component: () => import('@/views/parentCenter/noticeNotice/index'),
      name: 'noticeNotice',
      meta: { title: '通知公告' }
    },{
      path: 'serviceCentre',
      component: () => import('@/views/parentCenter/serviceCentre/index'),
      name: 'serviceCentre',
      meta: { title: '服务中心' }
    },{
      path: 'newNotification',
      component: () => import('@/views/parentCenter/noticeNotice/newNotification/index'),
      name: 'newNotification',
      meta: { title: '新建通知' }
    },{
      path: 'Template',
      component: () => import('@/views/parentCenter/noticeNotice/Template/index'),
      name: 'Template',
      meta: { title: '模板' }
    },{
      path: 'dataAnalysis',
      component: () => import('@/views/parentCenter/dataAnalysis/index'),
      name: 'dataAnalysis',
      meta: { title: '数据分析' }
    },
  ]
}
export default parentCenterRouter
