<template>
	<el-dialog title="新建任务" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="680px">

		<div  class="myClient-paddingright-10">

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="任务标题" prop="TaskTitle">
					<el-input v-model="ruleForm.TaskTitle " placeholder="请输入任务标题"></el-input>
				</el-form-item>
					<el-form-item label="任务内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入任务内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>

				<el-form-item class="flex justify-content-flexEnd">
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				changeName: '',
				value1: '',
				CutomerId: '',
				executor: 'my',
				Information: {},
				ruleForm: {
					TaskTitle: '',
					TaskContent: '',
					Type: 0, //计划类型 0-部门任务 1-个人任务
				},
				rules: {
					TaskTitle: [{
						required: true,
						message: '请输入任务标题',
						trigger: 'blur'
					}, ],
					TaskContent: [{
						required: true,
						message: '请填写发送内容',
						trigger: 'blur'
					}]
				},
				pickerOptions0: {
					disabledDate: (time) => {
						if (this.ruleForm.CompleteTime) {
							return time.getTime() > this.ruleForm.CompleteTime;
						}
					}
				},
				pickerOptions1: {
					disabledDate: (time) => {
						return time.getTime() < this.ruleForm.RemindTime
					}
				}
			}
		},
		created() {
			
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			submitForm(formName) {
				let _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						setTimeout(() => {
							this.taskAdd()
						}, 100)
					} else {
						return false;
					}
				});
			},
			taskAdd() {
				this.API.CustomerTaskEditTas(this.ruleForm).then(res => {
					if (res.code == 200) {
						this.$message.success("操作任务成功")
						this.$bus.$emit("dialogClose", {
							type: "DepartmentalTasks",
							data: '成功',
						});
						this.close()
					}
				})
			},
		
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
