import request from '@/utils/request'

export default {
	//创建文件夹
	cloudDiskEditFolder(data) {
		return request({
			url: '/CloudDisk/Edit_Folder',
			method: 'post',
			data
		})
	},
	//查看文件夹
	cloudDiskSelectFolder(data) {
		return request({
			url: '/CloudDisk/Select_Folder',
			method: 'post',
			data
		})
	},
	//查看该文件夹下的文件
	cloudDiskSelectFile(data) {
		return request({
			url: '/CloudDisk/Select_File',
			method: 'post',
			data
		})
	},
	//删除文件夹
	cloudDiskDeleteFolder(query) {
		return request({
			url: '/CloudDisk/Delete_Folder',
			method: 'post',
			params: query
		})
	},
	//查看文件夹下级的文件夹
	cloudDiskSelectChildFolder(data) {
		return request({
			url: '/CloudDisk/Select_ChildFolder',
			method: 'post',
			data
		})
	},
	//上传文件
	cloudDiskAddFile(data) {
		return request({
			url: '/CloudDisk/Add_File',
			method: 'post',
			data
		})
	},
	//删除文件
	cloudDiskDeleteFile(query) {
		return request({
			url: '/CloudDisk/Delete_File',
			method: 'post',
			params: query
		})
	},
}
