<template>
	<section class="app-main">
		<app-header></app-header>
		<div class="right-content">
			<div class="Breadcrumb-Navigation">
				
				<a class="nav-title" href="/">首页</a>
				<span class="nav-label">/</span><a class="nav-title">{{ this["$route"].meta.title}}</a>
			</div>
			<div class="right-content-content" :class="{'content-bg':currentRoute.path!=='/'}" >
				<transition name="fade-transform" mode="out-in">
					<template v-if="currentRoute.meta.keepAlive">
						<keep-alive>
							<router-view :key="key" />
						</keep-alive>
					</template>
					<template v-else>
						<router-view :key="key" />
					</template>
				</transition>
			</div>
		</div>
	</section>
</template>

<script>
	import AppHeader from "./AppHeader.vue";
	export default {
		name: "AppMain",
		components: {
			AppHeader,
		},
		computed: {
			currentRoute() {
				return this.$route;
			},
			key() {
				return this.$route.path;
			},
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>
<style scoped>
	>>>.el-menu.el-menu--horizontal {
		border: 0;
	}
</style>
<style lang="scss" scoped>
	.app-main {
		/* 50= navbar  50  */
		flex: 1;
		position: relative;
		padding: 90px 0 0 280px;
		width: 100%;
		background-image: linear-gradient(to bottom right, #2FA4E7, #11DDF6);
		min-height: 100%;

		.right-content {
			margin: 0 10px;
			// background-image: linear-gradient(to bottom, #278bb3, #eaeaea);
			height: calc(100vh - 80px);
			border-radius: 5px !important;

			.right-content-content {
				&.content-bg{
					background-color: #fff;
					box-shadow: 0px 0px 10px #808080;
				}
				height: calc(100vh - 150px);
				min-height: calc(100vh - 150px);
				max-height: calc(100vh - 150px);
				overflow-y: auto;
				overflow-x: hidden;
				border-radius: 5px;
			}
		}
	}

	.Breadcrumb-Navigation {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #F5F5F5;
		padding-left: 20px;
		margin-bottom: 10px;
		box-shadow: 0px 0px 10px #808080;
		border-radius: 5px;

		.nav-title {
			color: #37A7E7;
		}

		.nav-label {
			margin: 0px 10px
		}
	}
</style>

<style lang="scss">
</style>
