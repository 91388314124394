<template>
  <el-dialog
    title="电话助手"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
	width="780px"
  >
    <div class="outbound-call">
      <div class="oc-left">
        <div class="oc-search-box">
          <el-input
            size="small"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="KeyWord"
          ></el-input>
        </div>
        <el-radio-group size="small" v-model="currentType" class="oc-tabs">
          <el-radio-button :label="1" :value="1">待拨打</el-radio-button>
          <el-radio-button :label="2" :value="2">已拨打</el-radio-button>
        </el-radio-group>
        <ul class="scrollbar">
          <li v-for="item in currentList" :key="item.id" @click="changeCurrentUser(item)" :class="{'active':currentUser.id == item.id}">
            <div class="flex justify-content-between">
              <h3>{{ item.UserName }}</h3>
              <span>{{ item.City }}</span>
            </div>
            <div class="flex justify-content-between">
              <span>{{item.LastCallTime | dateTimeFilter('yyyy-MM-dd HH:mm:ss','无最近通话记录')}}</span>
              <span
                @click="handleCall(item)"
                class="iconfont icon-dianhua1 cursor"
                title="拨打电话"
              ></span>
            </div>
          </li>
        </ul>
      </div>
      <div class="oc-right scrollbar">
        <CustomerDetail :userInfo="currentUser" v-if="currentUser.id" @outboundCall="handleCall"></CustomerDetail>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CustomerDetail from "./components/customerDetail";
export default {
  components: {
    CustomerDetail,
  },
  data() {
    return {
      dialogVisible: true,
      currentType: 1, //1待拨打，2已拨打
      KeyWord: "",
      timer: "",
      listWaitCall: {
        list: [],
        queryModel: {
          pageIndex: 1,
          pageSize: 100,
          KeyWord: "",
          CallSatus: 1,
        },
      },
      listHasCalled: {
        list: [],
        queryModel: {
          pageIndex: 1,
          pageSize: 100,
          KeyWord: "",
          CallSatus: 2,
        },
      },
      content: "",
      recognitioning: false,
      file: "",
      currentUser:{},
    };
  },
  created() {
    this.getList(1);
    this.getList(2);
  },
  computed: {
    currentList() {
      if (this.currentType === 1) {
        return this.listWaitCall.list;
      } else {
        return this.listHasCalled.list;
      }
    },
  },
  mounted() {
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "OutboundCall",
        data: this.content,
      });
    },
    KeyWord(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search();
      });
    },
  },
  methods: {
    changeCurrentUser(user){
      this.currentUser=user;
    },
    getList(type) {
      let curType = type || this.currentType;
      let modeName = curType == 1 ? "listWaitCall" : "listHasCalled";
      return this.API.getSelectCallList(this[modeName].queryModel).then(
        (res) => {
          if (this[modeName].queryModel.pageIndex === 1) {
            this[modeName].list = [];
          }
          (res.data.rows || []).forEach((item) => {
            this[modeName].list.push(item);
          });
        }
      );
    },
    search() {
      this.listWaitCall.queryModel.KeyWord = this.KeyWord;
      this.listHasCalled.queryModel.KeyWord = this.KeyWord;
      this.getList(1);
      this.getList(2);
    },
    close(flag) {
      this.dialogVisible = false;
    },
    updateCalledStatus(DialId) {
      this.API.postUpdateCallStatus({ DialId }).then((res) => {
        this.getList(1);
        this.getList(2);
      });
    },
    handleCall(item,phone) {
      item.PhoneNumber = phone || item.PhoneNumber1;
      this.$Dialog.OutboundCalling({userInfo:item}).then((res) => {
        this.updateCalledStatus(item.DialId);
        this.$message({
          message: "通话已结束",
          type: "success",
        });
      });
    },
    
  },
};
</script>

<style scoped>
>>> .el-dialog__body {
  padding: 0 !important;
}
</style>>
<style lang="scss" scoped>
#moor-box-hide {
  position: absolute;
  left: -99999999;
  opacity: 0;
  color: transparent;
  background-color: transparent;
}
.outbound-call {
  display: flex;
  min-height: 600px;
  .oc-left {
    width: 200px;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    .oc-search-box {
      padding: 10px;
    }
    .oc-tabs {
      padding: 10px;
    }
    ul {
      width: 100%;
      height: 100%;
      max-height: 600px;
      flex: 1;
      li {
        background-color: #f0f2f4;
        margin-bottom: 5px;
        padding: 5px 20px;
        display: flex;
        flex-direction: column;
        height: 60px;
        justify-content: space-around;
        cursor: pointer;
        &:hover{
          background-color: #fff;
          border:2px solid #0077aa;
        }
        &.active{
          background-color: #fff;
          border:2px solid #0077aa;
        }
        span {
          color: #aaa;
          font-size: 12px;
        }
        .iconfont {
          font-size: 14px;
          &:hover {
            color: #409eff;
          }
        }
      }
    }
  }
  .oc-right {
    flex: 1;
    height: 100%;
  }
}
</style>