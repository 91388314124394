<template>
  <el-dialog title="新建标签" :visible.sync="dialogFormVisible" width="22%" top="30%">
    <div class="flex aligin-items-center">
      <el-form :model="form" label-width="90px">
        <el-form-item
          label="标签名"
          prop="sub_title"
          :rules="[{ required: true, message: '请输入标签名称', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }]"
        >
          <el-input v-model="form.sub_title" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogFormVisible: true,
        form: {
          sub_title: ''
        },
      }
    },
    watch: {
      dialogVisible() {
        this.$bus.$emit("dialogClose", {
          type: "AddOrderTag",
          data: this.form,
        });
      },
    },
    methods: {
      close() {
        this.dialogFormVisible = false;
      },
      submit(){
      	let ruleForm = this.form;
      	this.$Dictionary.AddDictionary({
      		module: this.$Dictionary.moduleConfig.OrderTag,
          title: '订单标签',
      		...ruleForm
      	}).then(res => {
      		this.$message.success("添加成功")
      		this.close();
      	});
      }
    },
  }
</script>

<style scoped>
</style>
