<template>
  <el-dialog
    title="选择班级"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-input v-model="teacherName"></el-input>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(true)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
      teacherName:'',
    };
  },
  created() {
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "ChooseTeacher",
        data: this.teacherName,
      });
    },
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>