<template>
	<el-dialog title="选择员工" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<el-select v-model="roleform.userid" multiple placeholder="请选择">
				<el-option v-for="(item,index) in EmployeesList" :key="index" :label="item.full_name" :value="item.id">
				</el-option>
			</el-select>
			<div class="flex justify-content-flexEnd margin-top">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" class="myClient-left-30" v-preventReClick="500" @click="submit">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import API from '../../api/index.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				org_ids: 0, //传值过来的组织id
				roleform:{
					org_ids:0,
					userid:[],
				},
				value1: [],
				EmployeesList: [],
				selectEmployee: {
					// AccountType: [0],
					// orgids: [0],
					// roleids: [0],
					// personnel_status: [0],
					// labor_relations: [0],
					// is_teacher: true,
					// UserName: "string",
					// Phone: "string",
					// birthday: "string",
					// has_contract: true,
					// has_social_security: true,
					// tag: "string",
					post_type:[4019],
					PageIndex: 1,
					PageSize: 10,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				},
			}
		},
		created() {
			this.getEmployeesMore()
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			//获取所有的咨询专员
			getEmployeesMessage(data) {
				return new Promise((resolve, reject)=>{
					this.API.accountQuery(data).then(res=>{
						resolve(res)
					})
				})
			},
			getEmployeesMore(){
				this.getEmployeesMessage(this.selectEmployee).then(res=>{
					let num=Math.ceil( res.data.total/this.selectEmployee.PageSize)
					if(num>=this.selectEmployee.PageIndex){
						this.selectEmployee.PageIndex++
						this.getEmployeesMessage(this.selectEmployee).then(t=>{
							this.EmployeesList=this.EmployeesList.concat(res.data.rows,t.data.rows)
						})
					}
				})
			},
			submit() {
				this.API.OrganizationAddUserOrgs({ids:this.roleform.userid},{org_id:this.org_ids}).then(res=>{
					if(res.code==200){
						this.$message.success("添加成功");
						this.close()
						this.$bus.$emit('dialogClose', {
						  type: 'selectEmployees',
						});
					}
				})

			}
		}
	}
</script>

<style>
</style>
