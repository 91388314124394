import request from '@/utils/request'

export default {
	//编辑任务
	CustomerTaskEditTas(data) {
		return request({
			url: '/CustomerTask/Edit_TasK' ,
			method: 'post',
			data
		})
	},
	//根据用户Id来查询任务列表
	CustomerTaskGetTaskListByCustomerID(data) {
		return request({
			url: '/CustomerTask/Get_TaskListByCustomerID' ,
			method: 'post',
			data
		})
	},
	//删除任务
	CustomerTaskDelete(ids) {
		return request({
			url: '/CustomerTask/Delete?ids=' +ids,
			method: 'post',
		})
	},
	//查看任务的详情
	CustomerTaskGetTaskInfo(ids) {
		return request({
			url: '/CustomerTask/Get_TaskInfo?TaskId=' +ids,
			method: 'get',
		})
	},
	//完成任务
	CustomerTaskCompleteTask(ids) {
		return request({
			url: '/CustomerTask/Complete_Task?TaskId=' +ids,
			method: 'post',
		})
	},
	//查询今日截止的任务
	CustomerTaskGetTodayTaskList(data) {
		return request({
			url: '/CustomerTask/Get_TodayTaskList',
			method: 'post',
			data,
		})
	},
	//根据状态查询
	CustomerTaskGetTaskListByStatus(data) {
		return request({
			url: '/CustomerTask/Get_TaskListByStatus',
			method: 'post',
			data,
		})
	},
}

