<template>
	<div id="app">
		<router-view />
		<ul class="global-layer" v-show="globalLayerVisible">
			<li class="gl-dianhua" title="拨打电话" @click="outboundCall"><span class="iconfont icon-dianhua1"></span></li>
			<!-- <li class="gl-duanxin" title="短信助手"><span class="iconfont icon-duanxin"></span></li> -->
			<!--      <li class="gl-close-btn" @click="globalLayerVisible = false">隐藏</li> -->
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
			};
		},
		computed:{
			globalLayerVisible(){
				let userInfo = this.$store.getters.getUserInfo;
				if(userInfo){
					let isCall=userInfo.is_call
					return userInfo && userInfo.id && isCall;
				}
				return userInfo && userInfo.id ;
			},
		},
		created() {
		},
		methods: {
			outboundCall() {
				this.$Dialog.OutboundCall();
			},
		},
	}
</script>

<style>
	#app {
		min-height: 100vh;
		font-family: '思源雅黑', Avenir, Helvetica, Arial, sans-serif !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
</style>
<style lang="scss" scoped>
	.global-layer {
		position: fixed;
		bottom: 200px;
		right: 5px;
		display: flex;
		flex-direction: column;
		z-index: 3;
		box-shadow: 0 0 10px rgba(0, 0, 0, .1);

		&:hover {
			.gl-close-btn {
				display: block;
			}
		}

		.gl-close-btn {
			display: none;
			position: absolute;
			bottom: -20px;
			left: 0;
			width: 100%;
			height: 20px;
			line-height: 20px;
			content: '关闭';
			font-size: 12px;
			text-align: center;
			cursor: pointer;
			background-color: rgba(0, 0, 0, .5);
			color: #fff;
		}

		li {
			width: 60px;
			height: 60px;
			color: #f0f2f4;

			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&.gl-dianhua {
				background-color: rgba(0, 160, 250, .7);

				&:hover {
					background-color: rgba(0, 160, 250, .9);
				}
			}

			&.gl-duanxin {
				background-color: rgba(103, 194, 58, .7);

				&:hover {
					background-color: rgba(103, 194, 58, .9);
				}
			}

			&:hover {
				transform: scale(1.1);

				.iconfont {
					font-size: 34px;
				}
			}

			.iconfont {
				font-size: 30px;
			}
		}
	}
</style>
