import request from '@/utils/request'

export default {
  // 查询教室列表
  getClassroomList(data) {
    return request({
      url: '/Classesroom/GetList',
      method: 'post',
      data,
    })
  },
  // 修改/增加教室 /api/Classesroom/Createorupdate
  addClassrooms(data) {
    return request({
      url: '/Classesroom/Createorupdate',
      method: 'post',
      data,
    })
  },
  // 修改教室状态 /api/Classesroom/ChangeStatus
  changesClassroomStatus(data) {
    return request({
      url: '/Classesroom/ChangeStatus',
      method: 'post',
      data,
    })
  },
}
