import request from '@/utils/request'

export default {
	AccountCreate(data) {
		return request({
			url: '/Account/Create',
			method: 'post',
			data,
		})
	},
	accountQuery(query) {
		return request({
			url: '/Account/Query',
			method: 'post',
			data: query
		})
	},
	//修改账号状态
	accountChangeStatus(query) {
		return request({
			url: '/Account/ChangeStatus',
			method: 'post',
			params: query
		})
	},
	//修改账号信息
	accountUpdate(data) {
		return request({
			url: '/Account/Update',
			method: 'post',
			data
		})
	},
	//员工重置密码
	accountResetPassword(query) {
		return request({
			url: '/Account/ResetPassword',
			method: 'post',
			params: query
		})
	},
	//单个账号获取
	accountDetail(query) {
		return request({
			url: '/Account/Detail',
			method: 'get',
			params: query
		})
	},
	//员工签到
	accountSign(data) {
		return request({
			url: '/Account/Sign',
			method: 'post',
			params: data
		})
	},
	//获取具体员工的签到记录
	accountQuerySign(data) {
		return request({
			url: '/Account/QuerySign',
			method: 'post',
			data
		})
	},
}
