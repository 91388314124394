//报读课程
const courseEnrollmentList=[
    {
     label: '福星课程',
     value: 1
    },{
     label: '三国课程',
     value: 2
    },{
     label: '战神课程',
     value: 3
    },{
     label: '决斗课程',
     value: 4
    },{
     label: '摄魂课程',
     value: 5
    }
 ];
 export default courseEnrollmentList;