import userApi from './modules/user'
import orderApi from './modules/order'
import commonApi from './modules/common'
import organizationApi from './modules/organization'
import SystemApi from './modules/system.js'
import accountApi from './modules/account.js'
import roleApi from './modules/role.js'
import otherApi from './modules/other'
import dictionaryApi from './modules/dictionary.js'
import customerApi from './modules/customer.js'
import callApi from './modules/call.js'
import classesApi from './modules/classes.js'
import curriculumApi from './modules/curriculum.js'
import customerPlanApi from './modules/customerPlan.js'
import classesRoomApi from './modules/classesRoom.js'
import userLogApi from './modules/userLog.js'

import customerTaskApi from './modules/customerTask.js'
import CurriculumarrangerecordsApi from './modules/Curriculumarrangerecords.js'
import CurriculumAttendApi from './modules/CurriculumAttend.js' 
import studentApi from './modules/student.js'
import noticeApi from './modules/notice.js'
import cloudDiskApi from './modules/cloudDisk'
import processApi from './modules/process'
import statisticsApi from './modules/statistics'

export default {
  ...userApi,
  ...orderApi,
  ...commonApi,
  ...organizationApi,
  ...SystemApi,
  ...accountApi,
  ...dictionaryApi,
  ...roleApi,
  ...otherApi,
  ...customerApi,
  ...callApi,
  ...studentApi,
  ...classesApi,
  ...curriculumApi,
  ...customerPlanApi,
  ...classesRoomApi,
  ...customerTaskApi,
  ...CurriculumarrangerecordsApi,
  ...CurriculumAttendApi,
  ...cloudDiskApi,
  ...userLogApi,
  ...noticeApi,
  ...processApi,
  ...statisticsApi,
}
