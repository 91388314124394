
import Layout from '@/views/layout'

const salesAssistantRouter = {
  path: '/salesAssistant',
  component: Layout,
  redirect: '/salesAssistant/index',
  name: '销售助手',
  meta: {
    title: '销售助手',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/salesAssistant/index'),
      name: 'salesAssistantIndex',
      meta: { title: '销售助手' }
    },
	{
	  path: 'mytask',
	  component: () => import('@/views/salesAssistant/myTask'),
	  name: 'mytask',
	  meta: { title: '我的任务' }
	},
	{
	  path: 'departmentalTasks',
	  component: () => import('@/views/salesAssistant/departmentalTasks'),
	  name: 'departmentalTasks',
	  meta: { title: '部门任务' }
	},
	{
	  path: 'CommissionerTasks',
	  component: () => import('@/views/salesAssistant/CommissionerTasks'),
	  name: 'CommissionerTasks',
	  meta: { title: '部门任务' }
	},
  ]
}
export default salesAssistantRouter
