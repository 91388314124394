<template>
  <el-dialog title="选择课程" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false">
    <div class="flex aligin-items-center justify-content-between padding-sm">
      <div>
        <el-select v-model="schoolValue" placeholder="请选择" size="medium">
          <el-option v-for="item in schoolOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div><el-input size="medium" placeholder="请输入课程名称关键字搜索" suffix-icon="el-icon-search" v-model="listQuery.KeyWord" style="width: 230px;"></el-input></div>
    </div>

    <div class="padding-sm flex aligin-items-center">
      <el-popover width="60" trigger="click" @show="isShow = !isShow" @hide="isShow = !isShow">
        <el-radio-group v-model="isArrearage" @change="arrearageChange">
          <el-radio :label="3" class="padding-xs">按课时</el-radio>
          <el-radio :label="6" class="padding-xs">按期</el-radio>
          <el-radio :label="9" class="padding-xs">按时间</el-radio>
        </el-radio-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>收费模式</span>
          <i :class="isShow == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="250" trigger="click">
        <el-input v-model="courseTypeInput" placeholder="" size="mini"></el-input>
        <el-checkbox-group v-model="coursesTypeList">
          <el-checkbox class="padding-xs" label="高三1V1" />
          <el-checkbox class="padding-xs" label="小三1V1" />
          <el-checkbox class="padding-xs" label="丽都高三文科全日制" />
          <el-checkbox class="padding-xs" label="交大初三语文班课" />
          <el-checkbox class="padding-xs" label="高二1V1" />
          <el-checkbox class="padding-xs" label="高一1V1" />
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>课程类别</span>
          <i class="el-icon-caret-bottom" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="200" trigger="click">
        <el-input v-model="courseInput" placeholder="" size="mini"></el-input>
        <el-checkbox-group v-model="coursesList">
          <el-checkbox class="padding-xs" label="物理大班" />
          <el-checkbox class="padding-xs" label="语数外政史地" />
          <el-checkbox class="padding-xs" label="美术" />
          <el-checkbox class="padding-xs" label="面试题" />
          <el-checkbox class="padding-xs" label="英语音标班1对1" />
        </el-checkbox-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>科目</span>
          <i class="el-icon-caret-bottom" />
          <el-divider direction="vertical"></el-divider>
        </div>
      </el-popover>
      <el-popover width="150" trigger="click">
        <el-radio-group v-model="isArrearage">
          <el-radio :label="1" class="padding-xs">春季</el-radio>
          <el-radio :label="3" class="padding-xs">夏季</el-radio>
          <el-radio :label="6" class="padding-xs">秋季</el-radio>
          <el-radio :label="9" class="padding-xs">冬季</el-radio>
        </el-radio-group>
        <div slot="reference" class="aligin-items-center flex">
          <span>学季</span>
          <i class="el-icon-caret-bottom" />
        </div>
      </el-popover>
    </div>

    <!-- 课程类别、科目选项 -->
    <div class="padding-lr flex aligin-items-center justify-content-between width-100" v-if="coursesTypeList.length > 0 || coursesList.length > 0">
      <div class="flex flex-direction" style="width: 90%;">
        <div class="text-gray flex aligin-items-center flex-wrap-wrap" v-if="coursesTypeList.length > 0">
          <div>课程类别：</div>
          <div v-for="(btn, btnIndex) in coursesTypeList" :key="btnIndex">
            <div :class="{ 'margin-left': btnIndex > 0 }">
              <div class="padding-top-xs">
                <el-button size="mini">
                  {{ btn }}
                  <i class="el-icon-close" @click="delCoursesType(btnIndex)" />
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-gray flex aligin-items-center padding-top-sm flex-wrap-wrap" v-if="coursesList.length > 0">
          <div>科目：</div>
          <div v-for="(btn, btnIndex) in coursesList" :key="btnIndex">
            <div :class="{ 'margin-left': btnIndex > 0 }">
              <div class="padding-top-xs">
                <el-button size="mini">
                  {{ btn }}
                  <i class="el-icon-close" @click="delCourses(btnIndex)" />
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-blue cursor" style="width: 8%;" @click="clearBtn">清空筛选</div>
    </div>

    <div>
      <el-table :data="datalist" @selection-change="handleSelectionChange" height="400">
        <el-table-column type="selection"></el-table-column>
        <el-table-column property="curriculum_name" label="课程名称" align="center"></el-table-column>
        <el-table-column property="curriculum_type_namme" label="课程类别" align="center"></el-table-column>
        <el-table-column property="charge_type_name" label="收费模式" align="center">
        </el-table-column>
        <el-table-column prop="listm" label="学费标准" align="center" width="140">
          <template slot-scope="scope">
            <div v-for="item in scope.row.listm">
              <span class="margin-left">{{item.num}}课时 = {{item.money}}元</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授课模式"  prop="tmode" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.tmode == 0" class="class-tag" >班</span>
            <span v-if="scope.row.tmode == 1" >
              <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-dui"></use>
                   </svg>
            </span>
          </template>
        </el-table-column>
        <el-table-column property="openclasscount" label="开班数" align="center"></el-table-column>
      </el-table>
      <el-pagination style="margin-top:20px;" background layout="prev, pager, next,total" :total="queryResult.total" @current-change="pageChange"></el-pagination>
    </div>

    <div class="margin-top flex justify-content-center">
      <el-popover placement="top" width="300" trigger="hover" :disabled="selectedCourses.length < 1">
        <template v-if="selectedCourses.length > 0">
          <div class="flex justify-content-center aligin-items-center" v-for="(course, courseIndex) in selectedCourses" :key="courseIndex">
            <span>{{ course.curriculum_name }}</span><!-- ({{course.open_school}}校区) -->
            <i class="el-icon-error el-icon--right cursor" @click="delSelectedCourse(courseIndex)"></i>
          </div>
        </template>

        <el-button slot="reference">
          已选择课程{{ selectedCourses.length || 0 }}个
          <i class="el-icon-error el-icon--right text-grey"></i>
        </el-button>
      </el-popover>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(true)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      // 校区数组
      schoolOptions: [],
      schoolValue: '', // 校区值
      isShow: false,
      isArrearage: '',
      courseTypeInput: '', // 课程类别
      coursesTypeList: [], // 课程类别数据
      courseInput: '', // 科目
      coursesList: [], // 科目数据
      courseName: '',
      dialogVisible: true,
      datalist: [],
      queryResult: {
        total: 0
      },
      listQuery: {
        PageIndex: 1,
        PageSize: 20,
        KeyWord: '',
      },
      keywordTimer: '',
      name: '',
      selectedCourses: [],
      listQuery1: {
        HasRole: false,
        module: '',
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 15
      },
    };
  },
  created() {
    this.search();
  },
  watch: {
    dialogVisible(val) {
      this.$bus.$emit('dialogClose', {
        type: 'ChooseCourses',
        data: this.selectedCourses
      });
    },
    'listQuery.KeyWord': {
      handler() {
        clearTimeout(this.keywordTimer);
        this.keywordTimer = setTimeout(() => {
          this.search();
        }, 500);
      }
    }
  },
  methods: {
    getCourseClass() {
      this.listQuery1.module = 'course_class';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.courseClassList = res.data.rows;
        this.$forceUpdate()
      });
    },
    getSubjectTypeList() {
      this.listQuery1.module = 'SubjectType';
      this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
        this.SubjectTypeList = res.data.rows;
        this.$forceUpdate()
      });
    },
    close() {
      this.dialogVisible = false;
    },
    search() {
      this.listQuery.PageIndex = 1;
      this.getList();
    },
    getList() {
      this.API.getCurriculumList(this.listQuery).then(res => {
        this.datalist = res.data.rows || [];
        this.queryResult = Object.assign({}, this.queryResult, res.data);
      });
    },
    pageChange(pageindex) {
      this.listQuery.PageIndex = pageindex;
      this.getList();
    },
    handleSelectionChange(val) {
      this.selectedCourses = val;
    },
    delSelectedCourse(index) {
      this.selectedCourses.splice(index, 1);
    },
    // 删除课程类别btn
    delCoursesType(index) {
      this.coursesTypeList.splice(index, 1);
    },
    // 删除科目btn
    delCourses(index) {
      this.coursesList.splice(index, 1);
    },
    // 清空筛选
    clearBtn() {
      this.coursesTypeList = [];
      this.coursesList = [];
    },
    // 筛选方法
    arrearageChange(e) {
      this.listQuery.charge_type = e
      this.getList()
    },
    // 获取校区
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 15
      }
      this.API.OrganizationList(obj).then(res => {
        if(res.success) {
          this.schoolOptions = res.data.rows
        }
      })
    },
  }
};
</script>
<style scoped>
  .icon {
    width: 25px;
    height: 25px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
   }
</style>
