
import Layout from '@/views/layout'

const commonPageRouter = {
  path: '/commonPage',
  component: Layout,
  redirect: '/commonPage/index',
  name: '公共页面',
  meta: {
    title: '公共页面',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/commonPage/index'),
      name: 'commonPage',
      meta: { title: '公共页面' }
    },
    {
      path: 'printOrder',
      component: () => import('@/views/commonPage/printOrder/printOrder'),
      name: 'printOrder',
      meta: { title: '订单打印' }
    },
    {
      path: 'developing',
      component: () => import('@/views/commonPage/developing'),
      name: 'developing',
      meta: { title: '功能未开放' }
    },
  ]
}
export default commonPageRouter
