<template>
  <el-dialog
    title="图片识别"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="image-recognition">
      <el-input
        type="textarea"
        :rows="10"
        placeholder="点击下方按钮开始识别"
        v-model="content"
      >
      </el-input>
      <el-upload
       v-show="!recognitioning"
        class="upload-box"
        action=""
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleChange"
      >
        <el-button size="small" type="primary">上传图片识别</el-button>
      </el-upload>
      <el-button v-show="recognitioning" type="text"><span class="el-icon-loading"></span>识别中</el-button>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close(false)">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { fileToBase64 } from '@/utils/imageUtil'
export default {
  data() {
    return {
      dialogVisible: true,
      content: "",
      recognitioning: false,
      file:'',
    };
  },
  created() {
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "ImageRecognition",
        data: this.content,
      });
    },
  },
  methods: {
    close(flag) {
      this.dialogVisible = false;
    },
    getResultContent(res){
        try {
            let content=[];
            res= JSON.parse(res);
            let lines = res.data.block[0].line;
            lines.forEach(line=>{
                let words = line.word;
                words.forEach(word=>{
                    content.push(word.content);
                });
            });
            return content.join('\r\n');
        
        } catch (err) {
            return '';
        }
        
    },
    handleChange(file){
      debugger;
        this.recognitioning = true;
        fileToBase64(file.raw).then(base64=>{
            base64 = base64.replace(/data:[a-zA-Z]+\/[a-zA-Z]+;base64,/,'');
            this.API.imageRecognition({
                PicBase:base64
            }).then(res=>{
                this.content+=this.getResultContent(res);
            }).catch(()=>{
                this.$message.error('识别失败');
            }).finally(()=>{
                this.recognitioning=false;
            });
        }).catch(()=>{
            this.recognitioning = false;
            this.$message.error('文件类型错误');
        })

    }
  },
};
</script>
<style lang="scss" scoped>
.image-recognition{
    display: flex;
    flex-direction: column;
    align-items: center;
    .upload-box{
        margin-top: 20px;
    }
}
</style>