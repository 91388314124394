import request from '@/utils/request'

export default {
  // 获取班级列表 /api/Classes/Getlist
  getProcessList(query) {
    return request({
      url: '/Process/List',
      method: 'get',
      params: query
    })
  },
   
}

