
import Layout from '@/views/layout'

const salesManagementRouter = {
  path: '/salesManagement',
  component: Layout,
  redirect: '/salesManagement/index',
  name: '销售管理',
  meta: {
    title: '销售管理',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/salesManagement/index'),
      name: 'salesManagementIndex',
      meta: { title: '销售管理' }
    },
	{
	  path: 'salesTeam',
	  component: () => import('@/views/salesManagement/salesTeam/index'),
	  name: 'salesTeam',
	  meta: { title: '销售团队' }
	},
  ]
}
export default salesManagementRouter
