import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout'

/* Router Modules */
import orderRouter from './modules/order'
import parentCenterRouter from './modules/parentCenter'
import majorFunctionRouter from './modules/majorFunction'
import myClientRouter from './modules/myClient'
import workCenterRouter from './modules/workCenter'
import educationCenterRouter from './modules/educationCenter'
import commonPageRouter from './modules/commonPage'
import InternalManagementRouter from './modules/InternalManagement'
import contactRecordRouter from './modules/contactRecord'
import salesManagementRouter from './modules/salesManagement'
import salesAssistantRouter from './modules/salesAssistant'
import messageAssistantRouter from './modules/messageAssistant'
import dictionaryRouter from './modules/dictionary'
import marketRouter from './modules/market'
import commonToolRouter from './modules/commonTool'
import mailListRouter from './modules/mailList'
import noticeMsgRouter from './modules/noticeMsg'
import checkInListRouter from './modules/checkInList'
import HMDRouter from './modules/HMD'
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
	roles: ['admin','editor']    control the page roles (you can set multiple roles)
	title: 'title'               the name show in sidebar and breadcrumb (recommend set)
	icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
	noCache: true                if set true, the page will no be cached(default is false)
	affix: true                  if set true, the tag will affix in the tags-view
	breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
	activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login/index.vue'),
		hidden: true
	},
	{
		path: '/404',
		component: () => import('@/views/error-page/404'),
		hidden: true
	},
	{
		path: '/401',
		component: () => import('@/views/error-page/401'),
		hidden: true
	},
	{
		path: '/demo',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/demo/index'),
			name: '项目示例',
			meta: {
				title: '项目示例',
			}
		}]
	},
	{
		path: '/',
		component: Layout,
		redirect: '/dashboard',
		children: [{
			path: '',
			component: () => import('@/views/dashboard/index'),
			name: 'Dashboard',
			meta: {
				title: '首页',
				icon: 'dashboard',
				affix: true
			}
		}]
	},
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
let moduleRoutes = [
	noticeMsgRouter,
	commonToolRouter,
	orderRouter,
	educationCenterRouter,
	parentCenterRouter,
	commonPageRouter,
	workCenterRouter,
	majorFunctionRouter,
	InternalManagementRouter,
	contactRecordRouter,
	salesManagementRouter,
	salesAssistantRouter,
	messageAssistantRouter,
	dictionaryRouter,
	marketRouter,
  mailListRouter,
  checkInListRouter,
  HMDRouter,
	/** when your routing map is too long, you can split it into small modules **/
	// 404 page must be placed at the end !!!
	{ path: '*', redirect: '/404', hidden: true },
	myClientRouter,
	/** when your routing map is too long, you can split it into small modules **/
	// 404 page must be placed at the end !!!
	{
		path: '*',
		redirect: '/404',
		hidden: true
	}
]

export const asyncRoutes = [
	...constantRoutes,
	...moduleRoutes,

	/** when your routing map is too long, you can split it into small modules **/
	// 404 page must be placed at the end !!!
	// { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
	// mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: asyncRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
router.beforeEach((to, from, next) => {
	console.log(to);
	next();
	// if (to.meta.requireAuth) {
	//     //判断该路由是否需要登录权限
	//     if (cookies('token')) {
	//         //通过封装好的cookies读取token，如果存在，name接下一步如果不存在，那跳转回登录页
	//         next()//不要在next里面加"path:/",会陷入死循环
	//     }
	//     else {
	//         next({
	//             path: '/login',
	//             query: {redirect: to.fullPath}//将跳转的路由path作为参数，登录成功后跳转到该路由
	//         })
	//     }
	// }
	// else {
	//     next()
	// }
})

export default router
