<template>
  <div
    class="outbound-calling"
    :class="{ 'outbound-calling-small': mode === 2 }"
    v-if="dialogVisible"
  >
    <div class="big-mode" v-show="mode === 1">
      <i
        class="full-screen iconfont icon-tuichuquanping cursor"
        title="隐藏到侧边栏"
        @click="changeMode"
      ></i>
      <div class="oc-user">
        <i style="background-color: #0077aa" class="el-icon-user-solid"></i>
        <div class="oc-user-name">
          <p>{{ userInfo.UserName }}</p>
          <p style="font-size:12px;color:#aaa;">{{ userInfo.City }}</p>
          <p>{{ userInfo.PhoneNumber }}</p>
        </div>
      </div>
      <div class="oc-content">
        <span class="start-taste-line" :class="{ active: recognitioning }">
          <hr class="hr hr1" />
          <hr class="hr hr2" />
          <hr class="hr hr3" />
          <hr class="hr hr4" />
          <hr class="hr hr5" />
          <hr class="hr hr6" />
          <hr class="hr hr7" />
          <hr class="hr hr8" />
          <hr class="hr hr9" />
          <hr class="hr hr10" />
        </span>
        <span>{{ timesFormat }}</span>
        <i
          class="el-icon-phone"
          style="color: #409eff"
          @click="changeStatus(false)"
          title="点击挂断"
        ></i>
      </div>
    </div>
    <div v-show="mode === 2" class="small-mode" @click="changeMode">
      <span
        ><i class="iconfont icon-dianhua" style="margin-right: 10px"></i
        >{{ timesFormat }}</span
      >
      <span style="font-size: 12px">通话中</span>
    </div>
  </div>
</template>
<script>
import outboundCallUtil from "@/utils/outboundCall";
export default {
  data() {
    return {
      userInfo: {},
      dialogVisible: true,
      recognitioning: false,
      times: 0,
      startTime: new Date(),
      endTime: "",
      timer: "",
      mode: 1,
    };
  },
  created() { 
    if (window._Dialog_OutboundCalling) {
      this.dialogVisible = false;
      return false;
    }
    window._Dialog_OutboundCalling = true;
    this.loginMoor();
    this.timer = setInterval(() => {
      if (this.recognitioning) {
        this.endTime = new Date();
      }
    }, 1000);
  },
  beforeDestroy() {
    window._Dialog_OutboundCalling = false;
    clearInterval(this.timer);
  },
  computed: {
    timesFormat() {
      if (!this.recognitioning) {
        return "正在拨号...";
      }
      let times = this.times + (this.endTime - this.startTime);
      times = times / 1000;
      return `${Math.floor(times / 60)
        .toString()
        .padStart(2, "0")}:${Math.floor(times % 60)
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "OutboundCalling",
        data: this.content,
      });
    },
  },
  methods: {
    close(flag) {
      this.dialogVisible = false;
      this.$SpeedRecognition.stop();
    },
    handleCall() {  
      let adminUser = JSON.parse(localStorage.getItem('user'));
      if (webPhone && softphoneBar) {
        let user = this.userInfo;
        outboundCallUtil
          .call(user.PhoneNumber, `id:${adminUser.id}_${user.id}`)
          .then((res) => {
            this.recognitioning = true;
          });
      } else {
        setTimeout(() => {
          this.handleCall();
        }, 1000);
      }
    },
    changeStatus(flag) {
      webPhone.destroy();
      this.recognitioning = flag;
      this.dialogVisible = false;
    },
    changeMode() {
      this.mode = this.mode === 1 ? 2 : 1;
    },
    loginMoor() {
      let _this = this; 
      if (moorCall && window.initM7WebPhone) {
        var loginName = "8001@cdads";
        var password = "e6!gKEFXW8001";
        var agentStatus = '0';
        var proxy_url = "https://pbx-bj-hwc30.7moor.com";
        var extenType = "sip";
        var pwd = md5(password);
        var loginData = {
          username: loginName,
          pwd: pwd,
        };
        moorCall.moortools.m7BeginLogon(
          loginName,
          password,
          extenType,
          agentStatus,
          proxy_url,
        );
        window.webPhone = new initM7WebPhone(loginData);
        this.handleCall();
        webPhone.networkMonitoring(function(msg){ 
        })
        window.webPhone.onError(function (error) { 
            //_this.$message.error(error);
        //   if (_this.dialogVisible) {
        //     _this.$message.error("连接失败");
        //   }
        });
         window.webPhone.onMessage(function(msg){
             //_this.$message.error(msg);
             if(msg==='hangup'){
                 //结束通话
             } 
         });
      } else {
        setTimeout(() => {
          this.loginMoor();
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[class^="el-icon-"] {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  width: 50px;
  height: 50px;
  color: #fff;
  line-height: 50px;
  background-color: tomato;
  border-radius: 25px;
}
[class^="el-icon-"]::before {
  color: #fff;
}
.outbound-calling {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  &.outbound-calling-small {
    right: 0;
    top: 100px;
    left: initial;
    bottom: initial;
    background-color: transparent;
  }
  .big-mode {
    // background-image: url(../../assets/images/kfbg.jpg);
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 20px;
    padding: 20px 30px;
    height: 300px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
    .oc-user {
      display: flex;
      i {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        line-height: 80px;
        font-size: 60px;
        text-align: center;
      }
      .oc-user-name {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 20px;
        }
      }
    }
    .oc-content {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .full-screen {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 24px;
    }
  }
  .small-mode {
    width: 100px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    border-radius: 20px 0 0 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
<style lang="css" scoped>
.total-time {
  margin-left: 20px;
}

.start-taste-line {
  display: inline-block;
  margin-right: 20px;
}
.start-taste-line hr {
  background-color: #187cff;
  width: 3px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;
  border: none;
}
.start-taste-line.active hr {
  animation: note 0.2s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.start-taste-line.active .hr1 {
  animation-delay: -1s;
}

.start-taste-line.active .hr2 {
  animation-delay: -0.9s;
}

.start-taste-line.active .hr3 {
  animation-delay: -0.8s;
}

.start-taste-line.active .hr4 {
  animation-delay: -0.7s;
}

.start-taste-line.active .hr5 {
  animation-delay: -0.6s;
}

.start-taste-line.active .hr6 {
  animation-delay: -0.5s;
}

.start-taste-line.active .hr7 {
  animation-delay: -0.4s;
}

.start-taste-line.active .hr8 {
  animation-delay: -0.3s;
}

.start-taste-line.active .hr9 {
  animation-delay: -0.2s;
}

.start-taste-line.active .hr10 {
  animation-delay: -0.1s;
}

@keyframes note {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(4);
  }
}
</style>