<template>
  <el-dialog
    :title="studentEdit.title"
    :visible.sync="dialogVisible"
    width="60%"
  >
    <div>
      <border-title titleName="基本信息" />
      <el-form
        :model="stuForm"
        :rules="rules"
        ref="stuForm"
        label-width="100px"
      >
        <div>
          <div class="flex aligin-items-center">
            <div class="flex1 padding form-left">
              <div class="avatar-item">
                <el-form-item label="头像"
                  ><el-upload
                    class="upload-demo"
                    :action="uploadActionUrl"
                    :on-success="uploadSuccess"
                    :before-upload="beforeAvatarUpload"
                    :headers="{
                      Authorization:
                        '',
                    }"
                    :data="{ source: 'user_head' }"
                    :show-file-list="false"
                    list-type="picture"
                    multiple
                  >
                    <img
                      v-if="stuForm.avatar"
                      style="width: 100px"
                      class="el-upload-list__item-thumbnail avatar"
                      :src="stuForm.avatar | previewImage"
                      alt=""
                    />
                    <img
                      v-else
                      style="width: 100px"
                      class="el-upload-list__item-thumbnail avatar"
                      src="../../assets/images/portrait/default.png"
                      alt=""
                    />
                    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，   ../../../assets/images/xs/avatar1.jpg"  且不超过500kb</div> -->
                  </el-upload></el-form-item
                >
              </div>
              <el-form-item label="姓名" prop="FullName"
                ><el-input
                  v-model="stuForm.FullName"
                  autocomplete="off"
                ></el-input
              ></el-form-item>
              <!-- <el-form-item label="来源渠道:"
                ><el-input
                  v-model="stuForm.source"
                  autocomplete="off"
                ></el-input
              ></el-form-item> -->
              <el-form-item label="来源渠道">
                <el-select v-model="stuForm.source" placeholder="选择渠道">
                  <el-option
                    v-for="(item, index) in Channel"
                    :key="index"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex1 padding">
              <el-form-item :label="'联系人'" prop="phone">
                <div class="sel-input flex aligin-items-center">
                  <el-input
                    placeholder="请输入手机号"
                    v-model="stuForm.phone"
                    class="input-with-select"
                  > 
                    <el-select
                      v-model="stuForm.relation"
                      slot="prepend"
                      placeholder="选择"
                    >
                      <el-option
                        v-for="(item, index) in ParentList"
                        :key="index"
                        :label="item.Name"
                        :value="item.Key"
                      ></el-option>
                    </el-select>
                  </el-input>
                  <!-- <i class="el-icon-circle-plus text-blue margin-left-sm"></i> -->
                </div>
              </el-form-item>
              <el-form-item label="性别" prop="resource">
                <el-radio v-model="stuForm.sex" :label="0">女</el-radio>
                <el-radio v-model="stuForm.sex" :label="1">男</el-radio>
              </el-form-item>
              <el-form-item label="介绍人">
                <el-input
                  @focus="introducer"
                  v-model="introducer_name"
                  placeholder="选择介绍人"
                ></el-input>
              </el-form-item>
              <el-form-item label="学管师">
                <!-- <el-select v-model="stuForm.teacher" placeholder="请选择学管师">
                  <el-option label="学管师一" value="1"></el-option>
                  <el-option label="学管师二" value="2"></el-option>
                </el-select> -->
                <as-autocompelete
                  v-model="studentName"
                  :fetch-suggestions="querySearch"
                  :isInfinite="true"
                  keyName="name"
                  style="width: 243.5px"
                  @selectChange="selectChange"
                  @loadMore="loadMoreStudent"
                  placeholder="选择学管师"
                >
                  <!-- <div slot="title">这里是示例111标题</div> -->
                  <template slot="item" slot-scope="row">
                    <div
                      highlight-current-row
                      class="cursor Select_"
                      style="display: flex; width: 100%"
                    >
                      <div style="width: 50%">{{ row.item.full_name }}</div>
                      <div
                        style="font-size: 12px; width: 50%"
                        v-if="row.item.phone"
                      >
                        ({{ row.item.phone }})
                      </div>
                    </div>
                  </template>
                </as-autocompelete>
              </el-form-item>
			  
			  
			  <el-form-item label="学员类型" prop="resource">
			    <el-radio v-for="item in Studentype" v-model="stuForm.type" :label="item.Key">{{item.Name}}</el-radio>
			 
			  </el-form-item>
			  
            </div>
          </div>
          <div
            v-show="!isMore"
            class="more-info flex aligin-items-center justify-content-center text-blue padding-xs bg-z"
            @click="isMore = true"
          >
            <span>填写更多学员信息</span>
            <i class="el-icon-caret-bottom margin-left-sm"></i>
          </div>

          <div class="" v-show="isMore">
            <div
              class="flex aligin-items-center width-100 justify-content-between"
            >
              <border-title titleName="更多信息" />
              <el-divider />
              <div class="text-blue less-info" @click="isMore = false">
                <span>收起</span>
                <i class="el-icon-caret-top margin-left-sm"></i>
              </div>
            </div>

            <div class="flex">
              <div class="flex1 padding">
                <!-- <el-form-item label="磁卡卡号:"
                  ><el-input
                    v-model="stuForm.name"
                    autocomplete="off"
                  ></el-input
                ></el-form-item> -->
                <el-form-item label="生日">
                  <el-col :span="11"
                    ><el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="stuForm.birthday"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                    ></el-date-picker
                  ></el-col>
                </el-form-item>
                <el-form-item label="民族">
                  <el-select v-model="stuForm.nation" placeholder="选择民族">
                    <el-option
                      v-for="(item, index) in Nation"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="年级">
                  <el-select v-model="stuForm.grade" placeholder="选择年级">
                    <el-option
                      v-for="(item, index) in gradeList"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="高中学校">
                  <el-input v-model="stuForm.high_school"></el-input>
                </el-form-item>
                <el-form-item label="小学学校">
                  <el-input v-model="stuForm.primary_school"></el-input>
                </el-form-item>
                <el-form-item label="行业">
                  <el-select v-model="stuForm.industry" placeholder="选择行业">
                    <el-option
                      v-for="(item, index) in IndustryList"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="微信号"
                  ><el-input
                    v-model="stuForm.wechat_no"
                    autocomplete="off"
                  ></el-input
                ></el-form-item>
              </div>

              <div class="flex1 padding">
                <el-form-item label="身份证号"
                  ><el-input
                    v-model="stuForm.id_card"
                    autocomplete="off"
                    maxlength="18"
                  ></el-input
                ></el-form-item>
                <!-- <el-form-item label="公立学校:"
                  ><el-input
                    v-model="stuForm.public_schools"
                    autocomplete="off"
                  ></el-input
                ></el-form-item> -->
                <el-form-item label="公立学校">
                  <el-select
                    v-model="stuForm.public_schools"
                    placeholder="选择公立学校"
                  >
                    <el-option
                      v-for="(item, index) in PublicSchools"
                      :key="index"
                      :label="item.title"
                      :value="item.title"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="公立校班级"
                  ><el-input
                    v-model="stuForm.public_grade"
                    autocomplete="off"
                  ></el-input
                ></el-form-item>
                <el-form-item label="初中学校">
                  <el-input v-model="stuForm.junior_high_school"></el-input>
                </el-form-item>
                <el-form-item label="幼儿园"
                  ><el-input
                    v-model="stuForm.kindergarten"
                    autocomplete="off"
                  ></el-input
                ></el-form-item>
                <el-form-item label="单位名称"
                  ><el-input
                    v-model="stuForm.company_name"
                    autocomplete="off"
                  ></el-input
                ></el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        v-if="!studentEdit.Edit"
        @click="submitForm('stuForm')"
        >确 定</el-button
      >
      <el-button
        type="primary"
        v-if="studentEdit.Edit"
        @click="submitForm('stuForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import BorderTitle from "@/components/border-title/index.vue";

export default {
  components: {
    BorderTitle,
  },
  data() {
    return {
      introducer_name: "", //介绍人
      PublicSchools: [], //公立学校
      Channel: [], //来源渠道
      Nation: [], //民族
      gradeList: [], //年级
      IndustryList: [], //行业
      StudentInformation: [], //学员信息
	  Studentype:[],//学员类型
	  StudentType:'StudentType',//传学员类型
	 
      studentEdit: {
        id: "",
        title: "",
        Edit: false,
      },
      getIntroducer_PageIndex: 1,
      getIntroducer_PageSize: 10,
      school_adminList: [],
      studentName: "",
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
      },
      ParentList: [], //联系人关系
      avatar: "",
      dialogVisible: true,
      stuForm: {
        id: "",
        birthday: "", //生日
        source: "", //来源渠道
        FullName: "", //学员姓名
        sex: 0, //
        phone: "",
        relation: 2, //关系
        school_admin: "", //学管员
        wechat_no: "", //微信号
        introducer: "", //介绍人
        id_card: "", //身份证
        nation: "", //民族
        public_schools: "", //公立学校
        grade: "", //年级
        public_grade: "", //公立学校班级
        high_school: "", //高中学校
        junior_high_school: "", //初中学校
        primary_school: "", //小学学校
        kindergarten: "", //幼儿园
        industry: "", //行业
        company_name: "", //单位名称
        avatar: "", //头像
		type:0,//学员类型
      },
      rules: {
        phone: [
          // 添加正则表达式 pattern: /^1[3|5|7|8|9]\d{9}$/，验证手机号是否正确
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "change",
          },
        ],
        FullName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        // resource: [
        //   { required: true, message: "请选择活动资源", trigger: "change" },
        // ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      isMore: false,
    };
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "CreatStudents",
        data: this.stuForm,
      });
    },
  },
  created() {
    this.initSelectData();
    this.getIntroducer();
    this.getstudent();
	this.getsudenttypes();
  },

  methods: {
	  //获取学员类型
	  getsudenttypes(){
			  
		  this.API.GetListStudenttypes(this.StudentType).then((res)=>{
			  console.log(res);
			  this.Studentype=res.filter(function(t){
				  return t.Name!="全部";
			  })
		  })
	  },
    getstudent() {
      if (this.studentEdit.Edit) {
        this.API.StudentDetail(this.studentEdit.id).then((res) => {
          if (res.success) {
            this.stuForm = res.data;
            this.studentName = this.stuForm.school_admin_name;
            // introducer_name
            this.introducer_name = this.stuForm.introducer_name;
            console.log(this.stuForm);
          }
        });
      }
    },
    //点击某一项的事件
    selectChange(item) {
      this.studentName = item.full_name;
      this.stuForm.school_admin = item.id;
      // console.log(this.studentName, item);
    },
    //搜索事件
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        // for (let index = 0; index < 10; index++) {
        //   list.push(list[0]);
        // }
        // cb(list);
        cb(res.data.rows || []);
      });
    },
    loadMoreStudent(queryString, cb) {
      this.getIntroducer_PageIndex++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        // for (let index = 0; index < 10; index++) {
        //   list.push(list[0]);
        //    list.push({ full_name: "张三" });
        // }
        cb(list);
        //cb(res.data.rows||[]);
      });
    },
    initSelectData() {
      this.getParent();
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Nation)
        .then((res) => {
          this.Nation = res.data.rows;
          // this.Nation = this.Nation.filter((x) => {
          //   return x.parent_id != 0;
          // });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Grade)
        .then((res) => {
          this.gradeList = res.data.rows;
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Industry)
        .then((res) => {
          this.IndustryList = res.data.rows;
          this.IndustryList = this.IndustryList.filter((x) => {
            return x.parent_id != 0;
          });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.Channel)
        .then((res) => {
          this.Channel = res.data.rows;
          this.Channel = this.Channel.filter((x) => {
            return x.parent_id != 0;
          });
        });
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.PublicSchools)
        .then((res) => {
          this.PublicSchools = res.data.rows;
          this.PublicSchools = this.PublicSchools.filter((x) => {
            return x.parent_id != 0;
          });
        });
    },
    introducer() {
      this.$Dialog.introducer().then((res) => {
        this.introducer_name = res.FullName || this.introducer_name;
        this.stuForm.introducer = res.id || this.stuForm.introducer;
      });
    },
    getStudentList() {
      var model = {
        student_status: [0],
        school_id: [0],
        wechat_no: "",
        school_admin: [0],
        sex: 0,
        is_arrears: true,
        has_oldstudent: true,
        has_balance: true,
        outer_school_name: "",
        outer_class: "",
        phone: "",
        business_type: 0,
        username: "",
        KeyWord: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
        Sort: [
          {
            Field: "string",
            Type: 0,
          },
        ],
      };
      this.API.StudentList(model).then((res) => {});
    },
    getParent() {
      this.API.SystemGetSettings("SysRelationshipEnum", false).then((res) => {
        console.log(res);
        this.ParentList = res;
        this.$nextTick(()=>{
          this.$forceUpdate();
        });
      });
    },
    getIntroducer(keyword) {
      var model = {
        // AccountType: [5],
        post_tag:['学管'],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    //头像上传 accountQuery
    beforeAvatarUpload(file) { 
      console.log(file)
      const isJPG =
        file.type.toLowerCase() === "image/jpeg" ||
        file.type.toLowerCase() === "image/png" ||
        file.type.toLowerCase() === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG,JPEG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    uploadSuccess(res) {
      this.avatar = res.data.Uri;
      this.stuForm.avatar = res.data.src;
    },

    submitForm(stuForm) {
      this.$refs[stuForm].validate((valid) => {
        if (valid) {
          this.StudentCreate();
        } else {
          return false;
        }
      });
    },
    StudentCreate() {
      var regIdNo = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      if (this.stuForm.sex == "男") {
        this.stuForm.sex = 1;
      } else if (this.stuForm.sex == "女") {
        this.stuForm.sex = 0;
      }

      if (
        !regIdNo.test(this.stuForm.id_card) &&
        this.stuForm.id_card &&
        this.stuForm.id_card.length
      ) {
        this.$message.error("请输入正确的身份证号码");
      } else {
        this.API.StudentCreate(this.stuForm).then((res) => {
          if (res.success) {
            this.dialogVisible = false;
            this.$message({
              message: res.message,
              type: "success",
            });
            this.selectChange();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .avatar {
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

>>> .form-left .el-form-item:first-child {
  display: flex;
  align-items: center;
}
>>> .avatar-item .el-form-item:first-child div {
  margin-left: 0 !important;
}
>>> .sel-input .el-select .el-input {
  width: 80px !important;
}
/* >>> .el-input-group {
  width: 243.5px !important;
} */
.Select_:hover {
  background-color: #efefef;
  color: #000;
}
.more-info {
  border: 1px dashed #0081ff;
  border-radius: 4px;
}
.more-info:hover {
  cursor: pointer;
}
.less-info:hover {
  cursor: pointer;
}
>>> .el-divider--horizontal {
  width: 80%;
}
>>> .el-divider {
  background-color: #6db1fc;
}
>>> .el-date-editor.el-input {
  width: 243.5px !important;
}
>>> .el-select .el-input {
  width: 243.5px !important;
}
.el-input {
  width: 243.5px !important;
}
</style>
