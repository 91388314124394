export default {
    preventReClick: {
        // 防止重复点击
        inserted(el, binding) {
            el.addEventListener('click', () => {
                if (!el.disabled) {
                    el.disabled = true
                    setTimeout(() => {
                        el.disabled = false
                    }, binding.value || 1000)
                }
            })
        }
    }
}