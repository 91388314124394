import request from '@/utils/request'

export default {
	//获取角色列表
	roleQuery(data) {
        return request({
            url: '/Role/Query',
            method: 'post',
            data
        })
    },
	//编辑角色
   roleSaveRole(data) {
       return request({
           url: '/Role/SaveRole',
           method: 'post',
           data
       })
   },
}