import Layout from '@/views/layout'

const educationCenterRouter = {
	path: '/educationCenter',
	component: Layout,
	redirect: '/Trainee/index',
	name: '教务中心',
	meta: {
		title: '教务中心',
		icon: 'table'
	},
	children: [{
		path: 'Trainee',
		component: () => import('@/views/educationCenter/Trainee/index'),
		name: 'Trainee',
		meta: {
			title: '学员'
		}
	},{
		path: 'Class',
		component: () => import('@/views/educationCenter/Class/index'),
		name: 'Class',
		meta: {
			title: '班级'
		}
	},{
		path: 'enterResults',
		component: () => import('@/views/educationCenter/Trainee/enterResults'),
		name: 'enterResults',
		meta: {
			title: '录入成绩'
		}
	},{
		path: 'studentInformation',
		component: () => import('@/views/educationCenter/Trainee/studentInformation'),
		name: 'studentInformation',
		meta: {
			title: '学员信息',
			keepAlive: false
		}
	},{
		path: 'newClass',
		component: () => import('@/views/educationCenter/Class/newClass'),
		name: 'newClass',
		meta: {
			title: '新建班级'
		}
	},{
		path: 'editClass',
		component: () => import('@/views/educationCenter/Class/newClass'),
		name: 'editClass',
		meta: {
			title: '编辑班级'
		}
	},{
		path: 'oneToOne',
		component: () => import('@/views/educationCenter/oneToOne/index'),
		name: 'oneToOne',
		meta: {
			title: '一对一'
		}
	},{
		path: 'course',
		component: () => import('@/views/educationCenter/course/index'),
		name: 'course',
		meta: {
			title: '课程'
		}
	},{
		path: 'newCourse',
		component: () => import('@/views/educationCenter/course/newCourse'),
		name: 'newCourse',
		meta: {
			title: '新建课程'
		}
	},{
		path: 'editCourse',
		component: () => import('@/views/educationCenter/course/newCourse'),
		name: 'editCourse',
		meta: {
			title: '编辑课程'
		}
	},{
		path: 'newUpgrade',
		component: () => import('@/views/educationCenter/course/newUpgrade'),
		name: 'newUpgrade',
		meta: {
			title: '新建升期'
		}
	},{
		path: 'remembertoClass',
		component: () => import('@/views/educationCenter/remembertoClass/index'),
		name: 'remembertoClass',
		meta: {
			title: '记上课'
		}
	},{
		path: 'addRemembertoClass',
		component: () => import('@/views/educationCenter/remembertoClass/addRemembertoClass'),
		name: 'addRemembertoClass',
		meta: {
			title: '记上课'
		}
	},{
		path: 'schedule',
		component: () => import('@/views/educationCenter/schedule/index'),
		name: 'schedule',
		meta: {
			title: '排课'
		}
	},{
		path: 'newSchedule',
		component: () => import('@/views/educationCenter/schedule/newSchedule'),
		name: 'newSchedule',
		meta: {
			title: '新建排课'
		}
	},{
		path: 'editSchedule',
		component: () => import('@/views/educationCenter/schedule/editSchedule'),
		name: 'editSchedule',
		meta: {
			title: '编辑排课'
		}
	}
	]
}
export default educationCenterRouter
