<template>
	<el-dialog title="创建计划" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="40%">
		<div style="height: 450px;">
			<div class="flex  justify-content-between">
				<div class="flex myClient-title-prompt">
					<svg class="icon" aria-hidden="true" style="padding: 5px 0 0 5px;">
						<use xlink:href="#icon-weixin1"></use>
					</svg>
					<div style="font-size: 16px;margin-left: 10px;">微信计划</div>
				</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm margin-top-lg">
				<el-form-item label="标题" prop="Title">
					<el-input v-model="ruleForm.Title" placeholder="请输入标题,最多32个字" maxlength="32"></el-input>
				</el-form-item>

				<el-form-item label="提醒时间" prop="ExecutionTime">
					<el-date-picker v-model="ruleForm.ExecutionTime " type="datetime" placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item>

				<el-form-item label="客户" prop="CustomerId">
					<div v-model="ruleForm.CustomerId">
						<div v-if="customersList.id" class="flex margin-left">
							<div class="margin-right-lg">
								<svg class="icon" style="width:25px;height: 25px;" aria-hidden="true">
									<use xlink:href="#icon-icon-test"></use>
								</svg>
								<span class="margin-left-xs">{{customersList.UserName}}</span>
							</div>
							<div class="flex myClientimg-text-title">
								<div class="add-img" @click="chooseContact" style="margin-left: 10px;"><i
										class="iconfont icon-add"></i></div>
								<span class="myClient-text myClient-left-10">重新选择客户</span>
							</div>
						</div>
						<div v-else>
							<div class="flex myClientimg-text-title">
								<div class="add-img" @click="chooseContact" style="margin: 0;"><i
										class="iconfont icon-add"></i></div>
								<span class="myClient-text myClient-left-10">选择客户</span>
							</div>
						</div>
					</div>
				</el-form-item>

				<el-form-item label="内容" prop="PlanContent">
					<el-input type="textarea" v-model="ruleForm.PlanContent " placeholder="请输入微信提示内容,最多1000个字"
						maxlength="1000" show-word-limit></el-input>

				</el-form-item>
			</el-form>

			<div class="myClient-position-absolute">
				<div class="flex justify-content-flexEnd">
					<!-- <div>
						<el-button @click="planTemplate">选择模板</el-button>
					</div> -->
					<el-button type="primary" @click="submitForm" v-preventReClick="500">保存</el-button>
					<el-button @click="close">取消</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {

		data() {
			return {
				dialogVisible: true,
				UserId: 0,
				ruleForm: {
					PlanType: 4,
					Title: '',
					CustomerId: 0,
					UserName: '',
					ExecutionTime: '',
					PlanContent: '',
				},
				customersList:{},
				rules: {
					Title: [{
						required: true,
						message: '请输入任务标题',
						trigger: 'blur'
					}],
					ExecutionTime: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					CustomerId: [{
						required: true,
						message: '请选择客户',
						trigger: 'blur'
					}],
					PlanContent: [{
						required: true,
						message: '请输入电话提示内容',
						trigger: 'blur'
					}],
				}
			};
		},
		created() {
			this.getViewCustomer();
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			chooseContact() {
				this.$Dialog.ChooseContact().then(res => {
					this.ruleForm.client = res
				})
			},
			getViewCustomer() {
				this.API.customerView_Customer(this.UserId).then(res => {
					this.ruleForm.CustomerId = res.id
					this.ruleForm.UserName = res.UserName
				})
			},
			submitForm() {
				if (JSON.stringify(this.customersList)!="") {
						this.ruleForm.CustomerId = this.customersList.id
						this.ruleForm.UserName = this.customersList.UserName
						this.PlanEditPla()
				} else {
					this.PlanEditPla()
				}
			},
			PlanEditPla() {
				if (!this.ruleForm.Title) {
					this.$message.error("请填写计划的标题")
				}
				if (!this.ruleForm.PlanContent) {
					this.$message.error("请填写计划的内容")
				}
				if (!this.ruleForm.ExecutionTime) {
					this.$message.error("请填写计划的执行时间")
				} else {
					this.ruleForm.ExecutionTime=formatTime(this.ruleForm.ExecutionTime,'yyyy-MM-dd HH:mm:ss ')
					this.API.CustomerPlanEditPla(this.ruleForm).then(res => {
						if (res.code == 200) {
							this.$message.success("添加计划成功")
							this.close()
						}
					})
				}
			},
			planTemplate() {
				this.$Dialog.PlanTemplate().then(res => {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 43px;
		height: 43px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
