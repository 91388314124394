import Layout from '@/views/layout'

const noticeMsgRouter = {
    path: '/noticeMsg',
    component: Layout,
    redirect: '/noticeMsg/message',// /commonTool/cloudDisk
    name: '通知公告',
    meta: {
        title: '通用功能',
        icon: 'table'
    },
    children: [{
        path: 'message',
        component: () => import('@/views/NotificationMessage/index'),
        name: '通知公告',
        meta: { title: '通知公告' }
    }]
}
export default noticeMsgRouter
