import Layout from '@/views/layout'

const myClientRouter = {
	path: '/HMD',
	component: Layout,
	redirect: '/HMD/index',
	name: '总部市场部',
	meta: {
		title: '市场部',
		icon: 'table'
	},
	children: [{
			path: 'index',
			component: () => import('@/views/HMD/index'),
			name: 'index',
			meta: {
				title: '我的客户'
			}
		},
		{
			path: 'createClient',
			component: () => import('@/views/HMD/components/createClient'),
			name: 'createclient',
			meta: {
				title: '创建客户'
			}
		}, {
			path: 'management',
			component: () => import('@/views/HMD/CustomerManagement'),
			name: 'management',
			meta: {
				title: '客户管理'
			}
		},{
			path: 'DepartmentalCustomers',
			component: () => import('@/views/HMD/DepartmentalCustomers'),
			name: 'DepartmentalCustomers',
			meta: {
				title: '部门客户'
			}
		},
	]
}
export default myClientRouter
