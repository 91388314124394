<template>
  <el-dialog
    title="选择班级/一对一"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
  >
    <div class="flex justify-content-between">
      <el-form
        ref="form"
        label-width="80px"
        class="flex justify-content-between"
      >
        <el-form-item label="授课模式">
          <el-select
            v-model="screenModel.curr_mode"
            placeholder="选择授课模式"
            size="small"
          >
            <el-option label="班课" :value="0"></el-option>
            <el-option label="全部" :value="1"></el-option>
            <el-option label="一对一" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="校区">
          <el-select
            v-model="screenModel.school_id"
            placeholder="请选择校区"
            size="small"
          >
            <el-option
              v-for="item in shool_List"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教师">
          <el-select
            v-model="screenModel.teacher_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in ArrangeTeachers_List"
              :key="item.value"
              :label="item.full_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            size="small"
            placeholder="请输入班级/一对一名称"
            suffix-icon="el-icon-search"
            v-model="screenModel.KeyWord"
            style="width: 230px"
          />
        </el-form-item>
      </el-form>
    </div>

    <div>
      <!-- attendClassForNotArrange -->
      <el-table
        :data="datalist"
        highlight-current-row
        @current-change="handleSelectionChange1"
      >
        <el-table-column
          property="class_name"
          label="班级/一对一"
          align="center"
        ></el-table-column>
        <el-table-column
          property="school_name"
          label="上课校区"
          align="center"
        ></el-table-column>
        <el-table-column 
          label="教师"
          align="center"
        > <template slot-scope="scope">
          <div v-if="scope.row.Tlistname">
             <span v-for="(item,index) in scope.row.Tlistname" :key="index" class="margin-left-sm">
              {{ item}}</span
            >
          </div> 
          </template>
        </el-table-column>
        <el-table-column 
          label="助教"
          align="center"
        ><template slot-scope="scope">
          <div v-if="scope.row.Alistname">
             <span v-for="(item,index) in scope.row.Alistname" :key="index" class="margin-left-sm">
              {{ item}}</span
            >
          </div> 
          </template>
        </el-table-column>
        <el-table-column property="starttime" label="开班日期" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.starttime" class="cursor">
              {{ scope.row.starttime.split("T")[0] }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          property="class_count"
          label="人数"
          align="center"
        >	<template slot-scope="scope">
          <div>

						<span  v-if="scope.row.class_count">{{ scope.row.class_count }}</span>
            <span>/</span>
            <span v-if="scope.row.class_num">{{scope.row.class_num}}</span>
          </div>
					</template>
        </el-table-column>
        <!-- 	<el-table-column label="授课模式" align="center">
					<template slot-scope="scope">
						<span class="class-tag">{{ scope.row.teachType }}</span>
					</template>
				</el-table-column> -->
        <el-table-column label="上课时段" align="center">
          <template slot-scope="scope">
            <span
              >{{ scope.row.class_begin_time_start }}-{{
                scope.row.class_begin_time_end
              }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="padding-top-lg">
        <el-pagination
          v-if="total"
          style="text-align: center"
          background
          @current-change="handleCurrentChange"
          :page-size="screenModel.PageSize"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="prev, pager, next,total,sizes"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close(true)">取 消</el-button>

      <!-- @click="close(true)" -->
      <el-button type="primary" @click="gopage()">记上课</el-button>
    </div>
  </el-dialog>
</template>
<script>
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
  },
  data() {
    return {
      shool_List: [],
      screenModel: {
        curr_mode: 1,
        teacher_id: [],
        school_id: [],
        KeyWord: "",
        PageIndex: 1,
        PageSize: 15,
      },
      ArrangeTeachers_List: [], //教师
      BatchAdjustmentList: [],
      // 校区数组
      schoolOptions: [
        {
          value: "1",
          schoolName: "班课",
        },
        {
          value: "2",
          schoolName: "一对一",
        },
      ],
      courseName: "",
      dialogVisible: true,
      datalist: [],
      multipleSelection: {},
      name: "",
      selectedCourses: [],
      total: "",
    };
  },
  created() {
    this.getArrangeCurriculum_List();
    this.gitShool();
  },
  watch: {
    screenModel: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.getArrangeCurriculum_List(); 
      },
      deep: true, //true 深度监听
    },
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "ChooseCoursesSingle",
        data: this.multipleSelection,
      });
    },
  },
  methods: {
    gopage() {
      var _this = this; 
      if (_this.multipleSelection.id) {
        // console.log(_this.$route);
            this.dialogVisible = false;
        _this.$router.push({
          path: "/educationCenter/addRemembertoClass",
          query: { classid: _this.multipleSelection.id},
          
        });
      } else {
        this.$message.error("请先点击一个信息在试试");
      }
    },
    handleCurrentChange(pageIndex) {
      this.screenModel.PageIndex = pageIndex;
      this.getArrangeCurriculum_List(); 
    },
    gitShool() {
      //查询校区
      // var model = { ParentId: -1, type: 1 };
      // this.API.OrganizationGetTree(model).then((res) => {
      //   this.shool_List = res;
      // });
        //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      };
      this.API.OrganizationList(model).then((res) => {
        this.shool_List = res.data.rows;
      });
      //查询教师
      var model_teachers = {
        post_tag:['教师'],
        KeyWord: "",
        PageSize: 20,
        PageIndex: 1,
      };
      this.API.accountQuery(model_teachers).then((res) => {
        this.ArrangeTeachers_List = res.data.rows;
      });
    },
    getArrangeCurriculum_List() {
      this.API.getClassList(this.screenModel).then((res) => {
        if (res.success) {
          this.total = res.data.total;
          this.datalist = res.data.rows;
        }
      });
    },

    close() {
      this.dialogVisible = false;
    },
    
    handleSelectionChange1(val) { 
      this.multipleSelection = val;
    },
  },
};
</script>

<style scoped>
>>> .el-table__body tr.current-row > td {
  background-color: #2fa4e7 !important;
  color: #fff;
}
.class-tag {
  border-radius: 4px;
  border: 1px solid #4a9efc;
  padding: 2px 4px;
  background-color: #dbebfe;
  color: #4a9efc;
}
</style>