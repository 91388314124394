import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import {
  getToken
} from '@/utils/auth'

let haslogin = location.href.includes('/login')
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method.toLowerCase() === 'post') {
      config.headers.post = {};
    }
    let token = getToken();
    if (!token && config.url.toLowerCase().indexOf('login') < 0) {
      //未登录
      store.dispatch('resetToken').then(() => {})
      return Promise.reject(error)
    }
    // do something before request is sent
    config.headers['Authorization'] = token;
    return config
  },
  error => {
    // do something with request error 
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.status === 403 && !haslogin) {
      //未登录
      MessageBox.confirm('未登陆或登陆已失效，是否重新登陆？', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('resetToken').then(() => {})
      })
      return;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code <= 0) { 
   if(res.code  !=-3)
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      console.log(res.message);

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === -999 && !haslogin) {
        // to re-login
        MessageBox.confirm('未登陆或登陆已失效，是否重新登陆？', {
          confirmButtonText: '重新登陆',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('resetToken').then(() => {})
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error && error.response && error.response.status === 403 && !haslogin) {
      //未登录
      MessageBox.confirm('未登陆或登陆已失效，是否重新登陆？', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('resetToken').then(() => {})
      })
    }
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
