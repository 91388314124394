const formatTime = (date,formatStr)=>{
	formatStr=formatStr || 'yyyy-MM-dd HH:mm:ss';
  if(!date){
    return '';
  }
  try{
    date=new Date(date);
    formatStr = formatStr.replace('yyyy',date.getFullYear());
    formatStr = formatStr.replace('MM',(date.getMonth()+1).toString().padStart(2,'0'));
    formatStr = formatStr.replace('dd',(date.getDate()).toString().padStart(2,'0'));
    formatStr = formatStr.replace('HH',(date.getHours()).toString().padStart(2,'0'));
    formatStr = formatStr.replace('mm',(date.getMinutes()).toString().padStart(2,'0'));
    formatStr = formatStr.replace('ss',(date.getSeconds()).toString().padStart(2,'0'));
    formatStr = formatStr.replace('M',(date.getMonth()+1));
    formatStr = formatStr.replace('d',date.getDate());
    formatStr = formatStr.replace('H',date.getHours());
    formatStr = formatStr.replace('m',date.getMinutes());
    formatStr = formatStr.replace('s',date.getSeconds());
    return formatStr;
  }catch(e){
    //TODO handle the exception
    return '';
  }
	
};

export {
	formatTime
}