<template>
	<el-dialog title="选择校区" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false">
		<el-table ref="singleTable" :data="school_list" highlight-current-row @current-change="handleCurrentChange"
			style="width: 100%">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column property="name" label="学校名称" ></el-table-column>
			<el-table-column property="path" label="地址" >
			</el-table-column>
		</el-table>
		<div class="flex justify-content-flexEnd margin-top-xl">
			<el-button @click="close">取消</el-button>
			<el-button type="primary" @click="submit">确定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				Customerids: '',
				dialogVisible: true,
				school_list: [],
				message: {},
			}
		},
		created() {
			this.getSchool()
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			//查询出所有的学校
			getSchool() {
				var modelschool = {
					type: 1,
					PageIndex: 1,
					PageSize: 10000,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				};
				this.API.OrganizationList(modelschool).then((res) => {
					this.school_list = res.data.rows;
					console.log(res.data.rows)
				});
			},
			handleCurrentChange(val) {
				this.message = val
			},
			submit() {
				if (this.message.id) {
					let listQuery = {
						customerid: String(this.Customerids),
						schoolid: String(this.message.id)
					}
					this.API.CustomerAlldistribution(listQuery).then(res=>{
						if(res.success){
							this.$message.success("分配成功")
							this.$bus.$emit('dialogClose', {
								type: 'ChooseSchool',
								data: this.message
							});
							this.close()
							
						}
					})
				}else{
					this.$message.error("请选择校区")
				}
			}
		}
	}
</script>

<style>
</style>
