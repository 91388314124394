import Vue from 'vue'
import Vuex from 'vuex'
import Router from '@/router'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
	state: {
		token: '',
		user: '',
		menus:[],
	},
	mutations: {
		TOKEN(state, token) {
			if(token){
				state.token = token
				localStorage.setItem('token', token);
			}else{
				state.token = '';
				localStorage.clear();
				Router.push({
					path:'/login'
				});
			}
		},
		USER_INFO(state, info) {
			state.user = info
			localStorage.setItem('user', JSON.stringify(info));
		},
		MEUNS(state, list){
			state.menus = list
			localStorage.setItem('menus', JSON.stringify(list));
		},
	},
	actions: {
		setToken({
			commit
		}, data) {
			commit('TOKEN', data)
		},
		setMenus({
			commit
		}, data) {
			commit('MEUNS', data)
		},
		resetToken({
			commit
		}, data) {
			commit('TOKEN', null)
		},
		setUserInfo({
			commit
		}, data) {
			commit('USER_INFO', data)
		},
		exitLogin({commit}){
			commit('TOKEN', null);
			commit('USER_INFO', null);
			localStorage.clear();
			Router.push({
				path:'/login'
			});
		},
	},
	getters: {
		getMenus(state){
			return state.menus;
		},
		getToken(state) {
			if (!state.token) {
				let token  = localStorage.getItem('token');
				state.token = token;
				if(!token){
					Router.push({
						path:'/login'
					});
				}
				return token;
			} else {
				return state.token
			}
		},
		getUserInfo(state) {
			if (state.user) {
				return state.user
			} else {
				let user = JSON.parse(localStorage.getItem('user'));
				if(!user){
					Router.push({
						path:'/login'
					});
				}
				state.user = user;
				return user;
			}
		}
	}
})

export default store
