import request from '@/utils/request'

export default {
  //添加、修改学员信息 POST /api/Student/Create 
  StudentCreate(data) {
    return request({
      url: '/Student/Create',
      method: 'post',
      data,
    })
  },

  //POST /api/Student/ChangeStatus 修改状态 
  StudentChangeStatus(data) {
    return request({
      url: '/Student/ChangeStatus',
      method: 'post',
      data,
    })
  },
  //POST /api/Student/List 学员列表
  StudentList(data) {
    return request({
      url: '/Student/List',
      method: 'post',
      data,
    })
  }, //	POST /api/Student/GetListForOneToOne 学员列表一对一
  GetListForOneToOne(data) {
    return request({
      url: '/Student/GetListForOneToOne',
      method: 'post',
      data,
    })
  },
  //	GET /api/System/GetSettings 获取学员类型枚举
  GetListStudenttypes(StudentType) {
    return request({
      url: '/System/GetSettings?type='+StudentType,
      method: 'get',

    })
  },

  //POST /api/Student/import 批量导入
  StudentImport(data) {
    return request({
      url: '/Student/import',
      method: 'post',
      data,
    })
  },
  //GET /Student/GetClassesStudents
  GetClassesStudents(data) {
    return request({
      url: '/Student/GetClassesStudents',
      method: 'post',
      data,
    })
  },
  //GET /api/Student/Detail/{id} 获取单个学员信息
  StudentDetail(id) {
    return request({
      url: 'Student/Detail?id=' + id,
      method: 'get',

    })
  },
  //POST /api/Student/UpdateStudentType/ 修改学员类型为0正式学员
  ChangeTypes(id,type) {
    return request({
      url: '/Student/UpdateStudentType/'+id+'?type='+type,
      method: 'post',
      
    })
  },
  //POST /api/Student/ChangeStatus 修改学员状态-99为删除
  ChangeStatus(ids) {
    return request({
      url: '/Student/ChangeStatus?ids=' + ids + '&status=-99',
      method: 'post',
      params: {
        ids
      }
    })
  },
  // 获取学生余额  /api/Student/GetBalance
  GetBalance(id) {
    return request({
      url: '/Student/GetBalance?student_id=' + id,
      method: 'get',
    })
  },//POST /api/Student/Division 学员分班
  StudentDivision(data) {
    return request({
      url: '/Student/Division',
      method: 'post',
     data
    })
  }
}


