import dialog from '../utils/dialog'

export default {
    intelligentRecognition: {
        // 初始化指令
        bind(el, binding, vnode) {
        },
        inserted(el, binding, vnode){
            let params=binding.value;
            var box = document.createElement('div');
            if(params && params.style){
                for (const key in params.style) {
                    if (Object.hasOwnProperty.call(params.style, key)) {
                        box.style[key] = params.style[key];
                    }
                }
            }
            box.className = 'intelligent-recognition-box';

            //语音识别按钮
            var btn = document.createElement('span');
            btn.innerHTML = '语音识别';
            btn.title='点击语音识别输入';
            btn.className = 'ir-btn';
            btn.addEventListener('click',()=>{
                dialog.SpeechRecognition().then(content=>{
                    el.children[0].value = el.children[0].value + content;
                });
            });
            box.appendChild(btn);
            

            //图片识别按钮
            var btn1 = document.createElement('span');
            btn1.innerHTML = '图片识别';
            btn1.title='点击上传图片识别';
            btn1.className = 'ir-btn';
            box.appendChild(btn1);
            btn1.addEventListener('click',()=>{
                dialog.ImageRecognition().then(content=>{
                    el.children[0].value = el.children[0].value + content;
                });
            });
            el.appendChild(box);
        },
        update() { },
        unbind(el, binding) {
        }
    }
}