import request from '@/utils/request'

export default {
	SystemGetSettings(type,hasAll) {
		return request({
			url: '/System/GetSettings?type='+type+'&hasAll='+hasAll || false,
			method: 'get',
		})
	},
}
