//申请状态
const applicationStatus=[
    {
     label: '进行中',
     value: 1
    },{
     label: '待处理',
     value: 2
    },{
     label: '处理中',
     value: 3
    },{
     label: '已完成',
     value: 4
    }
 ];
 export default applicationStatus;