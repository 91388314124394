<template>
  <div class="as-autocomplete">
    <input
      type="text"
      class="el-input__inner"
      v-model="keyword"
      :placeholder="placeholder"
    />
    <div class="as-autocomplete-box">
      <div class="as-autocomplete-title">
        <slot name="title"> </slot>
      </div>
      <div
        v-if="isInfinite"
        class="as-autocomplete-list"
        style="height: 250px;width:200px"
        v-infinite-scroll="loadMore">
        <div v-for="item in list" @click="selectChange(item)">
          <slot name="item" :item="item"></slot>
        </div>
      </div>
      <div v-else class="as-autocomplete-list">
        <div v-for="item in list" @click="selectChange(item)">
          <slot name="item" :item="item"></slot>
        </div>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //是否无限滚动加载
    isInfinite: {
      type: Boolean,
      default: false,
    },
    initDataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: String,
      default: ''
    },
    keyName: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: ''
    },
    FetchSuggestions: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      keyword: '',
      interval: ''
    };
  },
  watch: {
    value(){
      this.keyword=this.value
    },
    keyword(val) {
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        this.loadList();
      }, 500);
    },
    initDataList(val) {
      this.list = this.initDataList;
    },
  },
  created() {
    this.loadList();
  },
  methods: {
    selectChange(item) { 
      this.keyword = item[this.keyName];
      this.$emit("selectChange", item);
    },
    loadMore() {
      this.$emit("loadMore", this.keyword, (list) => {
        this.list = this.list.concat(list);
      });
    },
    loadList() {
      this.FetchSuggestions(this.keyword, list => {
        this.list = list;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.as-autocomplete {
  display: inline-block;
  .as-autocomplete-title {
    width: 100%;
  }
  input:focus + .as-autocomplete-box {
    display: block;
  }
  .as-autocomplete-box:hover {
    display: block;
  }
  .as-autocomplete-box {
    z-index: 99999;
    position: absolute;
    top: 100%;
    display: none;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .as-autocomplete-list {
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
