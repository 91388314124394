
import Layout from '@/views/layout'

const workCenterRouter = {
  path: '/workCenter',
  component: Layout,
  redirect: '/workCenter/index',
  name: '办理中心',
  meta: {
    title: '办理中心',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/workCenter/index'),
      name: 'workCenter',
      meta: { title: '办理中心' }
    },
    {
      path: 'signUp',
      component: () => import('@/views/workCenter/child/signUp'),
      name: 'signUp',
      meta: { title: '报名缴费' }
    },
    {
      path: 'recharge',
      component: () => import('@/views/workCenter/child/recharge'),
      name: 'recharge',
      meta: { title: '余额充值' }
    },
    {
      path: 'backExpense',
      component: () => import('@/views/workCenter/child/backExpense '),
      name: 'backExpense',
      meta: { title: '补费' }
    },
    {
      path: 'transferCourses',
      component: () => import('@/views/workCenter/child/transferCourses'),
      name: 'transferCourses',
      meta: { title: '转课' }
    },
    {
      path: 'refund',
      component: () => import('@/views/workCenter/child/refund'),
      name: 'refund',
      meta: { title: '退费' }
    },
    {
      path: 'teachBookFee',
      component: () => import('@/views/workCenter/child/teachBookFee'),
      name: 'teachBookFee',
      meta: { title: '教材杂费' }
    }, {
      path: 'templist',
      component: () => import('@/views/oa/tasktemp'),
      name: 'templist',
      meta: { title: '流程模板' }
    },
  ]
}
export default workCenterRouter
