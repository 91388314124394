import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/styles/index.scss' // global css
import '@/styles/iconfont/iconfont.css'
import '@/styles/iconfont/iconfont.js'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/components/element-ui.scss';
import VueParticles from "vue-particles";
import App from './App.vue'
import store from './store'
import router from './router'
import Bus from '@/utils/bus'
import API from './api/index'
import Dialog from '@/utils/dialog'
import Dictionary from '@/utils/dictionary'
import commonMixin from './mixins/common'
import EVueContextmenu from 'e-vue-contextmenu'

import AsAutocompelete from './components/as-autocomplete'
import BorderTitle from './components/border-title';
import AsSwitch from './components/as-switch';
import AsList from './components/as-list';

Vue.config.productionTip = false
Vue.prototype.API = API
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$bus = Bus;
Vue.prototype.$SpeedRecognition = $SpeedRecognition;
Vue.prototype.$Dictionary = Dictionary;
Vue.mixin(commonMixin)
// Vue.use(Bus)
Vue.use(ElementUI)
Vue.use(VueParticles);
Vue.use(EVueContextmenu)
Vue.component('AsAutocompelete', AsAutocompelete)
Vue.component('BorderTitle', BorderTitle)
Vue.component('AsSwitch', AsSwitch)
Vue.component('AsList', AsList)


//自定义全局指令
Vue.use((Vue) => {
  ((requireContext) => {
    const arr = requireContext.keys().map(requireContext);
    (arr || []).forEach((directive) => {
      directive = directive.__esModule && directive.default ? directive.default : directive;
      Object.keys(directive).forEach((key) => {
        Vue.directive(key, directive[key]);
      });
    });
  })(require.context('./directives', false, /^\.\/.*\.js$/));
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
