<template>
  <div
    class="as-switch"
    :class="{ 'as-switch-active': currentVal === activeValue }"
  >
    <div class="as-switch-box" :style="switchBoxStyle" @click="handleClick">
      <div class="as-switch-btn" :style="btnSyle"></div>
      <span class="as-switch-txt" :style="txtStyle">{{ currentVal === activeValue ? activeText : inactiveText }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean | Number | String,
      default: () => {
        return false;
      },
    },
    width:{
        type:Number,
        default:60
    },
    height:{
        type:Number,
        default:20
    },
    activeText: {
      type: String,
      default: "",
    },
    inactiveText: {
      type: String,
      default: "",
    },
    activeValue: {
      type: Boolean | Number | String,
      default: () => {
        return true;
      },
    },
    inactiveValue: {
      type: Boolean | Number | String,
      default: () => {
        return false;
      },
    },
    activeColor: {
      type: String,
      default: "#409EFF",
    },
    inactiveColor: {
      type: String,
      default: "#C0CCDA",
    },
    btnColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data() {
    return {
      currentVal: "",
    };
  },
  created() {
    this.currentVal = this.value;
  },
  computed: {
    switchBoxStyle() {
		let styleObj={
			height:this.height+'px',
			borderRadius:this.height/2+'px',
			minWidth:this.height*2+'px'
		};
      if (this.currentVal === this.activeValue) {
        styleObj.backgroundColor=this.activeColor;
      } else {
		  styleObj.backgroundColor=this.inactiveColor;
      }
	  return styleObj;
    },
    heightStyle(){
        return {
            height:this.height+'px',
			lineHeight:this.height+'px'
        }
    },
	btnSyle(){
		return{
			height:this.height+'px',
			width:this.height+'px',
			borderRadius:this.height/2+'px',
			backgroundColor:this.btnColor
		}
	},
	txtStyle(){
		return {
		    height:this.height+'px',
			lineHeight:this.height+'px'
		}
	},
  },
  methods: {
    handleClick() {
      this.currentVal =
        this.currentVal === this.activeValue
          ? this.inactiveValue
          : this.activeValue;
      this.$emit("change", this.currentVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.as-switch {
  display: inline-block;
  transition: all 200ms;
  &.as-switch-active {
    flex-direction: row-reverse;
    .as-switch-box {
      flex-direction: row-reverse;
    }
  }
  .as-switch-box {
    display: flex;
    align-items: center;
    min-width: 40px;
    cursor: pointer;
    .as-switch-txt {
      min-width: 20px;
      padding: 0 5px;
      color: #fff;
      font-size: 12px;
    }
  }

  .as-switch-btn {
    background-color: #fff;
    border-radius: 10px;
  }
}
</style>