
import Layout from '@/views/layout'

const InternalManagementRouter = {
  path: '/InternalManagement',
  component: Layout,
  redirect: '/staff/index',
  name: '员工',
  meta: {
    title: '员工',
    icon: 'table'
  },
  children: [
    {
      path: 'staff',
      component: () => import('@/views/InternalManagement/staff/index'),
      name: 'staff',
      meta: { title: '员工' }
    }, {
      path: 'newEmployee',
      component: () => import('@/views/InternalManagement/staff/newEmployee'),
      name: 'newEmployee',
      meta: { title: '新建员工' }
    }, {
      path: 'staffNotice',
      component: () => import('@/views/InternalManagement/staffNotice/index'),
      name: 'staffNotice',
      meta: { title: '员工通知' }
    },{
      path: 'addNotice',
      component: () => import('@/views/InternalManagement/staffNotice/addNotice'),
      name: 'addNotice',
      meta: { title: '员工通知' }
    },{
      path: 'organizationalStructure',
      component: () => import('@/views/InternalManagement/organizationalStructure/index'),
      name: 'organizationalStructure',
      meta: { title: '组织架构' }
    },{
      path: 'wages',
      component: () => import('@/views/InternalManagement/wages/index'),
      name: 'wages',
      meta: { title: '工资' }
    },{
      path: 'Finance',
      component: () => import('@/views/InternalManagement/Finance/index'),
      name: 'Finance',
      meta: { title: '财务' }
    },{
      path: 'teachingMaterial',
      component: () => import('@/views/InternalManagement/teachingMaterial/index'),
      name: 'teachingMaterial',
      meta: { title: '教材' }
    },
  ]
}
export default InternalManagementRouter
