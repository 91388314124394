import request from '@/utils/request'

export default {
  // 获取课程列表 /api/Curriculum/List
  getCurriculumList(query) {
    return request({
      url: '/Curriculum/List',
      method: 'post',
      data: query
    })
  },
  // 课程添加/修改 /api/Curriculum/Createorupdate 
  addCurriculum(query) {
    return request({
      url: '/Curriculum/Createorupdate',
      method: 'post',
      data: query
    })
  },
  // 修改状态 /api/Curriculum/Changestatus
  updateCurriculumStatus(id, status) {
    return request({
      url: '/Curriculum/Changestatus?ids=' + id + '&status=' + status,
      method: 'post'
    })
  },
  // 批量导入课程 /api/Curriculum/import
  importCurriculum(query) {
    return request({
      url: '/Curriculum/import',
      method: 'post',
      data: query
    })
  },
  // 批量导出课程 /api/Curriculum/export
  exportCurriculum(query) {
    return request({
      url: '/Curriculum/export',
      method: 'post',
      data: query
    })
  },
  // 获取单个课程信息 /api/Curriculum/Detail/{id}
  getClassDetail(id) {
    return request({
      url: '/Curriculum/Detail/' + id,
      method: 'post'
    })
  },
}
