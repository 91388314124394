<template>
	<el-dialog title="选择客户标签" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="540px">
		<div style="height: 580px; overflow-y: auto;" v-loading="loading">
			<div class="margin-tb-sm" v-for="(item,index) of CustomerLabelist" :key="index">
				<div>
					<i class="iconfont icon-yuandianxiao text-orange"></i>
					<span>{{item.title}}</span>
				</div>

				<el-checkbox-group v-model="lable" size="mini">
					<el-checkbox-button class="margin-right-sm margin-tb-sm" v-for="(res,index1) in item.children"
						:label="res.title" :key="index1">{{res.title}}</el-checkbox-button>
				</el-checkbox-group>
			</div>

			<div class="flex justify-content-flexEnd">
				<el-button type="primary" @click="submit" v-preventReClick="500">确定</el-button>
				<el-button @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: true,
				loading: true,
				Userid: 0,
				num: 0,
				message: [],
				lable: [],
				Information: {
					id: 0,
					Label: '',
				},
				cities: ['上海', '北京', '广州', '深圳'],
				CustomerLabelist: [],
				listQuery: {
					module: 'CustomerLabel',
					parentId: 386,
				},
			}
		},
		created() {
			this.getCustomerLabelist()
			if (this.num == 2) {
				this.lable = this.message
			}

		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			//获取权限
			getCustomerLabelist() {
				this.getCustomerLabeTree(this.listQuery).then(res => {
					this.CustomerLabelist = res
					this.loading = false
				})
			},
			getCustomerLabeTree(data) {
				return new Promise((resolve, reject) => {
					this.$Dictionary.getDictionaryAllTreee(data).then(res => {
						resolve(res.data)
					});
				})
			},
			submit() {
				this.Information.Label = this.lable.toString()
				if (this.num == 2) {
					this.Information.id = this.Userid
					this.API.postEditCustomer(this.Information).then(res => {
						this.$message.success(res.message)
						this.close()
					})
				}

				this.$bus.$emit('dialogClose', {
					type: 'ChooseCustomerlabel',
					data: this.Information.Label
				});
				console.log(this.Information)
			},

		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.content {
		margin: 15px;
	}
</style>
