import request from '@/utils/request'

export default {
	//编辑客户
	postEditCustomer(data) {
		return request({
			url: '/Customer/Edit_Customer',
			method: 'post',
			data
		})
	},
	//获取客户列表
	selectCustomerList(data) {
		return request({
			url: '/Customer/Select_Customer',
			method: 'post',
			data
		})
	},
	//编辑分组
	customerEditGroup(data) {
		return request({
			url: '/Customer/Edit_Group',
			method: 'post',
			data
		})
	},
	//获取我的所有分组
	customerSelectMyGroup(data) {
		return request({
			url: '/Customer/Select_MyGroup',
			method: 'post',
			data
		})
	},
	//删除分组
	customerDeleteGroup(ids) {
		return request({
			url: '/Customer/Delete_Group?GroupId='+ids,
			method: 'post',
		})
	},
	//获取客户详情
	customerView_Customer(ids) {
		return request({
			url: '/Customer/View_Customer?CustomerUserId='+ids,
			method: 'get',
		})
	},
	//客户文件夹的编辑
	customerEditFolde(data) {
		return request({
			url: '/Customer/Edit_Folder',
			method: 'post',
			data
		})
	},
	//我的文件夹
	customerSelectMyFolder(ids) {
		return request({
			url: '/Customer/Select_MyFolder?CustomerId='+ids,
			method: 'get',
		})
	},
	//删除我的文件夹
	customerDeleteFolder(ids) {
		return request({
			url: '/Customer/Delete_Folder?FolderId='+ids,
			method: 'post',
		})
	},
	//添加文件到文件夹
	customerAddFileToFolder(data) {
		return request({
			url: '/Customer/Add_FileToFolder',
			method: 'post',
			data
		})
	},
	//查看文件夹下面的文件
	customerSelectMyFolderFile(query) {
		return request({
			url: '/Customer/Select_MyFolderFile',
			method: 'get',
			params: query,
		})
	},
	//删除文件
	customerDeleteFileToFolder(ids) {
		return request({
			url: '/Customer/Delete_FileToFolder?FileId='+ids,
			method: 'post',
		})
	},
	//查看客户的状态
	customerSelectCutomerDynamicList(data) {
		return request({
			url: '/Customer/SelectCutomerDynamicList',
			method: 'post',
			data
		})
	},
	//添加客户的跟进记录
	customerAddDynamicFollow(data) {
		return request({
			url: '/Customer/Add_DynamicFollow',
			method: 'post',
			data
		})
	},
	//查询分组下的用户列表
	customerSelectCustomerByGroup(data) {
		return request({
			url: '/Customer/Select_CustomerByGroup',
			method: 'post',
			data
		})
	},
	//添加客户到分组
	customerAddUserToGroup(data) {
		return request({
			url: '/Customer/Add_UserToGroup',
			method: 'post',
			data
		})
	},
	//移动客户到分组
	customerMoveUserToGroup(data) {
		return request({
			url: '/Customer/Move_UserToGroup',
			method: 'post',
			data
		})
	},
	//认领客户
	customerClaimCustomer(ids) {
		return request({
			url: '/Customer/Claim_Customer?CustomerUserId='+ids,
			method: 'post',
		})
	},
	//放弃我的客户
	customerGiveUpCustomer(query) {
		return request({
			url: '/Customer/GiveUp_Customer',
			method: 'post',
			params: query,
		})
	},
	//查看我添加的客户列表
	customerSelectMyCustomer(data) {
		return request({
			url: '/Customer/Select_MyCustomer',
			method: 'post',
			data
		})
	},
	//共享客户
	customerShareCustomer(query) {
		return request({
			url: '/Customer/Share_Customer',
			method: 'post',
			params: query,
		})
	},
	//查询该客户已经共享的人员
	customerSelectShareCustomer(query) {
		return request({
			url: '/Customer/Select_ShareCustomer',
			method: 'post',
			params: query,
		})
	},
	//总部市场部添加、编辑客户
	postEditCustomerForMarketing(data) {
		return request({
			url: '/Customer/Edit_CustomerForMarketing',
			method: 'post',
			data
		})
	},
	//总部市场部主管分配客户到校区
	CustomerAlldistribution(query) {
		return request({
			url: '/Customer/Alldistribution',
			method: 'post',
			params: query,
		})
	},
	//咨询主管分配客户
	CustomerConsultingdistribution(query) {
		return request({
			url: '/Customer/Consultingdistribution',
			method: 'post',
			params: query,
		})
	},
}
