<template>
	<el-dialog title="新建任务" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="680px">

		<div style="height: 487px;overflow-y: auto;" class="myClient-paddingright-10">

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="任务标题" prop="TaskTitle">
					<el-input v-model="ruleForm.TaskTitle " placeholder="请输入任务标题"></el-input>
				</el-form-item>

				<el-form-item label="联系方式" prop="ContactType">
					<el-radio-group v-model="ruleForm.ContactType" @change="ChangetabPosition">
						<el-radio-button :label="1" class="myClient-right-10"><i class="iconfont icon-dianhua1"
								:class="changeName==1?'myClientimg-color-f':'myClientimg-color-b'"></i><span
								class="myClient-left-5">电话</span>
						</el-radio-button>
						<el-radio-button :label="2" class="myClient-right-10"><i class="iconfont icon-duanxin"
								:class="changeName==2?'myClientimg-color-f':'myClientimg-color-b'"></i><span
								class="myClient-left-5">短信</span>
						</el-radio-button>
						<el-radio-button :label="3" class="myClient-right-10"><i class="iconfont icon-weixin"
								:class="changeName==3?'myClientimg-color-f':'myClientimg-color-g'"></i><span
								class="myClient-left-5">微信</span>
						</el-radio-button>
						<el-radio-button :label="4" class="myClient-right-10"><i class="iconfont icon-qq"
								:class="changeName==4?'myClientimg-color-f':'myClientimg-color-b'"></i><span
								class="myClient-left-5">QQ</span>
						</el-radio-button>
						<!-- <el-radio-button :label="5" class="myClient-right-10"><i class="iconfont icon-youjian"
								:class="changeName==5?'myClientimg-color-f':'myClientimg-color-g'"></i><span
								class="myClient-left-5">邮件</span>
						</el-radio-button> -->
						<el-radio-button :label="6" class="myClient-right-10"><i class="iconfont icon-weizhi"
								:class="changeName==6?'myClientimg-color-f':'myClientimg-color-b'"></i><span
								class="myClient-left-5">拜访</span>
						</el-radio-button>
						<!-- <el-radio-button :label="7" class="myClient-right-10"><i class="iconfont icon-tixing"
								:class="changeName==7?'myClientimg-color-f':'myClientimg-color-DF'"></i><span
								class="myClient-left-5">提醒</span>
						</el-radio-button> -->
					</el-radio-group>
				</el-form-item>

				<el-form-item label="执行人">
					<el-radio label="my" value='my'>自己</el-radio>
				</el-form-item>

				<el-form-item label="客户" prop="client">
					<div v-if="Information.id" class="flex">
						<div>
							<svg class="icon" style="width:25px;height: 25px;" aria-hidden="true">
								<use xlink:href="#icon-icon-test"></use>
							</svg>
							<span class="margin-left-xs">{{Information.UserName}}</span>
						</div>
						<div class="flex myClientimg-text-title">
							<div class="add-img" @click="chooseContact" style="margin-left: 10px;"><i
									class="iconfont icon-add"></i></div>
							<span class="myClient-text myClient-left-10">重新选择客户</span>
						</div>
					</div>
					<div v-else>
						<div class="flex myClientimg-text-title" v-if="Information">
							<div class="add-img" @click="chooseContact" style="margin-left: 10px;"><i
									class="iconfont icon-add"></i></div>
							<span class="myClient-text myClient-left-10">选择客户</span>
						</div>
					</div>
				</el-form-item>

				<el-form-item label="完成时限" prop="CompleteTime">
					<el-date-picker v-model="ruleForm.CompleteTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择完成日期时间"
						:picker-options="pickerOptions1">
					</el-date-picker>

					<span class="myClient-text padding-left-sm">提醒员工在所指时限内完成工作</span>
				</el-form-item>

				<el-form-item label="提醒时间" prop="RemindTime">
					<!-- <el-date-picker v-model="ruleForm.RemindTime" type="date" placeholder="选择日期" :picker-options="pickOptions">
					</el-date-picker> -->
					<el-date-picker v-model="ruleForm.RemindTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"
						:picker-options="pickerOptions0">
					</el-date-picker>
				</el-form-item>

				<div v-if="changeName=='1'">
					<el-form-item label="话术" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入话术,说明在通话时如何与客户进行沟通"
							maxlength="1000" show-word-limit :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}"></el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='2'">
					<el-form-item label="短信内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入短信内容,执行时发送给客户"
							maxlength="1000" show-word-limit :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}"></el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='3'">
					<el-form-item label="微信内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入微信内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='4'">
					<el-form-item label="QQ内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入QQ内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='5'">
					<el-form-item label="邮件内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入邮件内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='6'">
					<el-form-item label="拜访内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.SMS" placeholder="输入拜访时所说的内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>
				</div>
				<div v-if="changeName=='7'">
					<el-form-item label="提醒内容" prop="TaskContent">
						<el-input type="textarea" v-model="ruleForm.TaskContent " placeholder="输入提醒内容" maxlength="1000"
							show-word-limit :rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}">
						</el-input>
					</el-form-item>
				</div>

				<el-form-item class="flex justify-content-flexEnd">
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				changeName: '',
				value1: '',
				CutomerId: '',
				executor: 'my',
				Information: {},
				ruleForm: {
					TaskTitle: '',
					ContactType: '',
					RemindTime: '',
					CompleteTime: '',
					TaskContent: '',
					ExecutorUserId: '',
					Type: 1, //计划类型 0-部门任务 1-个人任务
					AssigneeToUserId: 0,
				},
				rules: {
					TaskTitle: [{
						required: true,
						message: '请输入任务标题',
						trigger: 'blur'
					}, ],
					ContactType: [{
						required: true,
						message: '请选择联系方式',
						trigger: 'change'
					}],
					RemindTime: [{
						type: 'string',
						required: true,
						message: '请选择提醒时间',
						trigger: 'change'
					}],
					CompleteTime: [{
						type: 'string',
						required: true,
						message: '请填写完成时限',
						trigger: 'blur'
					}],
					TaskContent: [{
						required: true,
						message: '请填写发送内容',
						trigger: 'blur'
					}]
				},
				pickerOptions1: {
					disabledDate: (time) => {
						return formatTime(time,'yyyy-MM-dd HH:mm:ss') < formatTime(this.ruleForm.RemindTime,'yyyy-MM-dd HH:mm:ss')
					}
				},
				pickerOptions0: {
					disabledDate: (time) => {
						return formatTime(time,'yyyy-MM-dd HH:mm:ss') > formatTime(this.ruleForm.CompleteTime,'yyyy-MM-dd HH:mm:ss');
					}
				},
			}
		},
		created() {
			this.ruleForm.ExecutorUserId = this.$store.getters.getUserInfo.id
			if (this.CutomerId) {
				this.ruleForm.CutomerId = this.CutomerId
				this.getViewCustomer();
			}
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			//客户的资料
			getViewCustomer() {
				this.API.customerView_Customer(this.CutomerId).then(res => {
					this.Information = res
				})
			},
			typeChange(val) {
				if (val == 1) {
					this.ruleForm.ExecutorUserId = 1
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						setTimeout(() => {
							this.taskAdd()
						}, 100)
					} else {
						return false;
					}
				});
			},
			taskAdd() {
				this.API.CustomerTaskEditTas(this.ruleForm).then(res => {
					if (res.code == 200) {
						this.$message.success("操作任务成功")
						this.$bus.$emit("dialogClose", {
							type: "TaskSubmission",
							data: '成功',
						});
						this.close()
					}
				})
			},
			//选择客户
			chooseContact() {
				this.$Dialog.ChooseContact().then(res => {
					this.Information = res
					this.ruleForm.CutomerId = res.id

				});
			},
			ChangetabPosition(val) {
				this.changeName = val
				this.ruleForm.ContactType = val
			},


		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
