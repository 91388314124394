
import Layout from '@/views/layout'

const messageAssistantRouter = {
  path: '/messageAssistant',
  component: Layout,
  redirect: '/messageAssistant/index',
  name: '短信助手',
  meta: {
    title: '短信助手',
    icon: 'table'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/messageAssistant/index'),
      name: 'messageAssistantIndex',
      meta: { title: '短信助手' }
    },
  ]
}
export default messageAssistantRouter
