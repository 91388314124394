import request from '@/utils/request'

export default {
    //总欠费金额
    GetTotalArrearsMoney() {
        return request({
            url: '/Statistics/GetTotalArrearsMoney',
            method: 'post',
        })
    },
    //获取片区下校区收入金额统计（金额收入高排前面）
    GetSchoolsMoney(query) {
        return request({
            url: '/Statistics/GetSchoolsMoney',
            method: 'get',
            params:query,
        })
    },
    //获取片区下校区退费金额统计（金额高排前面）
    GetSchoolsRefundMoney(query) {
        return request({
            url: '/Statistics/GetSchoolsRefundMoney',
            method: 'get',
            params:query,
        })
    },
    //获取片区下校区学员数量统计（数量多排前面）
    GetSchoolsStudent(query) {
        return request({
            url: '/Statistics/GetSchoolsStudent',
            method: 'get',
            params:query,
        })
    },
    GetStatisticsIndexCount(){
        return request({
            url: '/Statistics/IndexCount',
            method: 'get',
        })
    },

}

