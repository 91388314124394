import request from '@/utils/request'

export default {
  // 设置字典权限
  SetRoleDicPermission(query) {
    return request({
      url: '/Dictionary/SetRoleDicPermission',
      method: 'post',
      data: query
    })
  },
  //获取角色下数据字典id
  getRoleDicIds(query){
    return request({
      url:'/Dictionary/GetRoleDicIds',
      method:'get',
      params:query
    });
  },
  //获取当前角色的菜单权限
  getCurrentPermissionDicIds(){
    return request({
      url:'/Dictionary/GetCurrentPermissionDicIds',
      method:'get',
    });
  },
  // 添加数据字典
  addDictionary(query) {
    return request({
      url: '/Dictionary/Add',
      method: 'post',
      data: query
    })
  },
  // 查询字典根据id
  getDictionaryId(query) {
    return request({
      url: '/Dictionary/Get',
      method: 'get',
      params: query
    })
  },
  // 查询字典列表
  getDictionaryList(query) {
    return request({
      url: '/Dictionary/Query',
      method: 'post',
      data: query
    })
  },
  // 查询字典树形 GET /api/Dictionary/GetTree
  getDictionaryTree(query) {
    return request({
      url: '/Dictionary/GetTree',
      method: 'get',
      params: query
    })
  },
  
  // 查询字典树形 GET GET /api/Dictionary/GetAllTree
  getDictionaryAllTreee(query) {
    return request({
      url: '/Dictionary/GetAllTree',
      method: 'get',
      params: query
    })
  },
  
  // 修改状态
  changeDictionaryStatus(query) {
    return request({
      url: '/Dictionary/ChangeStatus',
      method: 'post',
      params: query
    })
  },
  // 编辑
  editDictionary(query) {
    return request({
      url: '/Dictionary/Update',
      method: 'post',
      data: query
    })
  },
  // 设置字典权限
  addDictionaryPermission(query) {
    return request({
      url: '/Dictionary/AddDictionaryPermission',
      method: 'post',
      data: query
    })
  },
  // 删除字典权限
  removeDictionaryPermission(query) {
    return request({
      url: '/Dictionary/RemoveDictionaryPermission',
      method: 'post',
      data: query
    })
  },

}
