<template>
	<div style="height: 680px; padding: 10px" class="page-component__scroll ">
		<div class="el-scrollbar__wrap">
			<div class="myClient_client_header clear">
				<div class="aligin-items-center">
					<div style="margin-left: 20px">
						<h1 style="font-size: 18px;display: inline-block;">{{ userInfo.UserName }}</h1>
						<span class="margin-top-sm padding-left-sm">{{ userInfo.City }}</span>
					</div>
				</div>
				<div class="call-btns margin-lg flex justify-content-between">
					<span title="点击拨打电话" v-show="userInfo.PhoneNumber1"
						@click="call(userInfo.PhoneNumber1)"><span>{{ userInfo.PhoneNumber1 }}</span><i
							class="iconfont icon-dianhua1 cursor" title="拨打电话"></i></span>
					<span title="点击拨打电话" v-show="userInfo.PhoneNumber2"
						@click="call(userInfo.PhoneNumber2)"><span>{{ userInfo.PhoneNumber2 }}</span><i
							class="iconfont icon-dianhua1 cursor" title="拨打电话"></i></span>
				</div>
			</div>
			<el-input type="textarea" placeholder="添加跟进记录,1000字以内" v-model="DynamicFollow.Content" :rows="5"
				v-intelligentRecognition="{style:{right:'80px'}}" maxlength="1000" show-word-limit>
			</el-input>
			<div class="flex justify-content-flexEnd">
				<el-button type="primary" size="small" style="margin-top: 10px" @click="AddDynamicFollow">发表</el-button>
			</div>
			<div class="margin-top-sm margin-left-xl ">
				<div class="margin-top-lg">
					<el-timeline style="width: 80%">
						<el-timeline-item :timestamp="item.AddTime | dateTimeFilter" placement="top"
							v-for="(item,index) in DynamicList" :key="index">
							<h4 class="margin-top-sm">{{item.Event}}</h4>
							<div class="margin-tb-sm">{{item.Behavior}}</div>
							<el-card>
								<div class="flex justify-content-between" v-for="(res,serve) of item.BehaviorContent">
									<div style="width: 70%;">
										<div>{{res.name}}<span style="padding: 0 5px;">:</span>{{res.value}}
										</div>
									</div>
									<div>{{res.AddTime | dateTimeFilter}}</div>
								</div>
							</el-card>
						</el-timeline-item>

					</el-timeline>
				</div>
			</div>
		</div>
		<el-backtop target=".page-component__scroll .el-scrollbar__wrap" :visibility-height='100'></el-backtop>
	</div>
</template>
<script>
	export default {
		name: "CustomerDetail",
		props: {
			userInfo: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		components: {},
		data() {
			return {
				dialogVisible: false,
				DynamicFollow: { //跟进记录
					Content: '',
					CustomerUserId: ''
				},
				DynamicList: [],
				DynamicListQuery: {
					CutomerUserId: 0,
					Dynamic: '',
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				},
			};
		},
		watch:{
			userInfo(){
				this.getDynamicList()
			},
		},
		created() {
			this.getDynamicList()
		},
		methods: {
			close() {
				this.$emit("closepop");
			},
			call(phone) {
				this.$emit('outboundCall', this.userInfo, phone);
			},
			//发表动态
			AddDynamicFollow() {
				if (this.DynamicFollow.Content != '') {
					this.DynamicFollow.CustomerUserId = this.userInfo.id
					this.API.customerAddDynamicFollow(this.DynamicFollow).then(res => {
						if (res.code == 200) {
							this.DynamicFollow.Content = ""
							this.$message.success("添加成功")
						}
					})
				} else {
					this.$message.error("请填写具体的跟进记录！")
				}
			},
			//获取客户全部动态
			getDynamicList() {
				this.DynamicList=[]
				this.DynamicListQuery.CutomerUserId = this.userInfo.id
				this.API.customerSelectCutomerDynamicList(this.DynamicListQuery).then(res => {
					this.DynamicList = res.data.rows
					this.DynamicList.map(item => {
						item.BehaviorContent = JSON.parse(item.BehaviorContent)
					})
				})
			},
		}
	};
</script>

<style scoped>
	>>>.el-form-item__label {
		line-height: 26px !important;
	}
</style>

<style lang="scss" scoped>
	.call-btns {
		display: flex;
		justify-content: space-between;

		&>span {
			display: flex;
			align-items: center;
			color: #0077aa;
			cursor: pointer;
			margin-bottom: 10px;
		}

		.iconfont {
			margin-left: 10px;
			font-size: 20px;
			color: #00b831;
		}
	}

	.el-backtop {
		position: absolute;
	}
</style>
