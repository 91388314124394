import request from '@/utils/request'

export default {
  login(query) {
    return request({
      url: '/Auth/Login',
      method: 'post',
      params: query
    })
  },
  getUserList(query) {
    return request({
      url: '/area/list',
      method: 'get',
      params: query
    })
  },
  postDeleteUser(query) {
    return request({
      url: '/area/list',
      method: 'get',
      params: query
    })
  },
  getUser(query) {
    return request({
      url: '/area/list',
      method: 'get',
      params: query
    })
  },

}
