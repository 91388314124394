export default {
	data() {
		return {
			ajaxMethod: {
				list: '',
				add: '',
				edit: '',
				delete: ''
			},
			datalist: [],
			ajaxResult: {},
			listQuery: {
				PageIndex: 1,
				PageSize: 15
			},
			listLoading: false,
			datacount: 0
		}
	},
	created() {
		this.listQuery.PageIndex = 1;
	},
	mounted() {
		if (this.ajaxMethod.list) {
			this.getList();
		}
	},
	methods: {
		reload() {
			this.listQuery.PageIndex = 1;
			this.getList();
		},
		refresh(item) {
			this.getList();
		},
		getList() {
			this.listLoading = false;
			this.API[this.ajaxMethod.list](this.listQuery).then(res => {
				if (res.data) {
					this.datalist = res.data.rows;
					this.datacount = res.data.total;
					this.ajaxResult = res.data;
				}

			}).finally(() => {
				this.listLoading = false;
			});
		},
		pageSizeChange(val) {
			this.listQuery.PageIndex = 1;
			this.listQuery.pageSize = val;
			this.getList();
		},
		pageIndexChange(val) {
			this.listQuery.PageIndex = val;
			this.getList();
		},
	}
}