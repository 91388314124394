import request from '@/utils/request'

export default {
  // 查询排课列表 POST /api/Curriculumarrangerecords/Query
  queryTimeList(data) {
    return request({
      url: '/Curriculumarrangerecords/Query',
      method: 'post',
      data,
    })
  },
  // 查询排课信息（教师视图） /api/Curriculumarrangerecords/QueryForTeacherView
  queryTeaView(data) {
    return request({
      url: '/Curriculumarrangerecords/QueryForTeacherView',
      method: 'post',
      data,
    })
  }, 
  // 修改排课信息 （班级视图） /api/Curriculumarrangerecords/QueryForClassView
  updateClassView(data) {
    return request({
      url: '/Curriculumarrangerecords/QueryForClassView',
      method: 'post',
      data,
    })
  },
  // 修改排课信息 （教室视图）/api/Curriculumarrangerecords/QueryForRoomView
  updateClassroomView(data) {
    return request({
      url: '/Curriculumarrangerecords/QueryForRoomView',
      method: 'post',
      data,
    })
  },
  // 排课 /api/Curriculumarrangerecords/ArrangeCurriculum
  arrangeCurriculum(data) {
    return request({
      url: '/Curriculumarrangerecords/ArrangeCurriculum',
      method: 'post',
      data,
    })
  },
  // 修改状态 /api/CurriculumarrangerecorSds/Changestatus
  updatearrangeCurriculumStatus(params) {
    return request({
      url: '/Curriculumarrangerecords/Changestatus',
      method: 'post',
      params,
    })
  },
  // 冲突日程 /api/Curriculumarrangerecords/QueryConflictList
  queryConflictList(data) {
    return request({
      url: '/Curriculumarrangerecords/QueryConflictList',
      method: 'post',
      data,
    })
  },
  //获取单个排课信息 POST /api/Curriculumarrangerecords/Detail/{id
  CurriculumarrangerecordsDetail(id) {
    return request({
      url: '/Curriculumarrangerecords/Detail',
      method: 'post',
      params: {
        id
      }
    })
  },
}
