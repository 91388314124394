import Layout from '@/views/layout'

const myClientRouter = {
	path: '/myClient',
	component: Layout,
	redirect: '/myClient/index',
	name: '销售管理',
	meta: {
		title: '销售管理',
		icon: 'table'
	},
	children: [{
			path: 'index',
			component: () => import('@/views/myClient/index'),
			name: 'clientIndex',
			meta: {
				title: '我的客户'
			}
		},
		{
			path: 'createClient',
			component: () => import('@/views/myClient/components/createClient'),
			name: 'createclient',
			meta: {
				title: '创建客户'
			}
		}, {
			path: 'management',
			component: () => import('@/views/myClient/CustomerManagement'),
			name: 'management',
			meta: {
				title: '客户管理'
			}
		},{
			path: 'DepartmentalCustomers',
			component: () => import('@/views/myClient/DepartmentalCustomers'),
			name: 'clientIndex',
			meta: {
				title: '部门客户'
			}
		},
	]
}
export default myClientRouter
