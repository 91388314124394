const fileToBase64 = (file) => {
	return new Promise(function (resolve, reject) {      //使用Promise进行异步处理
		let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function() {
          imgResult = reader.result;
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.onloadend = function() {
          resolve(imgResult);
        };
	})
};

export {
	fileToBase64,
}