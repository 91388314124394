<template>
  <el-dialog title="选择教材杂费" :visible.sync="textbookDialog" width="55%" custom-class="textbook-dialog">
    <div class=" padding flex">
      <div class=" flex1 margin-right border-grey border-r">
        <div class="flex justify-content-center bg-z padding">可选教材、杂费</div>

        <div class="padding-sm"><el-input size="mini" placeholder="请输入关键字进行搜索" suffix-icon="el-icon-search" v-model="textbookInput"></el-input></div>

        <div class="textbook-dialog-item padding-sm border-top-grey"><el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree></div>
      </div>

      <div class="flex1 margin-left border-grey border-r">
        <div class="flex justify-content-center bg-z padding">已选教材、杂费：0</div>
        <div class="textbook-dialog-item"></div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="textbookDialog = false">取 消</el-button>
      <el-button type="primary" @click="textbookDialog = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      textbookDialog: true,
      textbookInput: '',
      data: [
        {
          label: '教材',
          children: [
            {
              label: '二级 1-1'
            }
          ]
        },
        {
          label: '杂费',
          children: [
            {
              label: '二级 2-1'
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "AddTextbook",
        data: this.textbookInput,
      });
    },
  },
  methods: {
    close() {
      this.textbookDialog = false;
    },
    handleNodeClick(data) { 
    },
    
  }
};
</script>

<style scoped>
  .textbook-dialog .textbook-dialog-item {
    height: 300px;
  }
</style>
