import request from '@/utils/request'

export default {
	//编辑计划
	CustomerPlanEditPla(data) {
		return request({
			url: '/CustomerPlan/Edit_Plan' ,
			method: 'post',
			data
		})
	},
	//根据状态查询我的计划
	CustomerPlanGetTaskListByStatus(data) {
		return request({
			url: '/CustomerPlan/Get_TaskListByStatus' ,
			method: 'post',
			data,
		})
	},
	//根据天数查看我的计划
	CustomerPlanGetDayTaskList(data) {
		return request({
			url: '/CustomerPlan/Get_DayTaskList' ,
			method: 'post',
			data,
		})
	},
	//根据客户Id查询该客户下的计划
	PlanGetTaskListByCutomerStatus(data) {
		return request({
			url: '/CustomerPlan/Get_TaskListByCutomerStatus' ,
			method: 'post',
			data
		})
	},
	//查询计划详情
	CustomerPlanGetPlanInfo(planids) {
		return request({
			url: '/CustomerPlan/Get_PlanInfo?PlanId='+planids ,
			method: 'get',
		})
	},
	//删除计划
	CustomerPlanDelete(ids) {
		return request({
			url: '/CustomerPlan/Delete?ids='+ids ,
			method: 'post',
		})
	},
	//完成计划
	CustomerPlanCompletePlan(ids) {
		return request({
			url: '/CustomerPlan/Complete_Plan?PlanId='+ids ,
			method: 'post',
		})
	},
}
