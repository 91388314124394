import request from '@/utils/request'

export default {
	//组织机构列表（校区级别）
	OrganizationList(data) {
		return request({
			url: '/Organization/list',
			method: 'post',
			data,
		})
	},
	//增加/修改组织机构信息
	OrganizationCreateorupdate(data) {
		return request({
			url: '/Organization/Createorupdate',
			method: 'post',
			data,
		})
	},
	//根据ParentId查询信息
	OrganizationGetTree(query) {
		return request({
			url: 'Organization/GetTree',
			method: 'get',
			params: query,
		})
	},
	//修改状态
	OrganizationGetChangestatus(ids) {
		return request({
			url: '/Organization/Changestatus?ids=' + ids + '&status=1',
			method: 'post',
			params: {
				ids
			}
		})
	},
	//根据ParentId查询上级信息
	OrganizationGetbyid(ids) {
		return request({
			url: '/Organization/Getbyid?ParentId=' + ids,
			method: 'get'
		})
	},
	//获取组织下的用户
	OrganizationGetOrgUsers(query) {
		return request({
			url: '/Organization/GetOrgUsers',
			method: 'get',
			params: query
		})
	},
	//删除组织下的用户
	OrganizationRemoveOrgUsers(data, params) {
		return request({
			url: '/Organization/RemoveOrgUsers',
			method: 'post',
			data: data,
			params
		})
	},
	//添加用户到组织
	OrganizationAddUserOrgs(data) {
		return request({
			url: '/Organization/AddUserOrgs',
			method: 'post',
			data
		})
	},
	//添加组织下的用户
	OrganizationAddUserOrgs(data, params) {
		return request({
			url: '/Organization/AddUserToOrg',
			method: 'post',
			data: data,
			params
		})
	},
}
