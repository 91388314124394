<template>
	<el-dialog title="任务详情" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="520px">
		<div>
			<div class="content" v-loading="loading">
				<div class="flex justify-content-between">
					<div class="flex flex-wrap-wrap">
						<svg class="icon content-logo " aria-hidden="true">
							<use xlink:href="#icon-bumen"></use>
						</svg>
						
						<div class="margin-left-sm" style="width: 380px;">
							<div>
								<span style="font-size: 16px;font-weight: 600;">{{taskMessage.TaskTitle}}</span>
							</div>
							<div class="text">{{taskMessage.TaskContent}}</div>
							<div class="margin-top-xs" v-if="taskMessage.TaskStatus==1">
								<i class="iconfont icon-yuandianxiao text-orange"></i>
								<span >未执行</span>
							</div>
							<div class="margin-top-xs" v-if="taskMessage.TaskStatus==2">
								<i class="iconfont icon-yuandianxiao text-green"></i>
								<span >执行成功</span>
							</div>
							<div class="margin-top-xs" v-if="taskMessage.TaskStatus==3">
								<i class="iconfont icon-yuandianxiao text-red"></i>
								<span >执行失败</span>
							</div>
						</div>
					</div>
					
				</div>

				<div class="margin-top" v-if="taskMessage.TaskStatus==1">
					时间：<span class="padding-left-sm">{{taskMessage.AddTime}}</span>
				</div>
			</div>

			<div class="flex justify-content-flexEnd margin-top-lg">
				<el-button @click="close" v-if="taskMessage.TaskStatus==1">取消</el-button>
				<el-button @click="close" v-if="taskMessage.TaskStatus==2">关闭</el-button>
				<el-button type="primary" @click="submit(taskMessage.id)" v-if="taskMessage.TaskStatus==1">确定完成</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				taskId:0,
				taskMessage: {},
				loading: true,
				
			}
		},
		created() {
			this.getplandetail()
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			getplandetail() {
				this.API.CustomerTaskGetTaskInfo(this.taskId).then(res => {
					this.loading=false,
					this.taskMessage = res
					this.taskMessage.AddTime=formatTime(res.AddTime,'yyyy-MM-dd HH:mm:ss')
					// this.API.customerView_Customer(res.CutomerId).then(res1 => {
					// 	this.Customermessage = res1
					// 	this.loading = false
					// })
				})
			},
			submit(id) {
				this.$confirm('确定已完成该任务?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.API.CustomerTaskCompleteTask(id).then(res => {
						if (res.code == -1) {
							this.$message.eval(res.message)
						} else if (res.code == 200) {
							this.$bus.$emit("dialogClose", {
								type: "DepartmentTaskDetails",
								data:'成功'
							});
							this.$message.success("成功完成该任务")
							this.close()
						}
					})
				}).catch(() => {
							
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.content {
		margin: 15px;
	}
	.text {
		height: 40px;
		font-size: 14px;
		text-indent: 26px;
		margin: 10px 0;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
</style>
