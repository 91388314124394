<template>
  <div class="app-wrapper">
    <left-menu />
    <app-main />
    <!-- <right-menu /> -->
  </div>
</template>

<script>
import AppMain from "./components/AppMain";
import LeftMenu from "./components/LeftMenu";
// import RightMenu from "./components/RightMenu";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    LeftMenu,
    AppMain,
    // RightMenu,
  },
  computed: {
    ...mapState({}),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  display: flex;
  // position: relative;
  // height: 100%;
  width: 100%;
  min-height: 100%;
  &.mobile.openSidebar {
    // position: fixed;
    // top: 0;
  }
}

</style>
