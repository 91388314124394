const student=[
    {
     label: '周雪媛',
     value: 1
    },{
     label: '张雪媛',
     value: 2
    },{
     label: '朱雪原',
     value: 3
    },{
     label: '爱学院',
     value: 4
    },{
     label: '陈雪媛',
     value: 5
    }
 ];
 export default student;