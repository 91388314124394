<template>
	<div id="leftMenu">
		<section class="left-menu-main">
			<div class="menu-list-wrapper">
				<!-- {{ userInfo.post_type }} -->
				<ul v-for="(item, index) in MenulList" :key="index" class="menu-list">
					<!-- v-if="rolelist.indexOf(item.id) !=-1" -->
					<li>
						<h2>{{ item.title }}</h2>
						<ul v-for="(menu, index) in item.children" :key="index">
							<li class="menu-item" :class="menu.remark == index ? 'left-nav-hover' : ''"
								v-if="item.id == menu.parent_id" @click="JumpUrl(menu.remark,menu)">
								<a>
									<div>
										<i :class="['iconfont', menu.sub_title]"></i>
										{{ menu.title }}
									</div>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</section>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				MenulList: [{
					title: "通用功能",
					icon: "table",
					children: [{
							title: "首页",
							remark: "/",
							sub_title: "icon-home_pho",
						},
						{
							title: "签到打卡",
							remark: "/checkInList/index",
							sub_title: "icon-dingdan",
						},
						{
							title: "业务申请",
							sub_title: "icon-shenqing",
							remark: "/commonTool/tasknew",
						},
						{
							title: "待办任务",
							remark: "/commonTool/tasklist",
							sub_title: "icon-daiban",
						},
						// {
						// 	title: "流程管理",
						// 	remark: "/commonTool/templist",
						// 	sub_title: "icon-daiban",
						// },
						{
							title: "通知公告",
							remark: "/noticeMsg/message",
							sub_title: "icon-tongzhi",
						},
						{
							title: "工资绩效",
							remark: "/InternalManagement/wages",
							sub_title: "icon-wage",
							// alert:true
						},
						{
							title: "云盘",
							remark: "/commonTool/cloudDisk",
							sub_title: "icon-yunpan",
						},
						
					],
				}, ],
				sampleBtns: [{
						icon: "icon-shouqi1",
						val: "1",
						type: "",
						title: "隐藏菜单",
					},
					{
						icon: "icon-home_pho",
						val: "5",
						type: "",
						title: "主页",
					},
					{
						icon: "icon-gonggao1",
						val: "2",
						type: "",
						title: "通知公告",
					},
					{
						icon: "icon-sousuo",
						val: "3",
						type: "",
						title: "搜索",
					},
					{
						icon: "icon-dingdan",
						val: "4",
						type: "",
						title: "代办事项",
					},
				],
				index: "0",
				permissions: [],
			};
		},
		computed: {
			userInfo() {
				return this.$store.getters.getUserInfo;
			},
			MenulListComputed() {},
		},
		created() {
			this.getdata();
		},
		methods: {
			getdata() {
				var model = {
					module: "RolePermissions",
					parentId: "365",
					limit: "10000",
				};
				this.API.getCurrentPermissionDicIds(model).then((res) => {
					this.MenulList = this.MenulList.concat(res.data);
				});
			},
			JumpUrl(url,m) { 
				if(m.alert){
					this.$message.success(url)
				} 
				else
				this.$router.push(url);	
			},
		},
	};

</script>
<style lang="scss" scoped>
	#leftMenu {
		width: 280px;
		flex: 0;
		background-color: #0e82b0;
		background-color: transparent;
		color: #66b1ff;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9;
		padding-left: 20px;
		padding-top: 80px;
	}

	.left-menu-main {
		display: flex;
		width: 100%;
		height: calc(100vh - 100px);
		margin-top: 10px;
		background-color: #fff;
		padding: 0px 0px;
		border-radius: 5px;
		box-shadow: 0 0 10px #808080;
	}

	.left-menu-top {
		height: 60px;
		line-height: 60px;
		padding-left: 10px;

		h1 {
			font-size: 18px;
		}
	}

	.sample-opt-list {
		width: 75px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		li {
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #808080;

			.iconfont {
				font-size: 20px;
				font-weight: bold;
			}

			&:hover {
				color: #fff;
			}
		}
	}

	.menu-list-wrapper {
		width: 100%;
		overflow-y: scroll;
		border-radius: 5px;
		height: calc(100vh - 120px);
		scrollbar-face-color: #efefef;
		scrollbar-shadow-color: #efefef;
		scrollbar-highlight-color: #efefef;
		scrollbar-3dlight-color: #efefef;
		scrollbar-darkshadow-color: #efefef;
		scrollbar-track-color: #efefef;
		scrollbar-arrow-color: #efefef;
		scrollbar-corner: 8px;
	}

	.menu-list {
		width: 100%;
		border-radius: 5;

		&>li {
			margin-top: 0px;
		}

		h2 {
			color: #808080;
			font-size: 14px;
			font-weight: bold;
			line-height: 40px;
			padding-left: 10px;
			background-image: url(../../../assets/images/system/title_bg.png);
		}

		li {
			text-align: left;
		}

		.menu-item {
			height: 40px;
			line-height: 40px;
			color: #2fa4e7;
			border-bottom: 1px #cfcfcf dashed;

			&:last-child {
				border-bottom: none;
			}

			&:hover,
			.left-nav-hover {
				color: #fff;
				background-color: #2fa4e7;
			}

			div {
				margin: 0px 10px;
			}
		}

		.iconfont {
			margin-right: 10px;
			font-size: 18px;
		}
	}

	.left-nav-hover {
		color: #fff !important;
		background-color: #2fa4e7;
	}

	::-webkit-scrollbar-thumb {
		/* 5 */
		background-color: #f1f1f1;
		border-radius: 2px;
		cursor: pointer;
	}

	::-webkit-scrollbar {
		/* 1 */
		width: 5px;
	}
</style>
