<template>
	<el-dialog title="共享客户" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<div class="text-black text-normal margin-tb-sm">
				<!-- 您一选择1个客户 -->
				选择分享人（只能选择员工）
			</div>
			<el-cascader ref="cascader" :props=" props" separator=',' clearable :show-all-levels="false"
				@change="getCheckedNodes">
				<template slot-scope="{ node, data }">
					<span class="lesson-tag" v-if="data.type==3">团</span>
					<span class="Staff-tag" v-else>员</span>
					<span class="margin-left-xs">{{ data.label }}</span>
				</template>
			</el-cascader>
			<div class="flex justify-content-flexEnd margin-top">
				<el-button type="primary" v-preventReClick="500" @click="submit">确定</el-button>
				<el-button class="myClient-left-30" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import API from '../../api/index.js'
	export default {
		data() {
			return {
				dialogVisible: true,
				CustomerUserId: 0, //传值过来的userid
				listQuery: {
					ParentId: -1,
					type: 3,
				},
				props: {
					lazy: true,
					expandTrigger: 'hover',
					checkStrictly: true,
					multiple: true,
					lazyLoad(node, resolve) {
						let {
							level,
							loading,
							root,
							value,
							data
						} = node;
						console.log(node)
						let listQuery = {
							ParentId: -1,
							type: 3,
						};
						if (root) {
							API.OrganizationGetTree(listQuery).then(res => {
								let list = res.map(t => {
									return {
										label: t.name,
										value: `${t.id}_${t.type || 1}`,
										type: t.type
									};
								});
								resolve(list);
							})
						} else {
							let ParentId = Number(value.split('_')[0]);
							let type = Number(value.split('_')[1]);
							let resultList = [];
							let listQuery = {
								ParentId: ParentId,
								type: 3,
							};

							let GetOrgUsersQuery = {
								orgid: ParentId,
							};

							let getTeam = new Promise((resolve1, reject) => {
								API.OrganizationGetTree(listQuery).then(res => {
									resultList = resultList.concat(res);
									Promise.all(res.filter(t=>t.type === 3).map(item => {
										return API.OrganizationGetOrgUsers(GetOrgUsersQuery)
									})).then(res1 => {
										resolve1()
									})
								})
							});
							if(type !== 3){
								resolve([]);
								return;
							}
							let lists = {
								orgid: ParentId
							}
							let getUser = new Promise((resolve1, reject) => {
								API.OrganizationGetOrgUsers(lists).then(res => {
									resultList = resultList.concat(res.data);
								}).finally(()=>{
									resolve1()
								})
							});

							Promise.all([getTeam, getUser]).then(res => {
								let list = resultList.map(t => {
									if (t.type == 3) {
										return {
											label: t.name,
											value: `${t.id}_3`,
											type: t.type
										};
									} else {
										return {
											label: t.full_name,
											value: `${t.id}_1`,
											type: 1
										};
									}
								});
								resolve(list);
							});
						}
					}
				},
				nodesObj:[],
			}
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			getCheckedNodes(value) {
				this.nodesObj = this.$refs['cascader'].getCheckedNodes()
			},
			submit() {
				this.nodesObj.forEach(t => {
					console.log(t)
					let id= Number(t.data.value.split('_')[0])
					let userQuery = {
						CustomerUserId: this.CustomerUserId,
						ShareId: id,
					}
					if (t.data.type == 3) {
						userQuery.shareType=1
						this.$message.error("请选择销售人员");
						return
					} else {
						userQuery.shareType=2
						this.API.customerShareCustomer(userQuery).then(res => {
							this.$message.success("共享成功")
							this.$bus.$emit("dialogClose", {
								type: "sharedClient",
								data:'成功'
							});
						})
					}
				})
				this.close()
			}
		}
	}
</script>

<style>
</style>
