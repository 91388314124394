<template>
	<el-dialog title="选择标签" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="35%">
		<div style="height: 450px;">
			<div style="height: 400px; overflow-y: auto;">
				<div class="padding-tb" style="border-bottom: 1px solid #e6e6e6;">
					<el-radio-group v-model="ruleForm.radio" @change="typeChange">
						<el-radio :label="1">指定标签</el-radio>
						<el-radio :label="2">不限</el-radio>
						<el-radio :label="3">无标签</el-radio>
					</el-radio-group>
				</div>

				<div>
					<div class=" margin-top-lg flex">
						<div @click="shows(1)">
							<span class="myClient-title-text cursor">电话联系情况</span>
							<i class="el-icon-arrow-up margin-left-sm"></i>
						</div>
						<el-checkbox class="margin-left" >不包含</el-checkbox>
					</div>
					<el-collapse-transition>
							<el-checkbox-group v-model="ruleForm.ContactSituation" @change="ChangetabPosition" v-if="show=='1'">
								<el-checkbox-button class="myClient-right-10 margin-top myClient-left-5"  v-for="(item,index) of ContactSituation" :key="item.value" :label="item.value" :disabled="ruleForm.radio==3">
									{{item.label}}
								</el-checkbox-button>
							</el-checkbox-group>
					</el-collapse-transition>
					
					<div class=" margin-top-lg flex">
						<div @click="shows(2)">
							<span class="myClient-title-text cursor">客户意向</span>
							<i class="el-icon-arrow-up margin-left-sm"></i>
						</div>
						<el-checkbox class="margin-left">不包含</el-checkbox>
					</div>
					<el-collapse-transition>
							<el-checkbox-group v-model="ruleForm.CustomerIntention" @change="ChangetabPosition" v-if="show=='2'">
								<el-checkbox-button class="myClient-right-10 margin-top myClient-left-5"  v-for="(item,index) of CustomerIntention" :key="item.value" :label="item.value" :disabled="ruleForm.radio==3">
									{{item.label}}
								</el-checkbox-button>
							</el-checkbox-group>
					</el-collapse-transition>
					
					<div class=" margin-top-lg flex">
						<div @click="shows(3)">
							<span class="myClient-title-text cursor">沟通方式</span>
							<i class="el-icon-arrow-up margin-left-sm"></i>
						</div>
						<el-checkbox class="margin-left" >不包含</el-checkbox>
					</div>

					<el-collapse-transition>
							<el-checkbox-group v-model="ruleForm.communication" @change="ChangetabPosition" v-if="show=='3'">
								<el-checkbox-button class="myClient-right-10 margin-top myClient-left-5"  v-for="(item,index) of communicationList" :key="item.value" :label="item.value" :disabled="ruleForm.radio==3">
									{{item.label}}
								</el-checkbox-button>
							</el-checkbox-group>
					</el-collapse-transition>

				</div>
			</div>
			<div class="myClient-position-absolute">
				<div class="flex justify-content-between">
					<div>
						<el-button>清空</el-button>
					</div>
					<div>
						<el-button type="primary" @click="submitForm">保存</el-button>
						<el-button  @click="close">取消</el-button>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: true,
				show:'1',
				ruleForm: {
					radio: 2,
					ContactSituation:[],
					CustomerIntention:[],
					communication:[],
				}
			};
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			submitForm() { 
				this.$bus.$emit("dialogClose", {
					type: "CustomerScreening",
					data: this.ruleForm,
				});
			},
			shows(val){ 
				if(this.show==val){
					this.show=0
				}else{
					this.show=val
				}
			},
			changes(val){ 
			},
			ChangetabPosition(val) { 
				this.ruleForm.radio=1
			},
			typeChange(val){
				if(val==2){
					this.ContactSituation=[]
					this.CustomerIntention=[]
					this.communication=[]
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 60px;
		height: 60px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
