<template>
  <el-dialog
    title="选择介绍人"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="40%"
  >
    <div class="flex padding" style="">
      <el-input placeholder="输入完整的客户名称" v-model="KeyWord"></el-input>

      <div class="myClient-left-10">
        <el-button type="success" @click="getStudentList">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="datalist"
      highlight-current-row
      @current-change="handleSelectionChange1"
    >
      <el-table-column
        property="FullName"
        align="center"
        label="介绍人"
      ></el-table-column>
    </el-table>
    <div class="padding">
      <el-pagination
        v-if="total"
        style="text-align: center"
        background
        :current-page="pageIndex"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <div slot="footer" style="text-align: center" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="close(true)">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      KeyWord: "",
      total: "",
      pageIndex: 1,
      pageSize: 10,
      dialogVisible: true,
      datalist: [],
      multipleSelection: [],
      name: [],
    };
  },
  created() { 
  },
  watch: {
    dialogVisible() {
      this.$bus.$emit("dialogClose", {
        type: "introducer",
        data: this.name,
      });
    },
  },
  created() {
    this.getStudentList();
    //  this.getIntroducer();
  },
  methods: {
    //选中介绍人
    handleSelectionChange1(val) {
      if (val) {
        this.name = val; 
      }
      // this.currentRow = val;
    },

    handleCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getStudentList(); 
    },
    getStudentList() {
      var model = {
        KeyWord: this.KeyWord,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.API.StudentList(model).then((res) => {
        if (res.success) { 
          this.total = res.data.total;
          this.datalist = res.data.rows;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
>>> .el-table__body tr.current-row > td {
  background-color: #409eff;
  color: #fff;
}
</style>
