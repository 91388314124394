<template>
	<el-dialog title="设置自动分配电话计划" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="40%">
		<div style="height: 550px;">
			<div class="flex justify-content-between btn-bcolor-e myClient-lithheight padding-lr"
				v-if="ischangeDisabled=='1'">
				<div style="font-size: 16px;color: #6b6b6b;">
					下次电话执行时间
				</div>
				<div class="myClient-planTime">2021-03-09(周二)08:00</div>
			</div>

			<div class="margin-top-xl">
				<h2 style="font-size: 16px;" class="margin-top">按条件自动为我分配需要拨打电话的客户</h2>
				<el-radio class="margin-top" v-model="radio" :label="item.value" v-for="(item,index) of useList"
					:key='index' @change="changeDisabled">{{item.label}}</el-radio>
			</div>

			<div class="margin-top-xl">
				<h2 style="font-size: 16px;" class="margin-top">
					分配的客户满足下述条件
					<el-tooltip placement="bottom" effect="light" class="myClient-right-5" style="width: 220px;">
						<i class="el-icon-question margin-left-sm"></i>
						<div slot="content">
							1、每天根据你前一天24:00千最后保存的设置条件分配电话计划（系统每一天仅自动分配一次电话计划）；
							<br />
							2、若选择从公海库分配客户，系统不会为你自动领取分配的客户，请及时跟进并领取客户；当天从公海库分配的客户，不会重复分配给其他同时，减少重复跟进客户的情况
							<br />
						</div>
					</el-tooltip>
				</h2>
				<div class="flex margin-top">
					<el-dropdown>
						<div class="el-dropdown-link myClient-lithheight" style="font-size: 15px;width: 140px;">
							{{clientLabel}}<i class="el-icon-caret-bottom el-icon--right"></i>
						</div>
						<el-dropdown-menu placement='top-end'>
							<el-dropdown-item v-for='(item,index) of clientList' :key="index"
								@click.native="changeclient(item.label)">{{item.label}} </el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-col :span="7">
						<el-input placeholder="200" v-model="num" :disabled="ischangeDisabled=='2'"></el-input>
					</el-col>
					<span class="myClient-lithheight margin-left-sm myClient-text">(每次分配的客户数)</span>
				</div>

				<div class="flex margin-top">
					<el-dropdown>
						<div class="el-dropdown-link myClient-lithheight" style="font-size: 15px;width: 140px;">
							{{TimeLabel}}<i class="el-icon-caret-bottom el-icon--right"></i>
						</div>
						<el-dropdown-menu placement='top-end' :disabled="ischangeDisabled=='2'">
							<el-dropdown-item  v-for='(item,index) of TimeList' :key="index"
								@click.native="changetime(item)">{{item.label}} </el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<el-radio-group v-model="Times" @change="ChangetabPosition" :disabled="ischangeDisabled=='2'">
						<el-radio-button :label="item.value" class="myClient-right-10 margin-top-xs"
							v-for="(item,index) of detailList" :key="item.value">
							<span class="myClient-left-5">{{item.label}}</span>
						</el-radio-button>
					</el-radio-group>

				</div>

				<div class="flex margin-top">
					<div style="width: 140px;">客户标签</div>
					<el-col :span="7">
						<div class="flex myClientimg-text-title">
							<div class="add-img" style="margin: 0;"><i class="iconfont icon-add"
									@click="customerScreening"></i></div>
							<div class="all-btn btn-bcolor-b margin-lr-xs padding-lr-xs"> 不限 </div>
						</div>
					</el-col>
				</div>

			</div>

			<div class="margin-top-xl">
				<h2 style="font-size: 16px;" class="margin-top">执行时间
					<el-tooltip placement="bottom" effect="light" class="myClient-right-5" style="width: 220px;">
						<i class="el-icon-question margin-left-sm"></i>
						<div slot="content">
							　　1、系统每天凌晨0点至5点期间分配客户，并在你的设置的执行时间通过电话计划提醒你拨打（不可选择凌晨0点至5点执行）<br />
							　　2、若当天符合条件的客户数量为0，计划会分配失败，建议重新设置分配条件<br />
						</div>
					</el-tooltip>
				</h2>

				<div class="flex margin-top">
					<div style="width: 140px;">每周/时间</div>
					<el-col :span="11">
						<el-select v-model="value1" multiple placeholder="请选择" style="width: 100%;"
							:disabled="ischangeDisabled=='2'">
							<el-option v-for="item in weekList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>

					</el-col>
					<el-col :span="5" class="margin-left-xs">
						<el-time-picker v-model="value"  placeholder="任意时间点" style="width: 100%;" :disabled="ischangeDisabled=='2'">
						</el-time-picker>
					</el-col>
				</div>
			</div>

			<div class="flex justify-content-flexEnd margin-top">
				<el-button type="primary" @click="submitForm">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {weekList} from '@/config/index'
	export default {
		data() {
			return {
				weekList,
				dialogVisible: true,
				input4: '',
				radio: '1',
				Times: 1,
				num: '',
				value: new Date(2016, 9, 10, 18, 40),
				detailList: [],
				clientLabel: '我的客户库',
				TimeLabel: '最后的联系时间',
				ruleForm: [],
				value1: [],
				ischangeDisabled: '1'
			};
		},
		created() {
			this.detailList = this.TimeList[0].list
		},
		methods: {
			close() {
				this.dialogVisible = false;
			},
			submitForm() {
				this.$bus.$emit("dialogClose", {
					type: "AssignPhonePlan",
					data: this.ruleForm,
				});
			},
			changeclient(val) {
				this.clientLabel = val
			},
			changetime(val) {
				this.TimeLabel = val.label
				this.detailList = val.list
			},
			changeDisabled(val) {
				this.ischangeDisabled = val
			},
			ChangetabPosition(val) {
				this.Times = val
			},
			customerScreening() {
				this.$Dialog.CustomerScreening().then(res => {
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 60px;
		height: 60px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
