<template>
  <div class="border-title">
    <span class="border-left-title"></span>
    <span class="margin-left-xs">{{titleName}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      titleName: {
        type: String,
        default: ''
      }
    },
  }
</script>

<style scoped>
</style>
