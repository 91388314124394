const starList = [{
		label: '一星级',
		value: 0
	},
	{
		label: '二星级',
		value: 1
	},
	{
		label: '三星级',
		value: 2
	},
	{
		label: '四星级',
		value: 3
	},
	{
		label: '五星级',
		value: 4
	},
	
]
export default starList;
