<template>
	<el-dialog title="选择客户" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="40%">
		<div style="height: 500px">
			<div class="Clientposition-relative">
				<div class="Clientcenter-left" style="width: 50%">
					<el-input placeholder="请输入内容" v-model="listQuery.KeyWord" style="width: none">
						<i slot="prefix" class="el-input__icon el-icon-search"></i>
					</el-input>
					<div class="margin-top scrollbar" style="height:390px">
						<el-radio-group v-model="checkedList" @change="checkboxGroupChange">
							<div class="customer-item" v-for="item in list" :key="item.id">
								<el-radio :label="item.id" :value="item.id">{{item.UserName}}</el-radio>
							</div>
						</el-radio-group>
					</div>
				</div>
				
				<div class="Clientcenter-right left-border" style="width: 48%; margin-left: 20px; height: 450px">
					<div v-if="JSON.stringify(ruleForm)=='{}'">
						请勾选需要添加的联系人
					</div>
					
					<div v-else>
						<div>已选择的联系人：</div>
						<div class="scrollbar" style="height: 400px">
							<div class="selected-customer-item">
								<svg class="icon" style="width: 25px; height: 25px" aria-hidden="true">
									<use xlink:href="#icon-icon-test"></use>
								</svg>
								<span>{{ ruleForm.UserName }}</span>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-content-flexEnd">
				<el-button type="primary" @click="submitForm">确定</el-button>
				<el-button @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				single: false,
				dialogVisible: true,
				keywords: "",
				checkedList: [],
				ruleForm: {},
				list: [],
				listQuery: {
					PageIndex: 1,
					PageSize: 20,
					KeyWord: "",
					Sort: [{
						Field: "id",
						Type: 1,
					}, ],
				},
				timer: "",
			};
		},
		created() {
			this.SelectMyGroup();
		},
		watch: {
			"listQuery.KeyWord": {
				deep: true,
				handler() {
					clearTimeout(this.timer);
					this.timer = setTimeout(() => {
						this.SelectMyGroup();
					}, 500);
				},
			},
		},
		methods: {
			
			close() {
				this.dialogVisible = false;
			},
			checkboxGroupChange(res) {
				let itemInfo = this.list.find(t => t.id === res);
				this.ruleForm = itemInfo

				console.log(this.ruleForm)
				
			},
			SelectMyGroup() {
				this.API.selectCustomerList(this.listQuery).then((res) => {
					this.list = res.data.rows;
				});
				this.$forceUpdate();
			},
			submitForm() {
				if (this.ruleForm) {
					
					this.dialogVisible = false;
					this.$bus.$emit("dialogClose", {
						type: "ChooseContact",
						data: this.ruleForm,
					});
				} else {
					this.$message.error("请选择联系人");
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.Clientcenter-left {
		height: 450px;
	}

	.icon {
		width: 60px;
		height: 60px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.customer-item {
		margin: 10px;
	}

	.selected-customer-item {
		text-align: left;
		padding-left: 30px;
		margin: 15px 20px;
		display: flex;
		align-items: center;

		span {
			margin-right: 10px;
			margin-left: 10px;
		}
	}
</style>
