import dictionaryApi from '../api/modules/dictionary.js'

const moduleConfig = {
  CourseClass: 'course_class', // 课程类别
  SubjectType: 'SubjectType', // 科目类别
  FunctionAuthority: 'FunctionAuthority', // 功能权限
  EmployeeRole: 'EmployeeRole', // 员工角色
  CustomerSource: 'CustomerSource', // 客户来源
  studentStatus: 'studentStatus', //学员状态
  CourseName: 'course_name', // 课程名称
  Nation: 'Nation',//民族
  Grade: 'Grade',//年级
  Industry: 'Industry',//行业
  PublicSchools: "PublicSchools",//公立学校
  Channel: "Channel",//渠道分类
  EnrollmentStatus: "EnrollmentStatus",//报读状态
  ApplyForCampus: "ApplyForCampus",//报读校区
  OrderTag: 'OrderTag',//订单标签
  SaleOrigin: 'SaleOrigin',//销售来源
  CustomerLabel: 'CustomerLabel',//客户标签
  RolePermissions: 'RolePermissions',//角色权限
  NotificationType: "NotificationType",//员工通知类型
  MenulList:"MenulList",//菜单列表
  // EmployeeRole: 'EmployeeRole', // 员工角色
  // CustomerSource: 'CustomerSource', // 员工角色
  // Students: 'Students', // 学员
  // Classes: 'Classes', // 班级
  // OneOnOne: 'OneOnOne', // 一对一
  // SortClass: 'SortClass', // 排课
  // Appointment: 'Appointment', // 约课
  // NotesLessons: 'NotesLessons', // 记上课
  // CheckAttendance: 'CheckAttendance', // 磁卡考勤
  // Curriculum: 'Curriculum', // 课程
  // OrganizationalStructure: 'OrganizationalStructure', // 组织架构
  // Staff: 'Staff', // 员工
  // StaffNotification: 'StaffNotification', // 员工通知
  // Wages: 'Wages', // 工资
  // FinancialManagement: 'FinancialManagement', // 财务管理
  // TeachingMaterial: 'TeachingMaterial', // 教材
};
const AddDictionary = (data) => {
  return dictionaryApi.addDictionary(data);
}
const EditDictionary = (data) => {
  return dictionaryApi.editDictionary(data);
}
const ChangeDictionaryStatus = (data) => {
  return dictionaryApi.changeDictionaryStatus(data);
}
const GetDictionarys = (data) => {
  return dictionaryApi.getDictionaryList(data);
}
const GetDictionaryTree = (data) => {
  return dictionaryApi.getDictionaryTree(data);
}
const getDictionaryAllTreee=(data)=>{
	 return dictionaryApi.getDictionaryAllTreee(data);
}
const GetDictionaryAll = (module) => {
  return dictionaryApi.getDictionaryList({
    PageIndex: 1,
    PageSize: 1000000,
    HasRole: false,
    module: module
  });
}

export default {
  moduleConfig,
  AddDictionary,
  GetDictionarys,
  GetDictionaryAll,
  GetDictionaryTree,
  EditDictionary,
  ChangeDictionaryStatus,
  getDictionaryAllTreee
}
